import React from 'react';
import { observable, action, toJS, computed } from 'mobx';
import { forEach, isArray, find, forOwn, filter, capitalize, findKey, includes, get, set, remove } from 'lodash';
import graphql from 'mobx-apollo';
import moment from 'moment';
import cleanDeep from 'clean-deep';
import { INCOME_QAL, INCOME_EVIDENCE, NETWORTH_QAL, VERIFICATION_REQUEST, INCOME_UPLOAD_DOCUMENTS, ASSETS_UPLOAD_DOCUMENTS, NET_WORTH, ENTITY_ACCREDITATION_METHODS, TRUST_ENTITY_ACCREDITATION, ACCREDITATION_EXPIRY, FILLING_STATUS, EVIDENCE_META } from '../../../../constants/investmentLimit';
import { FormValidator as Validator, DataFormatter } from '../../../../../helper';
import { GqlClient as client } from '../../../../../api/gqlApi';
import Helper from '../../../../../helper/utility';
import { uiStore, userDetailsStore, investmentStore } from '../../../index';
import { updateAccreditation, investorSelfVerifyAccreditedStatus, adminListAccreditation, adminAccreditedStatusApproveDeclineRequest, adminAccreditedStatusNotifyVerify } from '../../../queries/accreditation';
import { userAccreditationQuery, userDetailsQuery } from '../../../queries/users';
import { fileUpload } from '../../../../actions';
import { ACCREDITATION_FILE_UPLOAD_ENUMS, UPLOAD_ASSET_ENUMS, ACCREDITATION_SORT_ENUMS } from '../../../../constants/accreditation';
import { FILTER_META, CONFIRM_ACCREDITATION, SELF_ACCREDITATION, SELF_ACCREDITATION_PRIVATE } from '../../../../constants/accreditationRequests';
import { CURR_YEAR } from '../../../../../constants/common';
import Analytics from '../../../../../helper/utilities/Analytics';

export class AccreditationStore {
  @observable FILTER_FRM = Validator.prepareFormObject(FILTER_META);

  @observable SELF_ACCREDITATION_FRM = Validator.prepareFormObject(SELF_ACCREDITATION);

  @observable SELF_ACCREDITATION_PRIVATE_FRM = Validator.prepareFormObject(SELF_ACCREDITATION_PRIVATE);

  @observable CONFIRM_ACCREDITATION_FRM = Validator.prepareFormObject(CONFIRM_ACCREDITATION);

  @observable ENTITY_ACCREDITATION_FORM =
    Validator.prepareFormObject(ENTITY_ACCREDITATION_METHODS);

  @observable INCOME_EVIDENCE_FORM = Validator.prepareFormObject(INCOME_EVIDENCE);

  @observable TRUST_ENTITY_ACCREDITATION_FRM =
    Validator.prepareFormObject(TRUST_ENTITY_ACCREDITATION);

  @observable NETWORTH_QAL_FORM = Validator.prepareFormObject(NETWORTH_QAL);

  @observable ACCREDITATION_FORM = Validator.prepareFormObject(INCOME_QAL);

  @observable FILLING_STATUS_FORM = Validator.prepareFormObject(FILLING_STATUS);

  @observable VERIFICATION_REQUEST_FORM = Validator.prepareFormObject(VERIFICATION_REQUEST);

  @observable INCOME_UPLOAD_DOC_FORM = Validator.prepareFormObject(INCOME_UPLOAD_DOCUMENTS);

  @observable ASSETS_UPLOAD_DOC_FORM = Validator.prepareFormObject(ASSETS_UPLOAD_DOCUMENTS);

  @observable NET_WORTH_FORM = Validator.prepareFormObject(NET_WORTH);

  @observable ACCREDITATION_EXPIRY_FORM = Validator.prepareFormObject(ACCREDITATION_EXPIRY);

  @observable EVIDENCE_FORM_META = Validator.prepareFormObject(EVIDENCE_META);

  @observable removeFileIdsList = [];

  @observable stepToBeRendered = 0;

  @observable filters = false;

  @observable firstInit = '';

  @observable userData = {};

  @observable disableElement = false;

  @observable accreditationData = { ira: null, individual: null, entity: null };

  @observable requestState = {
    skip: 0,
    perPage: 25,
    filters: false,
    search: {
    },
  };

  @observable isFilingAllowed = (new Date() < new Date(`04/16/${CURR_YEAR}`));

  @observable data = [];

  @observable accreditaionMethod = null;

  @observable accreditationDetails = {};

  @observable userAccredetiationState = null;

  @observable accountAccreditationStatus = null;

  @observable selectedAccountStatus = undefined;

  @observable showAccountList = true;

  @observable headerSubheaderObj = {};

  @observable accType = '';

  @observable currentInvestmentStatus = '';

  @observable showLoader = false;

  @observable inProgress = [];

  @observable docsToUpload = [];

  @observable filingStatus = null;

  @observable isAccreditationExpired = false;

  @observable sortOrder = {
    column: null,
    direction: 'asc',
  }

  @observable docReference = null;

  @observable selfAccreditationQualifications = [];

  @observable entitySelfAccreditationQualifications = [];

  @observable isSelfAccredited = false;

  @observable isEntitySelfAccredited = false;

  @observable isAccreditationFlowInProgress = { open: false, accountSelectedType: null };

  @computed
  get currentActiveAccountDetails() {
    const activeAccounts = this.userDetails.roles;
    return find(activeAccounts, acc => acc.name === userDetailsStore.currentActiveAccount);
  }

  @action
  setFieldValue = (field, value, objRef = false) => {
    if (objRef) {
      const tempRef = this[field];
      this[field] = set(tempRef, objRef, value);
    } else {
      this[field] = value;
    }
  }

  @action
  setAccreditationInialStepState = ({ openState, accountSelected }) => {
    const { open, accountSelectedType } = this.isAccreditationFlowInProgress;
    this.setFieldValue('isAccreditationFlowInProgress', (openState || openState === false) ? openState : open, 'open');
    this.setFieldValue('isAccreditationFlowInProgress', (accountSelected || accountSelected === null) ? accountSelected : accountSelectedType, 'accountSelectedType');
  }

  @action
  addLoadingUserId = (requestId) => {
    this.inProgress.push(requestId);
  }

  @action
  removeLoadingUserId = (requestId) => {
    this.inProgress = filter(this.inProgress, request => request !== requestId);
  }

  @action
  initRequest = (reqParams) => {
    const {
      keyword, method, type, status, startDate, endDate,
    } = this.requestState.search;
    const filters = toJS({ ...this.requestState.search });
    delete filters.keyword;
    let params = {
      search: keyword,
      method: method !== 'ALL' ? method : null,
      type: type !== 'ALL' ? type : null,
      page: reqParams ? reqParams.page : 1,
      limit: (reqParams && reqParams.first) || this.requestState.perPage,
    };
    if (status && status !== '') {
      params = {
        ...params,
        status,
      };
    }
    if (this.sortOrder.column) {
      params = {
        ...params,
        sortBy: ACCREDITATION_SORT_ENUMS[this.sortOrder.column],
        sortType: this.sortOrder.direction.toUpperCase(),
      };
    }
    this.requestState.page = params.page;
    if (startDate && endDate) {
      params = {
        ...params,
        ...{ accountCreateFromDate: startDate, accountCreateToDate: endDate },
      };
    }
    this.data = graphql({
      client,
      query: adminListAccreditation,
      variables: params,
      fetchPolicy: 'network-only',
    });
  }

  @action
  resetModalForm = () => {
    this.CONFIRM_ACCREDITATION_FRM = Validator.prepareFormObject(CONFIRM_ACCREDITATION);
  }

  @computed get count() {
    return (this.data.data
      && this.data.data.adminListAccreditation
      && toJS(this.data.data.adminListAccreditation.resultCount)
    ) || 0;
  }

  @action
  setStepToBeRendered(step) {
    this.stepToBeRendered = step;
  }

  @action
  setFieldVal(field, val) {
    this[field] = val;
  }

  @action
  setCheckbox = (e, res) => {
    this.AGREEMENT_DETAILS_FORM = Validator.onChange(this.AGREEMENT_DETAILS_FORM, Validator.pullValues(e, res), 'checkbox');
  }

  @action
  formChange = (e, result, form) => {
    if (result && (result.type === 'checkbox')) {
      this[form] = Validator.onChange(
        this[form],
        Validator.pullValues(e, result),
        'checkbox',
        true,
        result.checked,
      );
    } else {
      this[form] = Validator.onChange(
        this[form],
        Validator.pullValues(e, result),
      );
    }
  }

  @action
  maskChange = (values, form, field) => {
    const fieldValue = field === 'expiration' ? values.formattedValue : values.floatValue;
    this[form] = Validator.onChange(
      this[form],
      { name: field, value: fieldValue },
    );
  }

  @action
  accreditationMethodChange = (e, form, result) => {
    this[form] = Validator.onChange(this[form], Validator.pullValues(e, result));
  }

  @action
  incomeEvidenceChange = (e, result) => {
    this.INCOME_EVIDENCE_FORM = Validator.onChange(this.INCOME_EVIDENCE_FORM, Validator.pullValues(e, result));
  }

  @action
  verificationFormChange = (e, result) => {
    this.formChange(e, result, 'VERIFICATION_REQUEST_FORM');
  }

  @action
  netWorthChange = (e, result) => {
    this.formChange(e, result, 'NET_WORTH_FORM');
  }

  @action
  checkFormValid = (form, multiForm, showErrors) => {
    this[form] = Validator.validateForm(this[form], multiForm, showErrors, false);
  }

  getFileUploadEnum = (accountType, accreditationMethod) => {
    if (accreditationMethod === 'IncomeDoc') {
      return UPLOAD_ASSET_ENUMS[accountType];
    }
    return ACCREDITATION_FILE_UPLOAD_ENUMS[accountType.toLowerCase()];
  }

  @action
  setFileUploadData = (form, field, files, accountType, accreditationMethod = '', actionValue = '', targetUserId = '', isIncomeUploadLater = false) => {
    const stepName = this.getFileUploadEnum(accountType, accreditationMethod);
    const tags = [accreditationMethod];
    if (accreditationMethod === 'Income' && !isIncomeUploadLater) {
      tags.push(this.getFileUploadEnum(field, 'IncomeDoc'));
    }
    if (typeof files !== 'undefined' && files.length) {
      forEach(files, (file) => {
        const fileData = Helper.getFormattedFileData(file);
        this.setFormFileArray(form, field, 'showLoader', true, accreditationMethod);
        // this.setFieldVal('showLoader', true);
        if (accreditationMethod !== 'Admin') {
          fileUpload.setFileUploadData('', fileData, stepName, 'INVESTOR', '', '', tags).then((result) => {
            const { fileId, preSignedUrl } = result.data.createUploadEntry;
            fileUpload.putUploadedFileOnS3({
              preSignedUrl, fileData: file, fileType: fileData.fileType,
            }).then(() => {
              this.putUploadedFileOnS3(
                form, field, preSignedUrl, file, fileData, fileId,
                accreditationMethod,
              );
              this.updateAccreditation(form, accountType.toUpperCase()).then(() => {
                this.setFormFileArray(form, field, 'showLoader', false, accreditationMethod);
                this.checkFormValid(form, false, false);
              });
            }).catch(() => {
              Helper.toast('Something went wrong, please try again later.', 'error');
              this.setFormFileArray(form, field, 'showLoader', false, accreditationMethod);
            });
          }).catch((error) => {
            this.setFormFileArray(form, field, 'showLoader', false, accreditationMethod);
            // this.setFieldVal('showLoader', false);
            Helper.toast('Something went wrong, please try again later.', 'error');
            uiStore.setErrors(error.message);
          });
        } else {
          fileUpload.setAccreditationFileUploadData('INVESTOR', fileData, accountType.toUpperCase(), actionValue, targetUserId).then((result) => {
            const { fileId, preSignedUrl } = result.data.adminAccreditedStatusUploadEntry;
            this.putUploadedFileOnS3(
              form, field, preSignedUrl, file, fileData, fileId,
              accreditationMethod,
            );
          }).catch((error) => {
            this.setFormFileArray(form, field, 'showLoader', false, accreditationMethod);
            // this.setFieldVal('showLoader', false);
            Helper.toast('Something went wrong, please try again later.', 'error');
            uiStore.setErrors(error.message);
          });
        }
      });
    }
  }

  @action
  putUploadedFileOnS3 = (form, field, preSignedUrl, file, fileData, fileId, scope) => {
    if (scope === 'Admin') {
      this.docsToUpload.push({
        preSignedUrl, fileData: file, fileType: fileData.fileType, field,
      });
      this.setFormFileArray(form, field, 'showLoader', false, scope);
    }
    const { fileName } = fileData;
    this.setFormFileArray(form, field, 'fileData', { fileId, fileName });
    this.setFormFileArray(form, field, 'preSignedUrl', preSignedUrl);
    this.setFormFileArray(form, field, 'fileId', fileId);
    this.setFormFileArray(form, field, 'value', fileName);
    this.setFormFileArray(form, field, 'error', undefined);
  }

  @action
  uploadAllDocs = () => new Promise((resolve, reject) => {
    if (this.docsToUpload.length) {
      const uploadedArr = [];
      this.docsToUpload.forEach((item, index) => {
        fileUpload.putUploadedFileOnS3(item).then(() => {
          uploadedArr.push(index);
          if (this.docsToUpload.length === uploadedArr.length) {
            resolve();
          }
        }).catch((error) => {
          Helper.toast('Something went wrong, please try again later.', 'error');
          uiStore.setErrors(error.message);
          reject(error);
        });
      });
    } else {
      resolve();
    }
  });

  @action
  removeUploadedData = (form, field, index = null, accountType) => {
    const accountId = userDetailsStore.getIdByAccountType(accountType.toLowerCase());
    let removeFileId = '';
    if (index !== null) {
      const fileId = this[form].fields[field].fileId.splice(index, 1);
      this[form].fields[field].value.splice(index, 1);
      removeFileId = get(fileId, '[0]');
    } else {
      const { fileId } = this[form].fields[field];
      removeFileId = fileId;
      this.setFormFileArray(form, field, 'fileId', '');
      this.setFormFileArray(form, field, 'fileData', '');
      this.setFormFileArray(form, field, 'value', '');
      this.setFormFileArray(form, field, 'preSignedUrl', '');
    }
    if (accountType && accountId) {
      fileUpload.removeUploadedData(removeFileId).then(() => {
        this.updateAccreditation(form, accountType.toUpperCase()).then(() => {
          this.setFormFileArray(form, field, 'showLoader', false);
        }).catch(() => {
          Helper.toast('Something went wrong, please try again later.', 'error');
          this.setFormFileArray(form, field, 'showLoader', false);
        });
      }).catch(() => {
        Helper.toast('Something went wrong, please try again later.', 'error');
      });
    }
    this.setFormFileArray(form, field, 'error', undefined);
    this.setFormFileArray(form, field, 'showLoader', false);
    this.checkFormValid(form, false, false);
  }

  @action
  setFormFileArray = (formName, field, getField, value, scope) => {
    if (scope !== 'Admin' && getField === 'showLoader') {
      this.setFieldVal('showLoader', value);
    } else if ((formName === 'ASSETS_UPLOAD_DOC_FORM' || formName === 'INCOME_UPLOAD_DOC_FORM') && getField !== 'showLoader' && getField !== 'error') {
      this[formName].fields[field][getField].push(value);
    } else {
      this[formName].fields[field][getField] = value;
    }
  }

  @action
  removeUploadedFiles = () => {
    const fileList = toJS(this.removeFileIdsList);
    if (fileList.length) {
      forEach(fileList, (fileId) => {
        fileUpload.removeUploadedData(fileId).then(() => {
        }).catch((error) => {
          uiStore.setErrors(error.message);
        });
      });
      this.removeFileIdsList = [];
    }
  }

  @action
  resetAccreditation = (form) => {
    Validator.resetFormData(form);
  }

  @action
  resetForm = (form) => {
    Validator.resetFormData(this[form]);
  }

  @action
  resetAllForms = () => {
    const forms = ['NETWORTH_QAL_FORM', 'FILLING_STATUS_FORM', 'ACCREDITATION_FORM', 'FILTER_FRM', 'CONFIRM_ACCREDITATION_FRM', 'ENTITY_ACCREDITATION_FORM', 'INCOME_EVIDENCE_FORM', 'TRUST_ENTITY_ACCREDITATION_FRM', 'VERIFICATION_REQUEST_FORM', 'INCOME_UPLOAD_DOC_FORM', 'ASSETS_UPLOAD_DOC_FORM', 'NET_WORTH_FORM'];
    forms.forEach((formName) => {
      Validator.resetFormData(this[formName]);
    });
    this.setStepToBeRendered(0);
    this.setFieldVal('disableElement', false);
    this.docsToUpload = [];
    this.firstInit = '';
  }

  @action
  setAccreditationMethod = (form, value) => {
    this[form] = Validator.onChange(this[form], { name: 'method', value });
  }

  @action
  initiateSearch = (srchParams) => {
    this.requestState.search = srchParams;
    this.initRequest();
  }

  @action
  setInitiateSrch = (name, value) => {
    if (name === 'startDate' || name === 'endDate') {
      // const dateObtained = DataFormatter.getDate(value.formattedValue, true, name);
      // this.requestState.search[name] = dateObtained;
      this.requestState.search[name] = value ? name === 'startDate' ? moment(new Date(`${value.formattedValue} 00:00:00`)).toISOString() : moment(new Date(`${value.formattedValue} 23:59:59`)).toISOString() : '';
      if ((this.requestState.search.startDate !== '' && this.requestState.search.endDate !== '')
        || (this.requestState.search.startDate === '' && this.requestState.search.endDate === '')
      ) {
        const srchParams = { ...this.requestState.search };
        this.initiateSearch(srchParams);
      }
    } else {
      const srchParams = { ...this.requestState.search };
      const temp = { ...this.requestState };
      temp.search[name] = { ...this.requestState.search };
      this.requestState = temp;
      if ((isArray(value) && value.length > 0) || (typeof value === 'string' && value !== '')) {
        srchParams[name] = value;
      } else {
        delete srchParams[name];
      }
      this.initiateSearch(srchParams);
    }
  }

  @action
  toggleSearch = () => {
    this.filters = !this.filters;
  }

  @computed get loading() {
    return this.data.loading || this.userData.loading;
  }

  @computed get accreditations() {
    return (this.data && get(this.data, 'data.adminListAccreditation.accreditation')) || [];
  }

  @action
  changeFormObject = (formObj, formArray) => {
    this[formObj] = Validator.prepareFormObject(formArray);
  }

  @action
  checkFormIsValid = (form) => {
    this[form] = Validator.validateForm(this[form], false, false);
  }

  formValidCheck = (forms) => {
    const notOkForms = forms.filter((form) => {
      this.checkFormIsValid(form);
      return !this[form].meta.isValid;
    });
    return notOkForms;
  }

  isAllFormValidCheck = (type) => {
    const forms = {
      ACCREDITATION_FORM: [1, 2, 3, 4, 5, 6, 9, 10, 11, 12, 13],
      // NET_WORTH_FORM: [3, 4, 7, 8, 11, 12],
      INCOME_EVIDENCE_FORM: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      VERIFICATION_REQUEST_FORM: [1, 3, 5, 7, 9, 11],
      ASSETS_UPLOAD_DOC_FORM: [4, 6, 8, 10, 13, 14, 16],
      INCOME_UPLOAD_DOC_FORM: [2, 12],
      ENTITY_ACCREDITATION_FORM: [15, 16],
      FILLING_STATUS_FORM: [],
      TRUST_ENTITY_ACCREDITATION_FRM: [7, 8, 9, 10, 11, 12, 14],
    };
    const formList = [];
    forEach(forms, (form, key) => {
      if (form.includes(type)) {
        formList.push(key);
      }
    });
    const notOkForms = this.formValidCheck(formList);
    return formList.length > 0 && notOkForms.length === 0;
  }

  formType = (accreditationType) => {
    let formType = 0;
    if ((this.ACCREDITATION_FORM.fields.method.value === 'INCOME') && this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value === 'verificationrequest') {
      if (accreditationType === 1) {
        formType = 1;
      } else if (accreditationType === 3) {
        formType = 11;
      }
    } else if ((this.ACCREDITATION_FORM.fields.method.value === 'INCOME') && ['uploaddocument'].includes(this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value)) {
      if (accreditationType === 1) {
        formType = 2;
      } else if (accreditationType === 3) {
        formType = 12;
      }
    } else if ((this.ACCREDITATION_FORM.fields.method.value === 'ASSETS') && this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value === 'verificationrequest') {
      if (accreditationType === 1) {
        formType = 3;
      } else if (accreditationType === 2) {
        formType = 5;
      } else if (accreditationType === 3) {
        if (this.TRUST_ENTITY_ACCREDITATION_FRM.fields.method.value === 'ASSETS') {
          formType = 7;
        } else if (this.TRUST_ENTITY_ACCREDITATION_FRM.fields.method.value === 'REVOCABLE_TRUST_ASSETS') {
          formType = 9;
        }
      }
    } else if ((this.ACCREDITATION_FORM.fields.method.value === 'ASSETS') && ['uploaddocument'].includes(this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value)) {
      if (accreditationType === 1) {
        formType = 4;
      } else if (accreditationType === 2) {
        formType = 6;
      } else if (accreditationType === 3) {
        if (this.TRUST_ENTITY_ACCREDITATION_FRM.fields.method.value === 'ASSETS') {
          formType = 8;
        } else if (this.TRUST_ENTITY_ACCREDITATION_FRM.fields.method.value === 'REVOCABLE_TRUST_ASSETS') {
          formType = 10;
        }
      }
    } else if (this.ACCREDITATION_FORM.fields.method.value === 'OWNERS_ACCREDITATED' || this.ACCREDITATION_FORM.fields.method.value === 'OWNERS_QUALIFIED') {
      if (this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value === 'verificationrequest') {
        formType = 5;
      } else if (this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value === 'uploaddocument') {
        formType = 6;
      }
    } else if (['INCOME', 'ASSETS'].includes(this.TRUST_ENTITY_ACCREDITATION_FRM.fields.method.value) && ['uploaddocument'].includes(this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value)) {
      formType = 14;
    } else if (['INCOME', 'ASSETS'].includes(this.ENTITY_ACCREDITATION_FORM.fields.method.value) || this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value === 'verificationrequest') {
      formType = 15;
    } else if (['INCOME', 'ASSETS'].includes(this.ENTITY_ACCREDITATION_FORM.fields.method.value) && ['uploaddocument'].includes(this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value)) {
      formType = 16;
    } else if ((['INCOME', 'ASSETS'].includes(this.ACCREDITATION_FORM.fields.method.value) || ['INCOME', 'ASSETS'].includes(this.TRUST_ENTITY_ACCREDITATION_FRM.fields.method.value)) && ['uploaddocumentLatter'].includes(this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value)) {
      if (accreditationType === 3) {
        formType = 14;
      } else {
        formType = 13;
      }
    }
    return formType;
  }

  @action
  updateAccreditation = (form, accountType, formType = 0) => {
    uiStore.setProgress();
    const accountId = userDetailsStore.getIdByAccountType(accountType.toLowerCase());
    let hasVerifier = null;
    let userAccreditationDetails = '';
    if (form === 'ENTITY_ACCREDITATION_FORM') {
      const accreForm = Validator.evaluateFormData(this.ACCREDITATION_FORM.fields);
      const networthForm = Validator.evaluateFormData(this.NET_WORTH_FORM.fields);
      userAccreditationDetails = cleanDeep({ ...accreForm, ...networthForm });
    } else {
      userAccreditationDetails = Validator.evaluateFormData(this[form].fields);
    }
    if (form === 'INCOME_UPLOAD_DOC_FORM' || form === 'ASSETS_UPLOAD_DOC_FORM') {
      ['previousEstimateIncome', 'estimateIncome'].map((field) => {
        if ((this.FILLING_STATUS_FORM.fields.method.value && field === 'previousEstimateIncome')
          || !userAccreditationDetails[field]) {
          delete userAccreditationDetails[field];
        }
        return null;
      });
      const fileUploadData = userAccreditationDetails.assetsUpload;
      userAccreditationDetails.assetsUpload = [];
      const isIncomeUpdateLatter = !!(['INCOME'].includes(this.ACCREDITATION_FORM.fields.method.value) && ['uploaddocumentLatter'].includes(this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value));
      forEach(fileUploadData, (file, key) => {
        if (key === 'statementDoc' || (file.fileId && file.fileName)) {
          const fileObj = {};
          fileObj.type = isIncomeUpdateLatter ? 'INCOME' : UPLOAD_ASSET_ENUMS[key];
          // fileObj.type = UPLOAD_ASSET_ENUMS[key];
          fileObj.fileInfo = file;
          userAccreditationDetails.assetsUpload.push(fileObj);
        } else if (Array.isArray(file)) {
          file.forEach((f) => {
            const fileObj = {};
            fileObj.type = isIncomeUpdateLatter ? 'INCOME' : UPLOAD_ASSET_ENUMS[key];
            // fileObj.type = UPLOAD_ASSET_ENUMS[key];
            fileObj.fileInfo = f;
            userAccreditationDetails.assetsUpload.push(fileObj);
          });
        }
      });
      this.VERIFICATION_REQUEST_FORM = Validator.prepareFormObject(VERIFICATION_REQUEST);
      hasVerifier = false;
    } else if (form === 'VERIFICATION_REQUEST_FORM') {
      this.INCOME_UPLOAD_DOC_FORM = Validator.prepareFormObject(INCOME_UPLOAD_DOCUMENTS);
      this.ASSETS_UPLOAD_DOC_FORM = Validator.prepareFormObject(ASSETS_UPLOAD_DOCUMENTS);
      hasVerifier = true;
    }
    if (formType && (form === 'INCOME_UPLOAD_DOC_FORM' || form === 'ASSETS_UPLOAD_DOC_FORM' || form === 'VERIFICATION_REQUEST_FORM')) {
      userAccreditationDetails.isPartialProfile = !this.isAllFormValidCheck(this.formType(formType));
    } else if (form === 'FILLING_STATUS_FORM') {
      userAccreditationDetails = {};
      userAccreditationDetails.filingStatus = this.FILLING_STATUS_FORM.fields.method.value;
      userAccreditationDetails.isPartialProfile = true;
    } else {
      userAccreditationDetails.isPartialProfile = true;
    }
    const payLoad = {
      accountId,
      accountType,
      userAccreditationDetails,
    };
    if (hasVerifier !== null) {
      payLoad.hasVerifier = hasVerifier;
    }
    const refetchQueries = formType ? [{
      query: userAccreditationQuery,
    }] : [];
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: updateAccreditation,
          variables: payLoad,
          refetchQueries,
        })
        .then(() => resolve())
        .catch((error) => {
          Helper.toast('Something went wrong, please try again later.', 'error');
          uiStore.setErrors(error.message);
          reject();
        })
        .finally(() => uiStore.setProgress(false));
    });
  }

  @action
  updateAccreditationAction = (accreditationAction, accountId, userId, accountType) => {
    uiStore.setProgress();
    const data = Validator.evaluateFormData(this.CONFIRM_ACCREDITATION_FRM.fields);
    const fileData = [{
      fileId: this.CONFIRM_ACCREDITATION_FRM.fields.adminJustificationDocs.fileId,
      fileName: this.CONFIRM_ACCREDITATION_FRM.fields.adminJustificationDocs.value,
    }];
    return new Promise((resolve, reject) => {
      this.uploadAllDocs().then(() => {
        client
          .mutate({
            mutation: adminAccreditedStatusApproveDeclineRequest,
            variables: {
              action: accreditationAction,
              accountId,
              userId,
              accountType,
              justification: data.justifyDescription,
              expiration: data.expiration,
              message: data.declinedMessage,
              adminJustificationDocs: fileData,
            },
            refetchQueries: [{ query: adminListAccreditation, variables: { page: 1 } }],
          })
          .then(() => {
            if (Analytics.trackingEnabled() && accreditationAction === 'CONFIRMED') {
              Analytics.trackAccreditedInvestorApproval(data.expiration);
            }
            resolve();
          })
          .catch((error) => {
            Helper.toast('Something went wrong, please try again later.', 'error');
            uiStore.setErrors(error.message);
            reject();
            uiStore.setProgress(false);
          });
      }).catch((error) => {
        Helper.toast('Something went wrong, please try again later.', 'error');
        uiStore.setErrors(error ? error.message : '');
        reject();
        uiStore.setProgress(false);
      });
    });
  }

  @action
  setFileFormData = (filesData) => {
    if (filesData && filesData.length) {
      this.INCOME_UPLOAD_DOC_FORM = Validator.prepareFormObject(INCOME_UPLOAD_DOCUMENTS);
      this.ASSETS_UPLOAD_DOC_FORM = Validator.prepareFormObject(ASSETS_UPLOAD_DOCUMENTS);
      if (filesData) {
        this.INCOME_UPLOAD_DOC_FORM.fields.isAcceptedForfilling.value = ['ACCEPTED'];
        this.INCOME_UPLOAD_DOC_FORM.fields.isAcceptedForUnfilling.value = ['ACCEPTED'];
        this.ASSETS_UPLOAD_DOC_FORM.fields.isAccepted.value = ['ACCEPTED'];
      }
      forEach(filesData, (file) => {
        const field = findKey(UPLOAD_ASSET_ENUMS, obj => obj === file.type);
        if (file.fileInfo) {
          forEach(file.fileInfo, (f) => {
            const formName = file.type.includes('INCOME') ? 'INCOME_UPLOAD_DOC_FORM' : 'ASSETS_UPLOAD_DOC_FORM';
            this[formName].fields[field].fileId.push(f.fileId);
            // this[formName].fields[field].fileData.push(f);
            this[formName].fields[field].value.push(f.fileName);
          });
        }
      });
    }
  }

  @action
  setTrustEntityAccreditationData = (data) => {
    if (data) {
      this.TRUST_ENTITY_ACCREDITATION_FRM = Validator.prepareFormObject(TRUST_ENTITY_ACCREDITATION);
      if (data.method === 'ASSETS') {
        this.TRUST_ENTITY_ACCREDITATION_FRM.fields.method.value = 'ASSETS';
        this.ACCREDITATION_FORM.fields.method.value = 'ASSETS';
      } else {
        if (data.method === 'REVOCABLE_TRUST_ASSETS' || data.method === 'REVOCABLE_TRUST_INCOME') {
          this.TRUST_ENTITY_ACCREDITATION_FRM.fields.method.value = 'REVOCABLE_TRUST_ASSETS';
          this.ACCREDITATION_FORM.fields.method.value = data.method;
        }
        this.ACCREDITATION_FORM.fields.grantorName.value = data.grantorName;
      }
    }
  }

  @action
  setIncomeEvidenceData = (data) => {
    if (data) {
      this.INCOME_EVIDENCE_FORM.fields.incEvidenceMethods.value = (data.assetsUpload && data.assetsUpload.length) ? 'uploaddocument' : (data.verifier && data.verifier.role) ? 'verificationrequest' : '';
    }
  }

  @action
  checkFormValid = (form, multiForm, showErrors) => {
    this[form] = Validator.validateForm(this[form], multiForm, showErrors, false);
  }

  @action
  getUserAccreditation = (userId = false, setInProgressArray = true) => new Promise((res) => {
    uiStore.setProgress();
    if (setInProgressArray) {
      uiStore.addMoreInProgressArray('getUserAccreditation');
    }
    this.userData = graphql({
      client,
      query: userAccreditationQuery,
      fetchPolicy: 'network-only',
      variables: userId ? { userId } : {},
      onFetch: () => {
        if (!this.userData.loading) {
          if (setInProgressArray) {
            uiStore.removeOneFromProgressArray('getUserAccreditation');
          }
          uiStore.setProgress(false);
          res();
        }
      },
      onError: () => {
        uiStore.setProgress(false);
        if (setInProgressArray) {
          uiStore.removeOneFromProgressArray('getUserAccreditation');
        }
        Helper.toast('Something went wrong, please try again later.', 'error');
      },
    });
  })

  @action
  investorSelfVerifyAccreditedStatus = (offeringId, documentId) => new Promise((resolve, reject) => {
    const payLoad = { offeringId, documentId };
    // uiStore.setProgress();
    client
      .mutate({
        mutation: investorSelfVerifyAccreditedStatus,
        variables: payLoad,
        refetchQueries: [{
          query: userDetailsQuery,
        }],
      })
      .then(() => {
        Helper.toast('Self Verification of accreditation submitted.', 'success');
        resolve();
        // uiStore.setProgress(false);
      })
      .catch((error) => {
        // uiStore.setProgress(false);
        Helper.toast('Something went wrong, please try again later.', 'error');
        uiStore.setErrors(error.message);
        reject();
      });
  });

  @action
  emailVerifier = (userId, accountId, accountType) => {
    this.addLoadingUserId(userId);
    const payLoad = { userId, accountId, accountType };
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: adminAccreditedStatusNotifyVerify,
          variables: payLoad,
          refetchQueries: [{
            query: userAccreditationQuery,
          }],
        })
        .then(() => {
          Helper.toast('Email sent for verification.', 'success');
          resolve();
        })
        .catch((error) => {
          Helper.toast('Something went wrong, please try again later.', 'error');
          uiStore.setErrors(error.message);
          reject();
        })
        .finally(() => this.removeLoadingUserId(userId));
    });
  }

  @computed get userDetails() {
    const details = (this.userData && this.userData.data && toJS(this.userData.data.user)) || {};
    return details;
  }

  @action
  setFormData = (form, ref, accountType) => {
    const { userDetails } = this;
    const entityAccreditation = userDetails && userDetails.roles
      && userDetails.roles.find(role => role.name === accountType);
    const appData = accountType === 'entity' ? entityAccreditation && entityAccreditation.details : userDetails;

    if (form === 'ACCREDITATION_FORM' && ref === 'accreditation') {
      this[form] = Validator.setFormData(this[form], appData, ref);
    }

    if (!appData || ['INVALID', 'EXPIRED'].includes(get(userDetails, 'accreditation.status'))) {
      return false;
    }

    if (form === 'TRUST_ENTITY_ACCREDITATION_FRM') {
      this.setTrustEntityAccreditationData(appData.accreditation);
      this.checkFormIsValid('ACCREDITATION_FORM', false, false);
    } else if (form === 'INCOME_EVIDENCE_FORM') {
      this.setIncomeEvidenceData(appData.accreditation);
    } else if (form !== 'INCOME_UPLOAD_DOC_FORM' && form !== 'ASSETS_UPLOAD_DOC_FORM') {
      this[form] = Validator.setFormData(this[form], appData, ref);
      if (form === 'ACCREDITATION_FORM') {
        this.accreditaionMethod = appData.accreditation.method;
        this.NETWORTH_QAL_FORM.fields.method.value = appData.accreditation.method;
        this.ENTITY_ACCREDITATION_FORM.fields.method.value = appData.accreditation.method;
      }
    } else {
      this.setFileFormData(appData.accreditation && appData.accreditation.assetsUpload);
      if (appData.accreditation.filingStatus !== null) {
        this.FILLING_STATUS_FORM.fields.method.value = appData.accreditation.filingStatus;
      }
      if (!this.isFilingAllowed) {
        this.FILLING_STATUS_FORM.fields.method.value = true;
      }
      this.INCOME_UPLOAD_DOC_FORM.fields.estimateIncome.value = appData.accreditation.estimateIncome;
      this.INCOME_UPLOAD_DOC_FORM.fields.previousEstimateIncome.value = appData.accreditation.previousEstimateIncome;
      // this.checkFormValid('INCOME_UPLOAD_DOC_FORM', false, false);
      this.checkFormValid('ASSETS_UPLOAD_DOC_FORM', false, false);
      this.checkFormValid('ENTITY_ACCREDITATION_FORM', false, false);
    }
    this.checkFormValid(form, false, false);
    return false;
  }

  @action
  getSelfAccreditationStatus = (account) => {
    const { userDetails } = userDetailsStore;
    const { accreditationSelfVerification } = userDetails;
    const entityAccreditationSelfVerification = get(account, 'details.accreditationSelfVerification');
    switch (account.name) {
      case 'entity':
        this.setFieldVal('entitySelfAccreditationQualifications', []);
        if (entityAccreditationSelfVerification !== null && entityAccreditationSelfVerification.verifiedAt !== null && entityAccreditationSelfVerification.questionnaire !== null) {
          this.setFieldVal('isEntitySelfAccredited', !!entityAccreditationSelfVerification.verifiedAt);
          const entityData = userDetails && userDetails.roles && userDetails.roles.find(role => role.name === 'entity');
          this.entitySelfAccreditationQualifications = entityData.details.accreditationSelfVerification.questionnaire.map(qualificationObj => qualificationObj.key);
        }
      break;
      case 'individual' || 'ira':
        this.setFieldVal('selfAccreditationQualifications', []);
        if (accreditationSelfVerification !== null && accreditationSelfVerification.verifiedAt !== null && accreditationSelfVerification.questionnaire !== null) {
          this.setFieldVal('isSelfAccredited', !!accreditationSelfVerification.verifiedAt);
          this.selfAccreditationQualifications = userDetails.accreditationSelfVerification.questionnaire.map(qualificationObj => qualificationObj.key);
        }
      break;
      default:
      break;
    }
  }

  @action
  initiateAccreditation = () => {
    const { userDetails } = this;
    const entityAccreditation = userDetails && userDetails.roles
      && userDetails.roles.find(role => role.name === 'entity');
    const accData = {
      individual: userDetails && userDetails.accreditation,
      ira: userDetails && userDetails.accreditation,
      entity: entityAccreditation && entityAccreditation.details
        && entityAccreditation.details.accreditation,
    };
    this.setFieldVal('isEntitySelfAccredited',
      entityAccreditation
      && entityAccreditation.details
      && entityAccreditation.details.accreditationSelfVerification
      && entityAccreditation.details.accreditationSelfVerification.verifiedAt
      && !!entityAccreditation.details.accreditationSelfVerification.verifiedAt);
    this.setFieldVal('isSelfAccredited',
      userDetails
      && userDetails.accreditationSelfVerification
      && userDetails.accreditationSelfVerification.verifiedAt
      && !!userDetails.accreditationSelfVerification.verifiedAt);
    this.accreditationData = accData;
  }

  @computed
  get isUserAccredited() {
    let isAccredited = false;
    const accreditationDataArr = Object.keys(this.accreditationData).map(a => this.accreditationData[a] && this.accreditationData[a].status);
    accreditationDataArr.map((status) => {
      if (['CONFIRMED', 'REQUESTED', 'SELF_VERIFIED'].includes(status) || this.isSelfAccredited || this.isEntitySelfAccredited) {
        isAccredited = true;
      }
      return isAccredited;
    });
    return isAccredited;
  }

  getKeyResult = (dataObj) => {
    const resultArr = [];
    if (dataObj) {
      forOwn(dataObj, (value, key) => {
        if (value === true) {
          resultArr.push(key);
        }
      });
    }
    return resultArr;
  }

  @action
  userAccreditatedStatus = (
    accountSelected = undefined,
    regulationCheck = false,
    regulationType = undefined,
  ) => {
    const aggreditationDetails = this.accreditationData;
    const currentSelectedAccount = accountSelected === '' ? ''
      : accountSelected || userDetailsStore.currentActiveAccountDetails.name;
    const intialAccountStatus = this.userSelectedAccountStatus(currentSelectedAccount);
    this.setUserSelectedAccountStatus(intialAccountStatus);
    let investmentType = 'BD_CF';
    if (regulationType === 'BD_506B') {
      investmentType = 'BD_506B';
    }
    if (intialAccountStatus === 'FULL' && regulationCheck) {
      let currentAcitveObject = {};
      if (aggreditationDetails) {
        currentAcitveObject = find(aggreditationDetails, (value, key) => key === currentSelectedAccount);
      }
      const validAccreditationStatus = ['REQUESTED'];
      const accountStatus = currentAcitveObject && currentAcitveObject.expiration
        ? (currentAcitveObject.status === 'EXPIRED' || this.checkIsAccreditationExpired(currentAcitveObject.expiration)
          === 'EXPIRED') ? 'EXPIRED' : regulationType && regulationType === 'BD_CF_506C' && currentAcitveObject && currentAcitveObject.status && includes(validAccreditationStatus, currentAcitveObject.status) ? 'REQUESTED' : currentAcitveObject && currentAcitveObject.status ? currentAcitveObject.status : null : regulationType && regulationType === 'BD_CF_506C' && currentAcitveObject && currentAcitveObject.status && includes(validAccreditationStatus, currentAcitveObject.status) ? 'REQUESTED' : currentAcitveObject && currentAcitveObject.status ? currentAcitveObject.status : null;
      investmentType = regulationType && regulationType === 'BD_CF_506C' && accountStatus !== 'EXPIRED' && currentAcitveObject && currentAcitveObject.status && includes(['REQUESTED', 'CONFIRMED'], currentAcitveObject.status) ? 'BD_506C' : regulationType && regulationType === 'BD_506C' ? 'BD_506C' : regulationType && regulationType === 'BD_506B' ? 'BD_506B' : 'BD_CF';
      this.setFieldVal('accountAccreditationStatus', accountStatus);
      switch (accountStatus) {
        case 'REQUESTED':
          this.setFieldVal('userAccredetiationState', 'PENDING');
          break;
        case 'DECLINED':
          this.setFieldVal('userAccredetiationState', 'NOT_ELGIBLE');
          break;
        case 'CONFIRMED':
          this.setFieldVal('userAccredetiationState', 'ELGIBLE');
          break;
        case 'EXPIRED':
          this.setFieldVal('userAccredetiationState', 'EXPIRED');
          this.setFieldVal('isAccreditationExpired', true);
          break;
        default:
          this.setFieldVal('userAccredetiationState', 'INACTIVE');
          break;
      }
    } else if (intialAccountStatus === 'FULL') {
      this.setFieldVal('userAccredetiationState', 'ELGIBLE');
    }
    this.setCurrentInvestmentStatus(investmentType);
  }

  @computed get currentUserAccreditedState() {
    return this.userAccredetiationState;
  }

  @action
  setUserSelectedAccountStatus = (intialAccountStatus) => {
    const { userDetails, signupStatus } = userDetailsStore;
    const userCurrentStatus = userDetails && userDetails.status;
    this.selectedAccountStatus = signupStatus.isMigratedUser && userCurrentStatus && userCurrentStatus !== 'FULL' ? 'PARTIAL' : intialAccountStatus;
  }

  userSelectedAccountStatus = (selectedAccount) => {
    const {
      activeAccounts,
      frozenAccounts,
      partialAccounts,
      processingAccounts,
    } = userDetailsStore.signupStatus;
    if (!userDetailsStore.userDetails.roles) {
      return '';
    }
    let accountStatusFound = '';
    if (selectedAccount) {
      const { details } = userDetailsStore.userDetails.roles.find(r => r.name === selectedAccount);
      const activeArr = activeAccounts.length
        ? filter(activeAccounts, o => o === selectedAccount) : activeAccounts;
      const frozenArr = frozenAccounts.length
        ? filter(frozenAccounts, o => o === selectedAccount) : frozenAccounts;
      const PartialArr = partialAccounts.length
        ? filter(partialAccounts, o => o === selectedAccount) : partialAccounts;
      const ProcessingArr = processingAccounts.length
        ? filter(processingAccounts, o => o === selectedAccount) : processingAccounts;
      if (activeArr.length) {
        accountStatusFound = 'FULL';
      } else if (ProcessingArr.length) {
        accountStatusFound = 'PROCESSING';
      } else if (frozenArr.length) {
        accountStatusFound = details.accountStatus;
      } else if (PartialArr.length) {
        accountStatusFound = 'PARTIAL';
      } else {
        accountStatusFound = 'PARTIAL';
      }
    } else {
      accountStatusFound = 'PARTIAL';
    }
    return accountStatusFound;
  }

  @action
  resetUserAccreditatedStatus = () => {
    // this.userAccredetiationState = null;
    this.selectedAccountStatus = undefined;
    this.showAccountList = true;
    investmentStore.resetAccTypeChanged();
    investmentStore.setFieldValue('disableNextbtn', true);
    investmentStore.setFieldValue('isGetTransferRequestCall', false);
    this.setFieldVal('isAccreditationExpired', false);
    this.setFieldVal('userData', {});
    this.resetAccreditationObject();
  }

  checkIsAccreditationExpired = (expirationDate, isUnix = false) => {
    let dateDiff = '';
    if (expirationDate) {
      const date = (isUnix && typeof expirationDate === 'string') ? parseInt(expirationDate, 10) : expirationDate;
      dateDiff = DataFormatter.diffDays(DataFormatter.formatedDate(date, isUnix), false, true);
      return dateDiff < 0 ? 'EXPIRED' : 'ACTIVE';
    }
    return dateDiff;
  }

  checkIsAccreditationExpiredAsperTimeZone = (expirationDate, isUnix = false) => {
    let dateDiff = '';
    if (expirationDate) {
      const date = (isUnix && typeof expirationDate === 'string') ? parseInt(expirationDate, 10) : expirationDate;
      dateDiff = DataFormatter.getDateDifferenceInHoursOrMinutes(DataFormatter.formatedDate(date, isUnix), true);
      return dateDiff <= 0 ? 'EXPIRED' : 'ACTIVE';
    }
    return dateDiff;
  }

  @action
  expirationChange = (e, result) => {
    this.formChange(e, result, 'ACCREDITATION_EXPIRY_FORM');
  }

  @action
  resetAccreditationExpirayForm = (form) => {
    Validator.resetFormData(this[form]);
  }

  @action
  changeShowAccountListFlag = (statusValue) => {
    this.showAccountList = statusValue;
  }

  pendingStepForAccreditation = (selectedAccountName) => {
    let redirectUrl = '';
    if (userDetailsStore.isEntityTrust && selectedAccountName === 'entity') {
      redirectUrl = `/dashboard/account-settings/investment-limits/verify-trust-entity-accreditation/${selectedAccountName}`;
    } else {
      redirectUrl = `/dashboard/account-settings/investment-limits/verify-accreditation/${selectedAccountName}`;
    }
    return redirectUrl;
  }

  offeringAccreditatoinStatusMessage = (
    currentStatus, accreditedStatus, isRegulationCheck = false,
    accountCreated, showAccountList = true, isDocumentUpload = true,
    offeringReuglation = undefined,
  ) => {
    const headerSubheaderTextObj = {};
    if (showAccountList && accountCreated.values.length >= 2) {
      headerSubheaderTextObj.header = 'Which Investment Account would you like to invest from ?';
      headerSubheaderTextObj.subHeader = 'Choose an account type';
      // return headerSubheaderTextObj;
    } else if ((!showAccountList && !isDocumentUpload)
      || (!isDocumentUpload && accountCreated.values.length === 1)) {
      headerSubheaderTextObj.header = 'Yikes, sorry.';
      headerSubheaderTextObj.subHeader = '';
      // return headerSubheaderTextObj;
    } else {
      const userCurrentState = (isRegulationCheck && currentStatus === 'FULL') ? accreditedStatus : currentStatus;
      // const offeringTitleInHeader = offeringDetailsObj && offeringDetailsObj.offeringTitle ?
      // offeringDetailsObj.offeringTitle : 'Offering';
      // const subHeaderForParallelOffering = `Up to ${Helper.CurrencyFormat((offeringDetailsObj &&
      // offeringDetailsObj.offeringRegulationDMaxAmount) || 0, 0)} is being raised under Regulation
      //  D and up to
      // ${Helper.CurrencyFormat((offeringDetailsObj
      //  && offeringDetailsObj.OfferingRegulationCFMaxAmount)
      //  || 0, 0)} is being raised under Regulation Crowdfunding`;
      if (userCurrentState) {
        const accountType = investmentStore.investAccTypes.value === 'ira' ? 'IRA' : capitalize(investmentStore.investAccTypes.value);
        switch (userCurrentState) {
          case 'PENDING':
            headerSubheaderTextObj.header = isRegulationCheck && offeringReuglation && offeringReuglation === 'BD_CF_506C' ? '' : 'This investment is only available for accredited investors.';
            headerSubheaderTextObj.subHeader = isRegulationCheck && offeringReuglation && offeringReuglation === 'BD_CF_506C' ? '' : 'Your request is currently in review, please check back to make an investment.';
            break;
          case 'NOT_ELGIBLE':
            headerSubheaderTextObj.header = 'This investment is only available for accredited investors.';
            headerSubheaderTextObj.subHeader = 'Please confirm your accredited investor status to invest in this offering.';
            break;
          case 'INACTIVE':
            headerSubheaderTextObj.header = isRegulationCheck && offeringReuglation && offeringReuglation === 'BD_CF_506C' ? 'How would you like to invest?' : 'This investment is only available for accredited investors.';
            headerSubheaderTextObj.subHeader = isRegulationCheck && offeringReuglation && offeringReuglation === 'BD_CF_506C' ? <>This investment campaign is a <i>Parallel Offering</i>, meaning both non-accredited investors and accredited investors can participate.</> : 'Please confirm your accredited investor status to invest in this offering.';
            break;
          case 'EXPIRED':
            headerSubheaderTextObj.header = 'Accredited Status Expired';
            // headerSubheaderTextObj.subHeader = 'Please confirm the following to renew your status.';
            break;
          case 'PROCESSING':
            headerSubheaderTextObj.header = 'Your account is being processed.';
            headerSubheaderTextObj.subHeader = '';
            break;
          case 'PARTIAL':
            headerSubheaderTextObj.header = `You do not have a full ${accountType} Investment Account.`;
            headerSubheaderTextObj.subHeader = '';
            break;
          case 'USER-PARTIAL':
            headerSubheaderTextObj.header = 'Finish setting up your account to begin investing.';
            headerSubheaderTextObj.subHeader = '';
            break;
          case 'HARD_FREEZE':
            // headerSubheaderTextObj.header =
            // `Your ${accountType} Account Is Frozen For Investments.`;
            headerSubheaderTextObj.header = 'This investment account is frozen.';
            headerSubheaderTextObj.subHeader = '';
            break;
          case 'SOFT_FREEZE':
            // headerSubheaderTextObj.header =
            // `Your ${accountType} Account Is Frozen For Investments.`;
            headerSubheaderTextObj.header = 'This investment account is frozen.';
            headerSubheaderTextObj.subHeader = '';
            break;
          default:
            headerSubheaderTextObj.header = '';
            headerSubheaderTextObj.subHeader = '';
            break;
        }
      }
      if (userCurrentState !== 'NOT_ELGIBLE' && (headerSubheaderTextObj.header !== '' || headerSubheaderTextObj.subHeader !== '')) {
        this.setFieldVal('disableElement', true);
      }
    }
    // return headerSubheaderTextObj;
    this.setHeaderAndSubHeader(headerSubheaderTextObj);
  }

  @action
  setHeaderAndSubHeader = (headerText) => {
    this.headerSubheaderObj.header = headerText.header;
    this.headerSubheaderObj.subHeader = headerText.subHeader;
  }

  @action
  setDefaultCheckboxVal = () => {
    this.ASSETS_UPLOAD_DOC_FORM.fields.isAccepted.value = [true];
  }

  @action
  resetAccreditationObject = () => {
    this.accreditationData = { ira: null, individual: null, entity: null };
  }

  @action
  setCurrentInvestmentStatus = (val) => {
    this.currentInvestmentStatus = val;
  }

  @action
  resetFilters = () => {
    this.requestState = {
      skip: 0,
      perPage: 25,
      filters: false,
      search: {
      },
    };
  }

  @action
  setSortingOrder = (column = null, direction = null) => {
    this.sortOrder.column = column;
    this.sortOrder.direction = direction;
    this.initRequest();
  }

  @action
  // changeRuleAsPerFilingStatus = (isFilingTrue) => {
  changeRuleAsPerFilingStatus = () => {
    const override = true;
    this.INCOME_UPLOAD_DOC_FORM.fields.isAcceptedForUnfilling.rule = override ? 'optional' : 'required';
    this.INCOME_UPLOAD_DOC_FORM.fields.isAcceptedForfilling.rule = override ? 'required' : 'optional';
    this.INCOME_UPLOAD_DOC_FORM.fields.incomeDocThirdLastYear.rule = override ? 'optional' : 'required';
    this.INCOME_UPLOAD_DOC_FORM.fields.incomeDocLastYear.rule = override ? 'required' : 'optional';
    this.INCOME_UPLOAD_DOC_FORM.fields.previousEstimateIncome.rule = !override ? 'required' : 'optional';
    this.INCOME_UPLOAD_DOC_FORM.fields.incomeDocThirdLastYear.skipField = override;
    this.INCOME_UPLOAD_DOC_FORM.fields.incomeDocLastYear.skipField = !override;
    ['fileData', 'fileId', 'value'].map((field) => {
      this.INCOME_UPLOAD_DOC_FORM.fields.incomeDocThirdLastYear[field] = override ? [] : this.INCOME_UPLOAD_DOC_FORM.fields.incomeDocThirdLastYear[field];
      this.INCOME_UPLOAD_DOC_FORM.fields.incomeDocLastYear[field] = !override ? [] : this.INCOME_UPLOAD_DOC_FORM.fields.incomeDocLastYear[field];
      return null;
    });

    this.checkFormValid('INCOME_UPLOAD_DOC_FORM', false, false);
  }

  @action
  calculateEvidenceMethod = (incEvidenceMethods, isEntity, isTrust) => {
    const evidenceMethodArr = [];
    const uploadTitle = this.ACCREDITATION_FORM.fields.method.value === 'INCOME' ? 'income' : 'net worth';
    if (isEntity || isTrust) {
      remove(incEvidenceMethods, o => o.value === 'uploaddocumentLatter');
    }
    forEach(incEvidenceMethods, (method) => {
      const valueObj = {};
      // valueObj.label = isEntity ? method.value === 'uploaddocumentLatter' ? method.header : method.header2 : method.value === 'uploaddocumentLatter' ? method.header : method.value === 'uploaddocument' ? `Upload proof of ${uploadTitle}` : method.header1;
      valueObj.label = isEntity ? method.header2 : method.value === 'uploaddocumentLatter' ? method.header : method.value === 'uploaddocument' ? `Upload proof of ${uploadTitle}` : method.header1;
      if (method.value === 'uploaddocument') {
        valueObj.labelDescription = isTrust ? method.desc4 : isEntity ? method.desc3 : this.ACCREDITATION_FORM.fields.method.value === 'ASSETS' ? method.desc1 : method.desc1;
      } else if (method.value === 'verificationrequest') {
        valueObj.labelDescription = isTrust ? method.desc3 : isEntity ? method.desc2 : method.desc1;
      } else if (method.value === 'uploaddocumentLatter') {
        valueObj.labelDescription = method.desc;
      } else {
        valueObj.labelDescription = '';
      }
      valueObj.value = method.value;
      evidenceMethodArr.push(valueObj);
    });
    this.EVIDENCE_FORM_META.fields.method.values = evidenceMethodArr;
  }
}
export default new AccreditationStore();
