import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Grid, Divider, Header, Button } from 'semantic-ui-react';
import { Logo } from '../../../../theme/shared';
import { REPUBLIC_BANNER } from '../../../../constants/about';


const RepublicHomeBanner = ({ isMobile, handleButtonClick }) => {
  const location = useLocation();
  const isAboutPage = location.pathname.includes('/about');
  return (
    <section className={`banner republic-banner ${isMobile ? 'mt-0' : ''} ${isAboutPage ? 'bg-white' : 'bg-offwhite'}`}>
      <Container className={isAboutPage && 'republic-container'}>
        <Grid>
          <div>
            <Grid.Row>
              {/* <NSImage className={isMobile ? 'mt-60' : ''} path={`republic/${!isMobile ? 'fists.png' : 'fistsMobile.png'}`} /> */}
              <div className="republic-fists">
                <Logo dataSrc="RepublicFists" size={isMobile ? 'small' : 'large'} />
              </div>
              <Divider fitted />
            </Grid.Row>
            <Grid.Row>
              <Logo dataSrc="LogoGreenGrey" size={isMobile ? 'small' : 'medium'} />
              <Header as="h2">{!isMobile ? 'is now a part of' : '+'}</Header>
              <Logo dataSrc="RepublicBlue" size={isMobile ? 'small' : 'medium'} />
              <Divider className={`${isMobile ? 'mt-10' : 'mt-40'}`} fitted />
            </Grid.Row>
            <Grid.Row>
              {isMobile && (
                <div className="mt-20 left-align">
                  <Header as="h2" className="mb-20">NextSeed is now part of Republic</Header>
                  <br /><br />
                </div>
              )}
              <div className={`${isAboutPage && isMobile && 'mb-30'} ${!isAboutPage && 'left-align'}`}>
                {REPUBLIC_BANNER.firstParagraph}<br /><br />
                {!isAboutPage && REPUBLIC_BANNER.secondParagraph}
              </div>
              {isMobile && !isAboutPage && <Divider hidden />}
            </Grid.Row>
            {!isAboutPage
            && (
            <Grid.Row>
              {// eslint-disable-next-line no-useless-escape
              <Button fluid={isMobile} primary color="green" className={isMobile ? 'mb-60' : ''} content="View All" onClick={() => handleButtonClick('/about?utm_source=nextseed\&utm_medium=website_republic_banner\&utm_campaign=ns_acq')}>Learn More</Button>}
            </Grid.Row>
            )}
          </div>
        </Grid>
      </Container>
    </section>
  );
};

export default RepublicHomeBanner;
