import React from 'react';
import { Container, Divider, Grid, Header, Responsive } from 'semantic-ui-react';
import { Logo } from '../../../../theme/shared';
// import FeaturedOn from '../../shared/components/FeaturedOn';
import NSImage from '../../../shared/NSImage';

const ImpactfulInvestments = ({ isMobile }) => (
  <section>
    <Container>
      <Header as="h4" className="mb-20">About</Header>
      <Logo dataSrc="LogoGreenGrey" size={isMobile ? 'small' : 'medium'} restOfClasses={`${!isMobile ? 'ns-logo-homePage' : ''}`} />
      <Grid className={`${isMobile ? 'mt-10' : 'mt-20'} space-between`} stackable columns={2}>
        <Grid.Column width={8}>
          <Header as="h2">Impactful investments<Responsive minWidth={768} as="br" /> in meaningful businesses<br /> since 2015</Header>
          <p>NextSeed is a pioneer in facilitating innovative debt and revenue-based investments in small businesses, completing{' '}
            the first-ever Regulation CF offering and building out a unique investment infrastructure that enables an entire closed-loop{' '}
            ecosystem for payments and investor distributions.
          </p>
          <p>
            NextSeed shares Republic’s mission of democratizing finance through capital markets, building prosperous communities along the way.
          </p>
          <Divider fitter hidden />
          <p>As featured in</p>
          <NSImage className="mt-20" NSImage path="featured.png" />
        </Grid.Column>
        <Grid.Column width={7} stackable>
          <Grid.Row>
            <NSImage className={`mr-20 ${isMobile ? 'mb-10' : 'mb-20 display-inline'}`} size={isMobile ? 'mini' : ''} NSImage path="returns.svg" />
            <Header as={isMobile ? 'h2' : 'h3'} className="display-inline">Competitive returns</Header>
            <Divider fitted={!isMobile} />
            <p className="mb-50 mt-20">
              Invest for potential monthly payments and cash distributions from investments in job-creating,{' '}
              community-building small businesses. NextSeed collects and processes any returns directly into your{' '}
              NextSeed investment account.<sup>1</sup>
            </p>
          </Grid.Row>
          <Grid.Row>
          <NSImage className={`mr-20 ${isMobile ? 'mb-10' : 'mb-20 display-inline'}`} size={isMobile ? 'mini' : ''} NSImage path="founders.svg" />
            <Header as={isMobile ? 'h2' : 'h3'} className="display-inline">Inspiring founders</Header>
            <Divider fitted={!isMobile} />
            <p className={`${isMobile ? 'mb-20' : ''} mt-20`}>
              Over 75% of the dollars invested on NextSeed have gone to companies founded by women and people of color.<sup>2</sup>{' '}
              Explore businesses founded by veterans and active duty military, as well as first-generation Americans.
            </p>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </Container>
  </section>
);

export default ImpactfulInvestments;
