import React from 'react';

export const CAMPAIGN_KEYTERMS_SECURITIES = {
  TERM_NOTE: 'Term Note',
  EQUITY: 'Equity',
  REVENUE_SHARING_NOTE: 'Revenue Sharing Note',
  PREFERRED_EQUITY_506C: 'Preferred Equity',
  CONVERTIBLE_NOTES: 'Convertible Notes',
  SAFE: 'Future Equity (SAFE)',
  REAL_ESTATE: 'LLC Membership Interests',
  FUNDS: 'Funds - Limited Partnership Interests',
};
export const CAMPAIGN_KEYTERMS_EQUITY_CLASS = {
  PREFERRED: 'Series NS Preferred Units',
  LLC_MEMBERSHIP_UNITS: 'LLC Membership Interests',
  CLASS_A_SHARES: 'Class A Shares',
  CLASS_B_SHARES: 'Class B Shares',
  PARALLEL_CLASS_SHARES: 'Class A and B Shares',
};
export const CAMPAIGN_KEYTERMS_EQUITY_CLASS_ENUM = {
  PREFERRED: 'PREFERRED',
  LLC_MEMBERSHIP_UNITS: 'LLC_MEMBERSHIP_UNITS',
  CLASS_A_SHARES: 'CLASS_A_SHARES',
  CLASS_B_SHARES: 'CLASS_B_SHARES',
  PARALLEL_CLASS_SHARES: 'PARALLEL_CLASS_SHARES',
};
export const CAMPAIGN_KEYTERMS_SECURITIES_ENUM = {
  TERM_NOTE: 'TERM_NOTE',
  EQUITY: 'EQUITY',
  REVENUE_SHARING_NOTE: 'REVENUE_SHARING_NOTE',
  CONVERTIBLE_NOTES: 'CONVERTIBLE_NOTES',
  SAFE: 'SAFE',
  FUNDS: 'FUNDS',
};
export const ROUND_TYPES_ENUM = {
  SEED: 'Seed',
  SERIES_A: 'Series A',
  SERIES_B: 'Series B',
};
export const BUSINESS_TYPE_ENUM = {
  SOLE_PROPRIETOR: 'Sole Proprietor',
  CORPORATION: 'Corporation',
  LLC: 'Limited Liability Company',
  LIMITED_PARTNERSHIP: 'Limited Partnership',
  OTHER: 'Other',
};
export const INDUSTRY_TYPES = {
  FASHION_AND_MERCHANDISING: 'Fashion and Merchandising',
  BEAUTY_AND_SPA: 'Beauty and Spa',
  FOOD_AND_BEVERAGE: 'Food and Beverage',
  REAL_ESTATE: 'Real Estate',
  FITNESS_AND_WELLNESS: 'Fitness and Wellness',
  HOSPITALITY: 'Hospitality',
  TECHNOLOGY: 'Technology',
  RESTAURANT_AND_BAR: 'Restaurant and Bar',
  BREWERY_AND_BREWPUB: 'Brewery and Brewpub',
  HEALTH_AND_WELLNESS: 'Health and Wellness',
  FITNESS: 'Fitness',
  FASHION_AND_APPAREL: 'Fashion and Apparel',
  COMMERCIAL_REAL_ESTATE: 'Commercial Real Estate',
  OTHER: 'Other',
};
export const INDUSTRY_TYPES_ICONS = {
  FASHION_AND_MERCHANDISING: 'ns-store',
  BEAUTY_AND_SPA: 'ns-beauty-spa',
  FOOD_AND_BEVERAGE: 'ns-food-light',
  REAL_ESTATE: 'ns-real-estate',
  FITNESS_AND_WELLNESS: 'ns-dumbbells',
  HOSPITALITY: 'ns-hospitality-2',
  TECHNOLOGY: 'ns-technology',
  RESTAURANT_AND_BAR: 'ns-food-light',
  BREWERY_AND_BREWPUB: 'ns-bar',
  HEALTH_AND_WELLNESS: 'ns-beauty-spa',
  FITNESS: 'ns-dumbbells',
  FASHION_AND_APPAREL: 'ns-store',
  OFFICE: 'ns-bag',
  COMMERCIAL_REAL_ESTATE: 'ns-real-estate',
  OTHER: 'ns-other',
};
export const CAMPAIGN_OFFERING_STATUS = {
  FUNDED: 'Funded',
  START_UP_PERIOD: 'Start up period',
  PRE_OPENING: 'Pre opening',
  IN_REPAYMENT: 'In repayment',
  IN_DEFAULT: 'In default',
};
const KEYTERMS_REGULATION_COMMON = {
  FP_TX: 'Rule 147, TX',
  FP_CF: 'Reg CF - US',
  BD_CF: 'Reg CF Offering',
  BD_506C: 'Reg D Offering',
  BD_506B: 'Reg B Offering',
};
export const CAMPAIGN_KEYTERMS_REGULATION = {
  ...KEYTERMS_REGULATION_COMMON,
  BD_CF_506C: 'Parallel Offering (Reg CF & Reg D)',
};

export const CAMPAIGN_KEYTERMS_REGULATION_PARALLEL = {
  ...KEYTERMS_REGULATION_COMMON,
  BD_CF_506C: <>Parallel Offering<br />(Reg CF &amp; Reg D)</>,
};

export const CAMPAIGN_KEYTERMS_REGULATION_ENUM = {
  FP_TX: 'FP_TX',
  FP_CF: 'FP_CF',
  BD_CF: 'BD_CF',
  BD_506C: 'BD_506C',
  BD_506B: 'BD_506B',
  BD_CF_506C: 'BD_CF_506C',
};

export const CAMPAIGN_REGULATION_DETAILED = {
  REGULATION: {
    FP_TX: 'Texas Intrastate Crowdfunding',
    FP_CF: 'Regulation Crowdfunding',
    BD_CF: 'Regulation Crowdfunding',
    BD_506C: 'Regulation D 506(c)',
    BD_506B: 'Regulation D 506(b)',
    BD_CF_506C: 'Parallel Offering (Reg CF & Reg D)',
  },
  TOOLTIP: {
    FP_TX: <span>Regulation Crowdfunding allows everyone to invest in private businesses. <a target="_blank" href="/resources/education-center/investor/making-a-reg-cf-investment">Learn more</a></span>,
    FP_CF: <span>Regulation Crowdfunding allows everyone to invest in private businesses. <a target="_blank" href="/resources/education-center/investor/making-a-reg-cf-investment">Learn more</a></span>,
    BD_CF: <span>Regulation Crowdfunding allows everyone to invest in private businesses. <a target="_blank" href="/resources/education-center/investor/making-a-reg-cf-investment">Learn more</a></span>,
    BD_506C: <span>Regulation D offerings are limited to accredited investors only. <a target="_blank" href="/resources/education-center/investor/making-a-reg-d-investment">Learn more</a></span>,
    BD_506B: <span>Regulation D offerings are limited to accredited investors only. <a target="_blank" href="/resources/education-center/investor/making-a-reg-d-investment">Learn more</a></span>,
    BD_CF_506C: <span>Parallel offerings are raising capital under two different exemptions from registration. A portion of this raise is being conducted under <a target="_blank" href="/resources/education-center/investor/making-a-reg-cf-investment">Regulation Crowdfunding</a> and a portion under Rule 506(c) of <a target="_blank" href="/resources/education-center/investor/making-a-reg-d-investment">Regulation D.</a></span>,
  },
};
export const CAMPAIGN_REGULATION_ABREVIATION = {
  FP_TX: 'TX INTRASTATE',
  FP_CF: 'REG CF',
  BD_CF: 'REG CF',
  BD_506C: 'REG D 506(C)',
  BD_506B: 'REG D 506(B)',
  BD_CF_506C: 'REG CF & REG D 506(C)',
};
export const CAMPAIGN_OFFERED_BY = {
  FP_TX: 'NextSeed TX LLC',
  FP_CF: 'NextSeed US LLC',
  BD_CF: 'NextSeed Securities, LLC',
  BD_506C: 'NextSeed Securities, LLC',
  BD_506B: 'NextSeed Securities, LLC',
  BD_CF_506C: 'NextSeed Securities, LLC',
};
export const OFFERING_COMMENTS_SCOPE = {
  NEXTSEED: { title: 'Internal Note', color: 'orange' },
  ISSUER: {
    titleITo: 'Note to NextSeed', titleIFrom: 'Note from NextSeed', titleTo: 'Note to Issuer', titleFrom: 'Note from Issuer', color: 'blue',
  },
};
export const OFFERING_ACCRDITATION_STATUS_MESSAGE = {
  PENDING: { header: 'Accreditation Verification In Review', subHeader: 'We are processing your accreditation request.  Please check back to make an investment after your accreditation has been approved.' },
  NOT_ELGIBLE: { header: 'Accreditation Verification Required', subHeader: 'You must be an accredited investor to make an investment in this offering.' },
  INACTIVE: { header: 'Accreditation Verification Required', subHeader: 'You must be an accredited investor to make an investment in this offering.' },
  EXPIRED: { header: 'Accreditation Expired - Renewal Required', subHeader: 'Your accreditation status has expired.  Please confirm the following to renew.' },
  ELGIBLE: { header: 'ELGIBLE', subHeader: '' },
};
export const CAMPAIGN_OFFERING_STAGE = {
  CREATION: 'Creation',
  LIVE: 'Live',
  LOCK: 'Lock',
  PROCESSING: 'Processing',
  FAILED: 'Failed',
  TERMINATED: 'Terminated',
  STARTUP_PERIOD: 'Startup Period',
  IN_REPAYMENT: 'Repayment',
  COMPLETE: 'Completed',
  DEFAULTED: 'Default',
};

export const OFFERING_REGULATIONS = {
  FP_TX: 'FP - TX',
  TX_CF: 'TX - CF',
  FP_CF: 'FP - CF',
  BD_CF: 'BD - CF',
  BD_506C: 'BD - 506C',
  BD_506B: 'BD - 506B',
  BD_CF_506C: 'BD - Parallel',
};

export const OFFERING_AGREEMENT_REGULATIONS = {
  BD_CF: 'NSS CF',
  BD_506C: 'RegD506C',
  FP_CF: 'NSUS CF',
};

export const CAMPAIGN_SECURITIES_DETAILED = {
  SECURITIES: {
    TERM_NOTE: 'Term Note',
    REVENUE_SHARING_NOTE: 'Revenue Sharing Note',
    PREFERRED_EQUITY_506C: 'Preferred Equity',
    EQUITY: 'Equity',
    CONVERTIBLE_NOTES: 'Convertible Notes',
    SAFE: 'Future Equity (SAFE)',
    REAL_ESTATE: 'LLC Membership Interests',
    FUNDS: 'Funds - Limited Partnership Interests',
  },
  TOOLTIP: {
    TERM_NOTE: <>With a Term Note, a business agrees to pay you a set amount of interest on your investment. Payments are fixed each month for a certain number of months. <a target="_blank" href="/education-center/investor/how-term-notes-work">Learn more</a></>,
    REVENUE_SHARING_NOTE: <>With a Revenue Sharing Note, a business agrees to pay you a percentage of their monthly revenue until you receive the total repayment amount. Payments, if any, may be different every month. <a target="_blank" href="/education-center/investor/how-revenue-sharing-notes-work">Learn more</a></>,
    CONVERTIBLE_NOTES: <>A Convertible Note is a hybrid security that starts as debt with a fixed interest rate and a maturity date. However, upon certain events in the future that establish a valuation for the business, such as future equity financing rounds, it can be converted into equity in the business.</>,
    SAFE: <>SAFEs are agreements that entitle the holders to discounted equity in a business in the future based on the valuation at a future financing event, such as a subsequent priced equity round or IPO. <a target="_blank" href="/education-center/investor/nextseed-safe">Learn more</a></>,
  },
};

export const INVESTOR_LIST_META = [
  { label: 'Date', value: 'investmentDate', mobile: false },
  { label: 'Time', value: 'investmentTime', mobile: false },
  { label: 'Investor\'s Name', value: 'firstName', mobile: false },
  { label: 'Amount', value: 'amount', mobile: true },
  { label: 'Residence City', value: 'city', mobile: true },
  { label: 'State', value: 'state', mobile: true },
  { label: 'Account Type', value: 'accountType', mobile: false },
  { label: 'Regulation', value: 'regulation', mobile: false },
  { label: 'EB', value: 'earlyBirdEligibility', mobile: false },
  { label: 'Referral Code', value: 'referralCode', mobile: true },
];

export const UPDATES_LIST_META = [
  { label: 'Title', value: 'title', mobile: false },
  { label: 'Published Date', value: 'publishedDate', mobile: false },
  { label: 'Recipients', value: 'scope', mobile: true },
  { label: 'Status', value: 'status', mobile: true },
  { label: 'Updated Date', value: 'updatedDate', mobile: true },
];

export const ADMIN_UPDATES_LIST_META = [
  ...UPDATES_LIST_META,
  { label: '', value: '', mobile: true },
];
