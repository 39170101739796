import gql from 'graphql-tag';

export const getUserReferralDetails = gql`
  query getUserReferralDetails($userId: String) {
    getUserReferralDetails(userId: $userId) {
      availableCredit
      spentCredit
      totalEarnedCredit
      totalReferredUsers
    }
  }
`;
