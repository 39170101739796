import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get, capitalize } from 'lodash';
import { matchPath, Link } from 'react-router-dom';
import { Form, Grid, Header, Button, Responsive, Visibility } from 'semantic-ui-react';
import SecondaryMenu from '../../../theme/layout/SecondaryMenu';
import { MobileDropDownNav, Logo } from '../../../theme/shared';
import NotFound from '../../shared/NotFound';
import LockUnlockOffering from '../admin/offerings/components/LockUnlockOffering';

const overrideContainerClass = ['account-details/:accountType/transactions'];
@inject('uiStore', 'navStore', 'userStore', 'userDetailsStore', 'offeringsStore')
@observer
class PrivateLayout extends Component {
  render() {
    const { location, navStore, offeringsStore, uiStore } = this.props;
    const pageMeta = navStore.navMeta;
    const { isInvestor, isIssuer, isAdmin } = this.props.userStore;
    const { match } = this.props;
    const { offer } = offeringsStore;
    const { isMobile } = uiStore.responsiveVars;
    const {
      processingAccounts,
      partialAccounts,
    } = this.props.userDetailsStore.signupStatus;
    const splittedUrl = match.url.split('/');
    const accType = splittedUrl.pop();
    const isAccProcessing = processingAccounts.includes(accType);
    const isAccPartial = partialAccounts.includes(accType);
    const splittedPathname = location.pathname.split('/');
    const issuerStep = splittedPathname.includes('campaign') ? 'Dashboard' : splittedPathname.includes('overview') ? 'General'
    : splittedPathname.includes('updates') ? 'Updates' : get(offer, 'stage') === 'CREATION' ? splittedPathname.slice(4)[0] : splittedPathname.pop();
    const showLock = issuerStep === 'General' || issuerStep === 'marketing' || issuerStep === 'legal';
    const isIssuerOffering = matchPath(location.pathname, { path: '/dashboard/offering/*' }) && isIssuer;
    const showPreview = issuerStep === 'General' || issuerStep === 'Dashboard';
    const dashboardView = pageMeta.title === 'Home';
    if (!pageMeta) {
      return <NotFound />;
    }
    return (
      <>
        <div className={`${isIssuer && !isMobile && dashboardView ? '' : 'page-header-section'} ${isInvestor ? 'investor' : isIssuer ? 'issuer' : ''}`}>
          <Grid columns="equal" stackable>
            <Grid.Row className={`${isIssuer ? 'pb-0' : ''}`}>
              <Grid.Column verticalAlign="middle">
                {!this.props.hideHeader && !dashboardView
                  ? (
                    <Header as={isInvestor ? (isMobile ? 'h5' : 'h3') : 'h1'}>
                      {this.props.forceTitle || pageMeta.heading || (isIssuer && !isMobile && dashboardView ? ''
                      : isAdmin ? pageMeta.title : <a href={`/offerings/${offer.offeringSlug}`} rel="noopener noreferrer" className="no-decoration green-hover" target="_blank">{pageMeta.title}</a>)}
                      {isIssuerOffering && issuerStep && !isMobile
                      && <span> | {capitalize(issuerStep)}</span>}
                      <Header.Subheader className="pull-right">
                        {!isMobile && (isAdmin || (showLock && isIssuer)) && get(offer, 'stage') === 'CREATION' && <LockUnlockOffering />}
                        {isIssuerOffering && !isMobile && <Button className="ml-16" color="green" as={Link} to={`/offerings/${offer.offeringSlug}`} target="_blank" inverted>Preview</Button>}
                      </Header.Subheader>
                    </Header>
                  ) : !this.props.hideHeader && dashboardView && isMobile
                  ? <Link to="/" className="d-flex justify-center"><Logo dataSrc="LogoGreenGrey" /></Link>
                  : ''}
              </Grid.Column>
              {this.props.P4
                && <Grid.Column only="large screen" width={this.props.buttonWidth ? this.props.buttonWidth : 3} floated={!isMobile ? 'right' : ''} textAlign={!isMobile ? 'right' : 'center'}>{this.props.P4}</Grid.Column>
              }
            </Grid.Row>
          </Grid>
        </div>
        {((pageMeta.subPanel === 1 || this.props.subNav) && !this.props.hideSubNav && !isIssuer && !(isInvestor && (isAccPartial || isAccProcessing)))
          && <SecondaryMenu addon={this.props.subNavAddon} noinvert refMatch={this.props.refMatch} match={this.props.match} attached="bottom" className={`${isInvestor ? 'investor' : ''} secondary-menu`} navItems={pageMeta.subNavigations} stepsStatus={this.props.appStepsStatus} rightLabel={this.props.rightLabel} />
        }
        {this.props.P1
          && (
            <div className="search-filters">
              <Form>
                <Grid stackable>
                  <Grid.Row>
                    {this.props.P1}
                  </Grid.Row>
                </Grid>
              </Form>
            </div>
          )
        }
        {this.props.P2}
        {this.props.P3}
        {this.props.P5}
        {splittedPathname.includes('offering')
        && (
          <Responsive maxWidth={991} as={React.Fragment}>
            <Visibility onUpdate={this.handleUpdate} continuous>
              <MobileDropDownNav
                inverted
                refMatch={match}
                navItems={pageMeta.subNavigations}
                location={location}
                useIsActive
                newLayout
                isAdmin={isAdmin}
                className="campaign-mobile-dropdown"
                isIssuerOffering={isIssuerOffering}
                issuerStep={issuerStep}
              />
            </Visibility>
          </Responsive>
        )}
        <div className={`${(overrideContainerClass.find(item => matchPath(location.pathname, { path: `/dashboard/${item}` }))) ? '' : 'content-spacer'} ${isIssuerOffering && 'issuer-content-spacer'}`}>
          {isIssuerOffering && isMobile && issuerStep !== 'comments' && (
            <>
            <Header as="h4" className="private-header">
                {capitalize(issuerStep)}
              <Header.Subheader className="private-sub-header">
                {/* <Link target="_blank" to={`/offerings/${offer.stage === 'CREATION' ? 'preview/' : ''}${offer.offeringSlug}`}>
                  <Icon className="ns-view" />
                </Link> */}
                {/* <Link target="_blank" to={`/offerings/${offer.stage === 'CREATION' ? 'preview/' : ''}${offer.offeringSlug}`} className="neutral-text green-hover no-decoration">
                  Preview
                </Link> */}
                {get(offer, 'stage') === 'CREATION' && showLock && <LockUnlockOffering />}
                <div className={isMobile ? 'pt-10' : ''}>
                  {isIssuerOffering && showPreview && <Button className="private-button" color="green" as={Link} to={`/offerings/${offer.offeringSlug}`} target="_blank" inverted>Preview</Button>}
                </div>
              </Header.Subheader>
            </Header>
          </>
          )}
          {this.props.children}
        </div>
      </>
    );
  }
}

export default PrivateLayout;
