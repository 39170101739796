import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Header, Container, Grid, Item } from 'semantic-ui-react';
import { WHY_NEXTSEED } from '../../../../services/constants/businessReferral';

@inject('uiStore')
@withRouter
@observer
class WhyNextseed extends Component {
  render() {
    const { responsiveVars } = this.props.uiStore;
    const { isMobile } = responsiveVars;
    return (
      <section className="bg-offwhite referral-section">
        <Container className={isMobile ? 'mb-20' : ''} textAlign={isMobile ? 'left' : 'center'}>
          <Grid stackable container columns={2} textAlign={isMobile ? 'left' : 'center'}>
            <Grid.Row>
              <Grid.Column computer={10}>
                <Header as="h2">
                  {WHY_NEXTSEED.header}
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid container stackable columns={2} relaxed="very" className="mb-30">
                {WHY_NEXTSEED.list.map(items => (
                  <Grid.Column className="referral-list">
                    <Item.Group className="left-align">
                      <p className="highlight-text">{items.subHeader}</p>
                      <Item>
                        <Item.Content>
                          {items.body}
                        </Item.Content>
                      </Item>
                    </Item.Group>
                  </Grid.Column>
                ))}
          </Grid>
        </Container>
      </section>
    );
  }
}

export default WhyNextseed;
