import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Header, Container, Grid, Item } from 'semantic-ui-react';
import { BUSINESS_TYPES } from '../../../../services/constants/businessReferral';
import NSImage from '../../../shared/NSImage';

@inject('uiStore')
@withRouter
@observer
class BusinessTypes extends Component {
  render() {
    const { responsiveVars } = this.props.uiStore;
    const { isMobile } = responsiveVars;
    return (
      <section className="bg-white referral-section">
        <Container className={isMobile ? 'mb-20' : ''} textAlign={isMobile ? 'left' : 'center'}>
          <Grid stackable columns={2} centered textAlign={isMobile ? 'left' : 'center'}>
            <Grid.Row>
              <Grid.Column computer={10}>
                <Header as="h2">
                  {BUSINESS_TYPES.header}
                </Header>
                <p>{BUSINESS_TYPES.body}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid columns={2} container stackable>
            <Grid.Row className="mb-30" centered>
              <Grid.Column className="referral-list" widescreen={6} computer={6}>
                <Item.Group className="left-align">
                  <p className="highlight-text">INDUSTRIES</p>
                  {BUSINESS_TYPES.list.industries.map(industry => (
                    <Item>
                      <div className={`${isMobile ? 'mlr-0' : ''} mobile-inline`}>
                        <NSImage path="icons/checkmark.svg" className="mt-half" />
                        <Item.Content>
                          {industry}
                        </Item.Content>
                      </div>
                    </Item>
                  ))}
                </Item.Group>
              </Grid.Column>
              <Grid.Column className="referral-list" widescreen={6} computer={6}>
                <Item.Group className="left-align">
                  <p className="highlight-text">DISTINGUISHING CHARACTERISTICS</p>
                  {BUSINESS_TYPES.list.characteristics.map(character => (
                    <Item>
                      <div className={`${isMobile ? 'mlr-0' : ''} mobile-inline`}>
                        <NSImage path="icons/checkmark.svg" className="mt-half" />
                      <Item.Content>
                        {character}
                      </Item.Content>
                      </div>
                    </Item>
                  ))}
                </Item.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <p className="note">{BUSINESS_TYPES.note}</p>
        </Container>
      </section>
    );
  }
}

export default BusinessTypes;
