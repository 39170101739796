import React from 'react';
import { Responsive } from 'semantic-ui-react';

export const MISSION_META = {
  header: <>We{'\''}ve built our brand and our platform<Responsive minWidth={768} as="br" /> on three core values</>,
  cards: [
    {
      header: 'Trust',
      key: 'trust',
      body: 'Our team of experts vet every offering on our platform, giving people real opportunities to invest in businesses they believe in.',
      stats: {
        value1: 'Top 3%',
        label1: <>Business applicants approved and launched campaigns<sup>1</sup></>,
        value2: '90%+',
        label2: <>Campaigns meet fundraising goals<sup>2</sup></>,
      },
    },
    {
      header: 'Innovation',
      key: 'innovation',
      body: 'We’re reinventing how local economies can grow from within by offering access to new forms of investments and capital.',
      stats: {
        value1: 'May 2016',
        label1: 'NextSeed US LLC became the first Funding Portal registered by the SEC',
        value2: 'October 2018',
        label2: 'NextSeed Securities, LLC is licensed and registered with the SEC as a broker-dealer',
      },
    },
    {
      header: 'Community',
      key: 'community',
      body: ' We empower entrepreneurs from all walks of life to follow their dreams and give back to their communities.',
      stats: {
        value1: '$15 million+',
        label1: <>Invested in women and minority-owned businesses<sup>3</sup></>,
        value2: '75%+',
        label2: <>Investment dollars go to women or minority-owned businesses<sup>4</sup></>,
      },
    },
  ],
};

export const MISSION_META_SUPS = [
  <><sup>1</sup> This calculates the percent of businesses that began the application
    process, passed NextSeed{'\''}s objective diligence <Responsive minWidth={992} as="br" />
    criteria, and launched an offering on the platform since NextSeed{'\''}s inception.</>,
  <><sup>2</sup> Historical figures only. Past performance of one business is not a
    guarantee of future results of another business.</>,
  <><sup>3,4</sup> Data reflects figures from offerings conducted by NextSeed TX LLC, NextSeed US LLC and NextSeed Securities, LLC as of October 2020.</>,
];

export const REPUBLIC_BANNER = {
  firstParagraph: <>
    NextSeed is joining forces with <a target="_blank" rel="noopener noreferrer" href="https://republic.co/?utm_source=nextseed&amp;utm_medium=website_homepage&amp;utm_campaign=ns_acq">Republic</a>,
    an industry leader among private investment platforms.
    We’re bringing our Main Street and debt financing expertise to a robust ecosystem that provides investors with
    direct access to investments in tech startups,
    gaming, crypto and real estate assets.
    </>,
  secondParagraph: `
    Our partners at Republic share our mission of democratizing finance through capital markets,
    and have grown tremendously in recent years,
    funding over $200 million through its online platform for retail investors as well as its private platform for accredited investors.
  `,
};

export const PRIVATE_INVESTING_COMPONENT = {
  header: 'Building the future of private investing, together',
  body: <>NextSeed has empowered everyday investors to invest directly in local businesses, enabling private companies across the US to raise capital directly from their community.<br /><br />
  By adding NextSeed to its ecosystem, Republic is gaining a strategic partner that shares its mission to build a financial system that will truly democratize finance and create more opportunities for everyone, while also adding additional industry and financing capabilities critical to fueling further growth and expansion.<br /><br />
  This partnership is a continuation of Republic’s efforts to expand its breadth of offerings and services, as the firm has grown tremendously in recent years, facilitating over $200 million through its online platform for retail investors as well as its private platform for accredited investors.<br /><br />
  In addition, NextSeed and Republic see a clear opportunity to bring a complete solution to cities large and small across the nation to reinforce local community growth for both tech innovation districts as well as Main Street small businesses.<br /><br /></>,
  linkText: 'What does this mean for the NextSeed community?',
  // eslint-disable-next-line no-useless-escape
  linkURL: '/education-center/investor/about-our-partnership?utm_source=nextseed\&utm_medium=website_about_page_faq\&utm_campaign=ns_acq',
};

export const REPUBLIC_INVESTMENT_OPPORTUNITIES = {
  key: 'republicModule',
  title: 'Now even more investment opportunities',
  description: <>NextSeed investors can now expand their portfolios with private investing opportunities<br />with high-growth potential across startups, gaming, real estate, and crypto.</>,
  buttonText: 'Explore All Offerings',
  desktopImages: ['republic/smallBusiness.png', 'republic/realEstate.png', 'republic/startups.png', 'republic/gaming.png', 'republic/crypto.png'],
  mobileImages: ['republic/smallBusinessMobile.png', 'republic/realEstateMobile.png', 'republic/startupsMobile.png', 'republic/gamingMobile.png', 'republic/cryptoMobile.png'],
  // eslint-disable-next-line no-useless-escape
  toLink: '/offerings?utm_source=nextseed\&utm_medium=website_about_page_button\&utm_campaign=ns_acq',
};
