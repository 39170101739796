import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Divider, Container, Header, Button, Responsive, Grid } from 'semantic-ui-react';
import Banner from '../components/Banner';
import HowItWorksSummary from '../components/HowItWorksSummary';
import ImpactfulInvestments from '../components/ImpactfulInvestments';
// import HowItWorks from '../components/HowItWorks';
// import FeaturedOn from '../../shared/components/FeaturedOn';
import CampaignList from '../../offering/components/listing/CampaignList';
// import SubscribeForNewsletter from '../../shared/components/SubscribeForNewsletter';
import { Logo } from '../../../../theme/shared';
// import RepublicHomeBanner from '../../republic/components/HomeBanner';

@inject('campaignStore', 'uiStore', 'userStore', 'publicStore', 'authStore')
@observer
class Home extends Component {
  constructor(props) {
    super(props);
    props.uiStore.setFieldvalue('inProgress', true);
    props.campaignStore.initRequest('COMPLETE', false, 'completedOfferings', true).finally(() => {
      props.campaignStore.initRequest('LIVE');
      props.uiStore.setFieldvalue('inProgress', false);
    });
  }

  handleButtonClick = (url) => {
    this.props.history.push(url);
    window.scrollTo(0, 0);
  }

  render() {
    const { active, completed, loading } = this.props.campaignStore;
    const { isMobile, uptoTablet } = this.props.uiStore.responsiveVars;
    const { isUserLoggedIn } = this.props.authStore;
    const { isIssuer } = this.props.userStore;
    const { setShowButton, getRedirectUrl } = this.props.publicStore;
    const { inProgress } = this.props.uiStore;
    const isFunded = true;
    setShowButton();
    getRedirectUrl();
    return (
      <>
        <Banner />
        <Responsive as={React.Fragment} fireOnMount onUpdate={this.handleOnUpdate}>
          <HowItWorksSummary
            isMobile={isMobile}
            uptoTablet={uptoTablet}
            isUserLoggedIn={isUserLoggedIn}
          />
        </Responsive>
        <div className="successCampaigns">
          <CampaignList
            loading={loading || inProgress}
            explore
            isFunded={isFunded}
            campaigns={completed.slice(0, 6)}
            heading={(
              <>
                <Header as="h2" textAlign={isMobile ? '' : 'center'} className={isMobile ? 'mt-20' : 'mt-50'}>Diversify your portfolio with investments <Responsive minWidth={768} as="br" />in revenue-sharing businesses</Header>
                <p className={`${isMobile ? 'mb-50' : 'mb-80 center-align'} neutral-text`}>
                  These are just a few of investments that have helped to pay out over $7M in cash returns to NextSeed investors.
                </p>
              </>
            )}
            loadMoreButton={(
              <div className={`${isMobile ? 'mb-20 mt-50' : 'mb-50 mt-70'} center-align`}>
                <Button fluid={isMobile} primary inverted color="green" content="view all past offerings" onClick={() => this.handleButtonClick('/offerings')} />
              </div>
            )}
          />
        </div>
        <CampaignList
          loading={loading || inProgress}
          explore
          isHomePage
          campaigns={active.slice(0, 6)}
          heading={(
            <>
              <Header as="h2" textAlign={isMobile ? '' : 'center'} className={isMobile ? 'mt-20' : 'mt-50'}>Offerings open for investment</Header>
              <p className={`${isMobile ? 'mb-50' : 'mb-80 center-align'} neutral-text`}>
                Explore pre-vetted investment opportunities available in a growing number of industry categories.
              </p>
            </>
          )}
          loadMoreButton={(
            <div className={`${isMobile ? 'mb-20 mt-50' : 'mb-50 mt-70'}  center-align`}>
              <Header as={isMobile ? 'h2' : 'h3'}>Ready to invest?</Header>
              <Button fluid={isMobile} primary color="green" content="browse current offerings" onClick={() => this.handleButtonClick('/offerings')} />
            </div>
          )}
        />
        <section className={`${isMobile ? 'pt-30 pb-30 mt-60' : 'pt-100 pb-100'}`}>
          <Container>
            <Grid className={`bg-offwhite ns-republic-box p-40 ${isMobile ? 'center-align' : ''}`} columns={3} stackable>
              <Grid.Column width={3}>
                <Logo dataSrc="RepublicFistsOnly" size={isMobile ? 'small' : 'medium'} />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as={isMobile ? 'h2' : 'h3'}>NextSeed is now part of Republic</Header>
                <p>
                  Republic — an industry leader among private investment platforms. On NextSeed, you can explore debt investments and offerings in Main Street businesses.{' '}
                  Learn more about opportunities across tech startups, gaming,{' '}
                  crypto and real estate assets at Republic.co.
                </p>
              </Grid.Column>
              <Grid.Column width={3}>
                <Button className="white-button" as={Link} to="/about">Learn More</Button>
              </Grid.Column>
            </Grid>
          </Container>
        </section>
        <ImpactfulInvestments isMobile={isMobile} />
        <Divider as={Container} fitted />
        <section className={`${isMobile ? 'pt-30 pb-50' : 'pt-100 pb-100'}`}>
          <Container className={isMobile ? 'mt-20' : 'mt-50 mb-50'}>
            <Grid columns={2} stackable className={`${isMobile ? '' : 'mb-80'} space-between`}>
              <Grid.Column width={7}>
                {isMobile && <Logo dataSrc="RepublicHand" size="tiny" />}
                <Header as="h2" className={isMobile ? 'mb-20' : 'mb-30'}>Looking to raise capital <Responsive minWidth={768} as="br" />for your growing business?</Header>
                <p className={`${isMobile ? '' : 'mb-60'} neutral-text`}>
                  Whether expanding or opening a brand-new concept, we make <Responsive minWidth={992} as="br" />
                  it easy to raise money from thousands of local investors.
                </p>
                {!isIssuer && !isMobile
                  && <Button as={Link} to="/business-application" className="relaxed" primary>Apply Online</Button>}
              </Grid.Column>
              <Grid.Column width={6}>
                {!isMobile && <Logo dataSrc="RepublicHand" size="Large" />}
                {!isIssuer && isMobile
                && <Button as={Link} to="/business-application" primary fluid className="mb-40 mt-10 relaxed">Apply Online</Button>}
              </Grid.Column>
            </Grid>
            <div className={!isMobile ? 'mt-120' : ''}>
              <p className="note"><sup>1</sup>Data reflects figures from offerings conducted by NextSeed TX LLC, NextSeed US LLC and NextSeed Securities, LLC as of October 2020</p>
              <p className="note"><sup>2</sup>This calculates the percent of businesses that began the application process, passed NextSeed&apos;s objective diligence criteria, and launched an offering on the platform since NextSeed&apos;s inception.</p>
            </div>
            {/* <Divider section /> */}
            {/* <Grid id="news-letter" columns={2} stackable doubling>
              <Grid.Column>
                <Header as="h2" className={isMobile ? 'mt-0 mb-10' : 'mt-80 mb-20'}>Join our newsletter</Header>
                <p className={`${isMobile ? 'mb-10' : ''} neutral-text`}>
                  Sign up to stay informed about new investment<Responsive minWidth={768} as="br" /> opportunities, updates and events.
                </p>
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <SubscribeForNewsletter className={`${isMobile ? 'mt-0' : 'mt-80'} public-form`} />
              </Grid.Column>
            </Grid> */}
          </Container>
        </section>
      </>
    );
  }
}

export default Home;
