/* eslint-disable no-console */
/**
 * Some generally used function definations being used at multiple places
 */
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';
import { toJS } from 'mobx';
import money from 'money-math';
import sanitizeHtml from 'sanitize-html';
import { Parser } from 'json2csv';
import apiService from '../api/restApi';
import { isLoggingEnabled, IMAGE_UPLOAD_ALLOWED_EXTENSIONS, DOCUMENT_UPLOAD_ALLOWED_EXTENSIONS, REACT_APP_DEPLOY_ENV } from '../constants/common';
import authStore from '../services/stores/entities/shared/authStore';
import userStore from '../services/stores/entities/userStore';
import DataFormatter from './utilities/DataFormatter';
import { CAMPAIGN_KEYTERMS_SECURITIES, CAMPAIGN_KEYTERMS_REGULATION_PARALLEL, CAMPAIGN_KEYTERMS_REGULATION } from '../constants/offering';
import { UPLOADS_CONFIG } from '../constants/aws';

export class Utility {
  // Default options for the toast
  options = {
    autoClose: 3800,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true,
    className: 'info',
  };

  /**
   * @desc To show alert notifications to the user
   * reference: https://fkhadra.github.io/react-toastify/
   */
  toast = (msg, alertType, optionsOverride) => {
    if (!userStore.isInvestor && (!['production', 'prod', 'master', 'staging'].includes(REACT_APP_DEPLOY_ENV) || window.location.href.includes('dev'))) {
      const cleanMsg = s => (s ? s.replace('GraphQL error: ', '') : '');
      if (alertType && _.includes(['error', 'success', 'info', 'warning'], alertType)) {
        toast[alertType](`${cleanMsg(msg)}`, _.merge({}, this.options, optionsOverride, { className: alertType }));
      } else {
        toast(`${cleanMsg(msg)}`, _.merge({}, this.options, optionsOverride));
      }
    }
  }

  unMaskInput = maskedInput => (
    maskedInput.split('-').join('')
  )

  matchRegexWithUrl = regexList => _.find(
    regexList,
    regex => (window.location.href.match(new RegExp(regex)) !== null),
  )

  matchRegexWithString = (regex, str) => str.match(new RegExp(regex, 'i')) !== null

  guid = () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return `${s4()}${s4()}-${s4()}${s4()}`;
  }

  getTotal = (from, key, isFloatToAmount = true) => {
    const total = '0.00';
    return from.map(f => (isFloatToAmount ? money.floatToAmount(f[key] || 0) : f[key] || 0))
      .map(r => money.add(total, r))
      .reduce((sum, n) => money.add(sum, n));
  }

  gAddressClean = (place) => {
    let result = {};
    const addressMap = {
      residentalStreet: ['street_number', 'route', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3'],
      city: ['locality'],
      state: ['administrative_area_level_1'],
      zipCode: ['postal_code'],
    };
    if (place.address_components) {
      Object.keys(addressMap).map(aK => place.address_components.map((c) => {
        if (_.intersection(addressMap[aK], c.types).length > 0) {
          const addressEle = {};
          addressEle[aK] = addressMap[aK].length > 2 && result[aK] ? `${result[aK]} ${c.long_name}` : c.long_name;
          result = _.has(result, aK) ? addressEle : { ...result, ...addressEle };
        }
        return result;
      }));
    }
    return result;
  }

  MoneyMathDisplayCurrency = (amount, fraction = true) => {
    try {
      return fraction ? `$${amount}` : `$${amount}`.split('.')[0];
    } catch (e) {
      return '$0.00';
    }
  }

  CommaFormat = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  CurrencyFormat = (amount, fraction = 2, maxFraction = 2) => new Intl.NumberFormat('en-US', {
    style: 'currency', currency: 'USD', minimumFractionDigits: fraction, maximumFractionDigits: maxFraction,
  }).format(amount)

  formattedSSNNumber = (ssnNumber) => {
    if (!ssnNumber) { return null; }
    // const cyrptedSSNNumber = ssnNumber.replace(/.(?=.{4,}$)/g, 'X');
    const cyrptedSSNNumber = ssnNumber;
    const formattedSSNNumber = `${cyrptedSSNNumber.substr(0, 3)}-${cyrptedSSNNumber.substr(3, 2)}-${cyrptedSSNNumber.substr(5, 4)}`;
    return formattedSSNNumber;
  }

  isUuid = value => value
  .match(new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/)) !== null

  encryptNumber = (number) => {
    if (!number) { return null; }
    let encryptedNumber = number.replace(/.(?=.{4,}$)/g, '...');
    encryptedNumber = encryptedNumber.slice(-7);
    return encryptedNumber;
  }

  encryptNumberWithX = (number) => {
    if (!number) { return null; }
    const encryptedNumber = number.replace(/.(?=.{4,}$)/g, 'X');
    return encryptedNumber;
  }

  encrypSsnNumberByForm = (form) => {
    const formData = _.cloneDeep(toJS({ ...form }));
    formData.ssn.value = this.encryptNumberWithX(formData.ssn.value).replace(/(.{3})(.{2})/, '$1-$2-');
    return formData;
  }

  replaceKeysDeep = (obj, keysMap) => _.transform(obj, (result, value, key) => {
    const resultTmp = result;
    const currentKey = keysMap[key] || key;
    resultTmp[currentKey] = _.isObject(value) ? this.replaceKeysDeep(value, keysMap) : value;
  });

  getFormattedFileData = (file) => {
    const fileData = {};
    if (file) {
      const fileInfo = file;
      fileData.fileName = this.sanitize(fileInfo.name);
      fileData.fileType = fileInfo.type;
      fileData.fileExtension = fileInfo.name.substr((fileInfo.name.lastIndexOf('.') + 1));
      fileData.fileSize = fileInfo.size;
    }
    return fileData;
  }

  isSpecialCharPresent = str => (str ? new RegExp(/[^a-z0-9._-]+/gi).test(str) : '');

  sanitize = name => (name ? name.replace(/[^a-z0-9._-]+/gi, '_') : '');

  putUploadedFile = urlArray => new Promise((resolve, reject) => {
    const funcArray = [];
    _.forEach(urlArray, (item) => {
      funcArray.push(apiService.uploadOnS3(item.preSignedUrl, item.fileData[0]));
    });
    Promise.all(funcArray).then(() => {
      resolve();
    })
      .catch((err) => {
        reject(err);
      });
  });

  maskPhoneNumber = (phoneNumber) => {
    const maskPhoneNumber = phoneNumber.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3');
    return maskPhoneNumber;
  }

  phoneNumberFormatter = (phoneNumber) => {
    const maskPhoneNumber = phoneNumber.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3');
    return maskPhoneNumber;
  }

  getDaysfromNow = (days) => {
    const d = new Date();
    let daysFromNow = d.setDate(d.getDate() + days);
    daysFromNow = new Date(daysFromNow).toISOString();
    return daysFromNow;
  }

  getLastThreeYearsLabel = () => {
    const currentYear = parseInt(moment().format('YYYY'), 10);
    return {
      annualIncomeCurrentYear: currentYear,
      annualIncomePreviousYear: currentYear - 1,
    };
  }

  otpShield = () => {
    try {
      const OtpItems = document.getElementsByClassName('otp-field')[0]
        ? document.getElementsByClassName('otp-field')[0]
          .getElementsByTagName('input') : '';
      for (let i = 0; i < OtpItems.length; i += 1) {
        OtpItems[i].addEventListener('keydown', (e) => {
          if ([16, 107, 110, 109, 69, 187, 188, 189, 190].includes(e.keyCode)) {
            e.preventDefault();
          }
        });
      }
    } catch (e) {
      window.logger(e);
    }
  }

  downloadCSV = (params) => {
    try {
      const parser = new Parser({ fields: params.fields, quote: params.quote || '' });
      const csv = parser.parse(params.data);
      const uri = `data:text/csv;charset=utf-8,${escape(csv)}`;
      const link = document.createElement('a');
      link.href = uri;
      link.style = 'visibility:hidden';
      link.download = `${params.fileName || 'download'}_${moment(new Date()).format('DD-MM-YYYY')}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
    }
  }

  isBase64 = (data) => {
    try {
      const block = data.split(';');
      return block[1].split(',')[0] === 'base64';
    } catch (e) {
      return false;
    }
  }

  b64toBlob = (data, sliceSize = 512) => {
    const block = data.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1];
    // get the real base64 content of the file
    const b64Data = block[1].split(',')[1];
    const size = sliceSize || 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += size) {
      const slice = byteCharacters.slice(offset, offset + size);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  removeSsn = () => {
    try {
      document.getElementsByName('ssn')[0].value = '';
    } catch (e) {
      window.logger(e);
    }
  }

  eventListnerHandler = (className, funName, action = 'add') => {
    const classname = document.getElementsByClassName(className);
    Array.from(classname).forEach((element) => {
      element[`${action}EventListener`]('click', this[funName]);
    });
  }

  toggleReadMore = (e) => {
    const htmlContent = e.target.closest('.parsed-data').querySelector('.html-toggle-content');
    const toggleButtonText = e.target.closest('.parsed-data').querySelector('.toggleReadMoreText');
    const arrowText = e.target.closest('.parsed-data').querySelector('.arrowText');
    const customTitle = e.target.closest('.parsed-data').querySelector('.customTitle');
    if (htmlContent.classList.contains('hide-content')) {
      htmlContent.classList.add('read-content');
      htmlContent.classList.remove('hide-content');
      customTitle.classList.add('hide-content');
      toggleButtonText.innerHTML = 'Collapse ';
      arrowText.innerHTML = '&#9652';
    } else {
      htmlContent.classList.add('hide-content');
      htmlContent.classList.remove('read-content');
      customTitle.classList.remove('hide-content');
      toggleButtonText.innerHTML = 'Expand ';
      arrowText.innerHTML = '&#9660';
      const parent = e.target.closest('.parsed-data').parentElement || e.target.closest('.parsed-data');
      const currentActiveHash = parent.previousElementSibling.querySelector('span').getAttribute('id');
      if (currentActiveHash) {
        document.querySelector(`#${currentActiveHash}`).scrollIntoView({
          block: 'start',
        });
      }
    }
  };

  logger = (params, type = 'log', email = false, error = '') => {
    if (isLoggingEnabled) {
      // eslint-disable-next-line no-unused-expressions
      type === 'info' ? console.info(params)
        : type === 'warn' ? console.warn(params)
          : type === 'clear' ? console.clear()
            : console.log(params);
      if (email) {
        this.sendAlertEmail(params, type, error);
      }
    } else if (!isLoggingEnabled && (type === 'warn' || type === 'info')) {
      // Send an email for these two type;
      this.sendAlertEmail(params, type, error);
    }
  }

  sendAlertEmail = (params, type, error) => {
    const emailP = {
      graphqlError: { operationName: `Logging ${type === 'warn' ? 'Warning' : type === 'info' ? 'Information' : 'error'} - ${params}` },
      urlLocation: window.location.href,
      message: _.get(error, 'stack'),
    };
    const emailParams = {
      emailContent: JSON.stringify(emailP),
    };
    authStore.notifyApplicationError(emailParams);
  }

  processImageFileName = (originalFileName, deviceInfo) => {
    const fileNameSplit = originalFileName.split('.');
    const fileExt = fileNameSplit.pop();
    const fileName = fileNameSplit.join('.');
    const { isMobile, isTablet } = deviceInfo;
    const prepName = res => `${fileName}${res ? `__${res}` : ''}.${fileExt}`;
    return IMAGE_UPLOAD_ALLOWED_EXTENSIONS.includes(fileExt.toLowerCase()) ? isMobile ? prepName(640) : isTablet ? prepName(1024) : prepName(1920) : prepName();
  }

  descriptionMetaTag = (module, content) => (_.get(module, content) ? _.get(module, content).replace(/<[^>]*>?/gm, '').slice(0, 155).trim() : '');

  getOgDataFromSocial = (obj, type, att) => {
    const data = _.find(obj, o => o.type === type);
    let val = _.get(data, att) || '';
    if (att === 'featuredImageUpload.url') {
      val = (val.includes('https://') || val.includes('http://')) ? val : `https://${UPLOADS_CONFIG.bucket}/${encodeURI(val)}`;
    }
    return val;
  };

  caseify = s => _.startCase(_.lowerCase(s));

  sanitizeContent = (c) => {
    try {
      return sanitizeHtml(c);
    } catch (e) {
      window.logger(e);
      return '';
    }
  };

  validateImageExtension = (ext, allowedGif = false) => {
    const ALLOWED_IMAGE_EXTENSIONS = allowedGif ? [...IMAGE_UPLOAD_ALLOWED_EXTENSIONS, 'gif'] : IMAGE_UPLOAD_ALLOWED_EXTENSIONS;
    const obj = {
      isInvalid: ext ? !ALLOWED_IMAGE_EXTENSIONS.includes(ext.toLowerCase()) : true,
      errorMsg: `Only ${ALLOWED_IMAGE_EXTENSIONS.join(', ')} extensions are allowed.`,
    };
    return obj;
  };

  validateDocumentExtension = (ext, specificUploadExtension = null) => {
    let UPLOAD_EXTENSIONS = DOCUMENT_UPLOAD_ALLOWED_EXTENSIONS;
    if (specificUploadExtension && specificUploadExtension.length > 0) {
      UPLOAD_EXTENSIONS = _.filter(UPLOAD_EXTENSIONS, n => specificUploadExtension.includes(n));
    }
    const obj = {
      isInvalid: ext ? !UPLOAD_EXTENSIONS.includes(ext.toLowerCase()) : true,
      errorMsg: `Only ${UPLOAD_EXTENSIONS.join(', ')} extensions are allowed.`,
    };
    return obj;
  };

  modalCssUpdate = (searchClass, addClass) => {
    const modal = document.querySelector(`.${searchClass}`).closest('.page');
    modal.classList.add(addClass);
  }

  customModalWrapper = () => {
    const mountNode = document.getElementsByClassName('custom-modal-wrapper');
    if (mountNode) {
      return mountNode[0];
    }
    const node = document.createElement('div');
    node.className = 'custom-modal-wrapper';
    document.body.appendChild(node);
    return node;
  }

  pageTitle = (t = '') => {
    try {
      return (!['production', 'prod', 'master'].includes(REACT_APP_DEPLOY_ENV) ? `[${REACT_APP_DEPLOY_ENV}] | ${t}` : t);
    } catch (e) {
      return 'Alternative Investments Made Simple - NextSeed';
    }
  };

  formatValue = (format, value) => {
    if (format && format.search('{{var}}') > -1) {
      const d = format.replace('{{var}}', value);
      return d;
    }
    return value;
  };

  checkAccreditationExpiryStatus = (expirationDate, isUnix = false) => {
    let dateDiff = '';
    if (expirationDate) {
      const date = (isUnix && typeof expirationDate === 'string') ? parseInt(expirationDate, 10) : expirationDate;
      dateDiff = DataFormatter.diffDays(DataFormatter.formatedDate(date, isUnix), false, true);
      return dateDiff < 0 ? 'EXPIRED' : 'ACTIVE';
    }
    return dateDiff;
  }

  getBoxAccountTypeByRegulation = (regulation) => {
    const offeringRegulationArr = (regulation && regulation.split('_')) || '';
    const regulationType = _.get(offeringRegulationArr, '[0]');
    return regulationType === 'BD' ? 'SECURITIES' : 'SERVICES';
  }

  enumToText = (valKey = '', value, fullText = false) => {
    let val = value;
    if (valKey) {
      let key = valKey.split('.');
      if (valKey && key.length) {
        key = key[key.length - 1];
        if (key === 'securities') {
          val = CAMPAIGN_KEYTERMS_SECURITIES[value];
        } else if (key === 'regulation') {
          val = fullText ? CAMPAIGN_KEYTERMS_REGULATION[value] : CAMPAIGN_KEYTERMS_REGULATION_PARALLEL[value];
        }
      }
    }
    return val;
  };

  cleanMsg = msg => (msg ? msg.replace('GraphQL error: ', '').replace('Error: ', '') : '');
}

export default new Utility();
