// MUST Match v2 Client tracking plan - https://app.segment.com/nextseed/protocols/tracking-plans/rs_1PNMxV0VkNbned6KlV2AaG5lEvH
export const ANALYTICS_INVESTOR_ACCOUNT_TYPE = 'INVESTOR';
export const ANALYTICS_INVEST_NOW_BUTTON_ACTION_NEW = 'NEW';
export const ANALYTICS_INVEST_NOW_BUTTON_ACTION_CHANGE = 'CHANGE';

export const ANALYTICS_FOLLOW_BUTTON_ACTION_FOLLOWED = 'FOLLOWED';
export const ANALYTICS_FOLLOW_BUTTON_ACTION_UNFOLLOWED = 'UNFOLLOWED';

export const BASIC_ACCOUNT_VALUE = 5;
export const BROKERAGE_ACCOUNT_VALUE = 7;
export const INVESTMENT_ACCOUNT_VALUE = 10;

export const LOGGED_IN_EVENT_NAME = 'Logged In';
export const LOGGED_OUT_EVENT_NAME = 'Logged Out';

export const BASIC_ACCOUNT_CREATED_EVENT_NAME = 'Basic Account Created';
export const BROKERAGE_ACCOUNT_CREATED_EVENT_NAME = 'Brokerage Account Created';
export const INVESTMENT_ACCOUNT_CREATED_EVENT_NAME = 'Investment Account Created';

export const ACCREDITED_INVESTOR_STATUS_REQUESTED_EVENT_NAME = 'Accredited Requested';
export const ACCREDITED_INVESTOR_STATUS_APPROVED_EVENT_NAME = 'Accredited Approved';

export const FOLLOW_BUTTON_CLICKED_EVENT_NAME = 'Follow Button Clicked';

export const INVEST_BUTTON_CLICKED_EVENT_NAME = 'Invest Button Clicked';
export const AGREEMENT_SIGNED_EVENT_NAME = 'Agreement Signed';

export const SUBSCRIBED_TO_NEWSLETTER_BUTTON_CLICKED_EVENT_NAME = 'Subscribed To Newsletter';
export const OFFERING_TILE_CLICK_EVENT_NAME = 'Offering Tile Clicked';


// Google Analytics ecom event names
export const GA_CURRENCY_CODE_USD = 'USD';
export const GA_ECOM_ORDER_COMPLETED = 'Order Completed';
