import React from 'react';
import Parser from 'html-react-parser';
import { get } from 'lodash';

const cleanMsg = (msg) => {
  try {
    return (msg ? msg.replace('GraphQL error: ', '') : msg);
  } catch (e) {
    return msg;
  }
};
class ListErrors extends React.Component {
  render() {
    const { errors } = this.props;
    if (errors) {
      return (
        errors.length === 1 ? (
          <p>{Parser(cleanMsg(get(errors, '[0].message') || errors[0]) || '')}</p>
        ) : (
          <ul className="error-messages">
            {Object.keys(errors).map(key => <li key={key}>{cleanMsg(errors[key])}</li>)}
          </ul>
        )
      );
    }
    return null;
  }
}

export default ListErrors;
