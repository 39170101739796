import React from 'react';
// import { Link } from 'react-router-dom';
import { Header, Container, Grid, Divider, Responsive } from 'semantic-ui-react';
import NSImage from '../../../shared/NSImage';

const HowItWorksSummary = ({ isMobile, uptoTablet }) => (
  <>
  <section>
    <Container className={isMobile ? 'mb-20' : ''} textAlign="left">
      <Grid>
        <Grid.Column widescreen={7} computer={7} tablet={16} mobile={16} stackable className={uptoTablet ? 'pb-0' : ''}>
          <NSImage NSImage path="Growth.svg" />
          <Header as="h2" className={`${uptoTablet ? 'mb-10' : 'mb-30'}`}>$7M+ paid out<Responsive minWidth={768} as="br" /> to investors</Header>
          <p className={`${uptoTablet ? '' : 'mb-30'} neutral-text`}>
            NextSeed has already paid out over $7M in cash returns <Responsive minWidth={768} as="br" />
            to investors. Earn potential cash payments through <Responsive minWidth={768} as="br" />
            unique revenue-share and debt financing investments.
          </p>
          {!isMobile
          && (
          <p className="note">
            This chart displays payments resulting from offerings facilitated by <Responsive minWidth={768} as="br" />
            NextSeed TX LLC, NextSeed US LLC, and NextSeed Securities, LLC between <Responsive minWidth={768} as="br" />
            the third quarter of 2015 and the third quarter of 2020 utilizing Texas&apos; <Responsive minWidth={768} as="br" />
            intrastate crowdfunding exemption, Regulations CF and D. Past results may <Responsive minWidth={768} as="br" />
            not be indicative of future performance, crowdfunding investments are <Responsive minWidth={768} as="br" />
            subject to total loss of principal.
          </p>
          )}
        </Grid.Column>
        <Grid.Column widescreen={9} computer={9} tablet={16} mobile={16} stackable className={uptoTablet ? 'pt-0' : ''}>
          <NSImage NSImage path="ns-graph.svg" />
        </Grid.Column>
        {isMobile
          && (
          <p className="note">
            This chart displays payments resulting from offerings facilitated by <Responsive minWidth={768} as="br" />
            NextSeed TX LLC, NextSeed US LLC, and NextSeed Securities, LLC between <Responsive minWidth={768} as="br" />
            the third quarter of 2015 and the fourth quarter of 2020 utilizing Texas&apos; <Responsive minWidth={768} as="br" />
            intrastate crowdfunding exemption, Regulations CF and D. Past results may <Responsive minWidth={768} as="br" />
            not be indicative of future performance, crowdfunding investments are <Responsive minWidth={768} as="br" />
            subject to total loss of principal.
          </p>
          )}
      </Grid>
    </Container>
  </section>
  <Divider as={Container} fitted />
  </>
);

export default HowItWorksSummary;
