/* eslint-disable react/no-did-mount-set-state, no-return-assign */
import React, { Component } from 'react';
import Slider from 'react-slick';
import { get } from 'lodash';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Image64 } from '..';

export default class NsCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const settings = {
      infinite: false,
      speed: 500,
      fade: this.props.fade,
      className: this.props.className,
      slidesToShow: this.props.slidesToShow,
      slidesToScroll: this.props.slidesToScroll,
      arrows: this.props.arrows,
      dots: this.props.dots,
      initialSlide: this.props.initialSlide || 0,
      adaptiveHeight: this.props.adaptiveHeight,
      beforeChange: (current, next) => {
        if (this.props.handlePaginationFun) {
          setTimeout(() => {
            this.props.handlePaginationFun(next);
          }, 5);
        }
      },
    };

    if (this.props.thumbs) {
      let thumbnailClassToApply = '';
      let thumbSliderCustomClassToApply = '';
      if (!this.props.customThumSliderClass) {
        if (this.props.isTablet) {
          thumbnailClassToApply = this.props.imageCount >= 4 ? 'slider-thumbnails' : 'slider-thumbnails custom-count';
        } else {
          thumbnailClassToApply = this.props.imageCount >= 10 ? 'slider-thumbnails' : 'slider-thumbnails custom-count';
        }
      } else {
        thumbnailClassToApply = 'slider-thumbnails';
      }
      thumbSliderCustomClassToApply = this.props.customThumSliderClass ? 'thumb-location-gallery' : '';
      return (
        <>
          <Slider
            {...settings}
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
          >
            {this.props.children}
          </Slider>
          <Slider
            {...settings}
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={this.props.thumbs}
            swipeToSlide
            focusOnSelect
            className={`${thumbnailClassToApply}  ${thumbSliderCustomClassToApply}`}
            variableWidth
          >
            {this.props.refItems.map(i => (
              <Image64 bg className="carousel-bg-thumb" srcUrl={this.props.isTemplate2 ? get(i, 'image.url') : i.url} />
            ))}
          </Slider>
        </>
      );
    }

    return (
      <Slider {...settings}>
        {this.props.children}
      </Slider>
    );
  }
}
