import { lazy } from 'react';

const retry = (fn, retriesLeft = 5, interval = 1000) => new Promise((resolve, reject) => {
  fn()
    .then(resolve)
    .catch((error) => {
      setTimeout(() => {
        if (retriesLeft === 1) {
          reject(error); // reject('maximum retries exceeded');
          return;
        }
        retry(fn, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
});

const lazyRetry = operation => lazy(() => retry(() => operation()));

export default lazyRetry;
