import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Header, Form, Container, Button, Modal, Divider } from 'semantic-ui-react';
import formHOC from '../../../../theme/form/formHOC';
import { REFERRAL_SIGNUP } from '../../../../services/constants/businessReferral';

const metaInfo = {
  store: 'authStore',
  form: 'BUSINESS_REFERRAL_FRM',
};

const ThanksNote = props => (
  <Modal open size="mini" closeIcon closeOnDimmerClick={false} onClose={props.closeModal}>
    <Modal.Header className="center-align signup-header">
      <Header as="h3">Thank you!</Header>
      <Divider section />
      <p>
        A member of the team will be in contact soon.
      </p>
      <Divider hidden />
    </Modal.Header>
  </Modal>
);
class ReferralSignup extends Component {
  state = { dialog: false };

  componentWillUnmount() {
    this.setState = ({ dialog: false });
    this.props.authStore.resetForm('BUSINESS_REFERRAL_FRM');
  }

  submit = () => {
    this.props.authStore.sendGeneralEmailToAdmin().then(() => {
      this.setState({ dialog: true });
    });
  }

  closeModal = () => {
    this.props.authStore.resetForm('BUSINESS_REFERRAL_FRM');
    window.location.reload();
    this.setState({ dialog: false });
  }

  render() {
    const { BUSINESS_REFERRAL_FRM } = this.props.authStore;
    const { inProgress } = this.props.uiStore;
    const { responsiveVars } = this.props.uiStore;
    const { smartElement } = this.props;
    const { dialog } = this.state;
    return (
      <section className={`${responsiveVars.isMobile ? 'pb-70' : 'pt-80 pb-80'} bg-offwhite referral-section`}>
        <Container>
          <Grid stackable columns={2}>
            <Grid.Column width={6}>
              <Header>
                {REFERRAL_SIGNUP.header}
              </Header>
              <p>{REFERRAL_SIGNUP.body}</p>
            </Grid.Column>
            <Grid.Column width={3} />
            <Grid.Column width={6} className="bg-white p-50">
              <Form onSubmit={this.submit} error className={responsiveVars.isMobile ? 'p-22' : ''}>
                { ['name', 'email', 'phone', 'businessName', 'businessContactName', 'businessContactEmail',
                'businessContactPhone'].map(field => (
                  field === 'phone' || field === 'businessContactPhone' ? smartElement.Masked(field, { disabled: inProgress, format: '(###) ###-####', phoneNumber: true })
                  : smartElement.Input(field, { disabled: inProgress })
                ))}
                {smartElement.FormDropDown('businessIndustry', { fielddata: BUSINESS_REFERRAL_FRM.fields.businessIndustry, containerclassname: 'dropdown-field', containerwidth: false, disabled: inProgress })}
                {smartElement.TextArea('businessAbout', { disabled: inProgress })}
                <Button disabled={inProgress} primary fluid content="EARN $500" />
              </Form>
              {dialog && <ThanksNote closeModal={this.closeModal} />}
            </Grid.Column>
          </Grid>
        </Container>
      </section>
    );
  }
}

export default inject('uiStore', 'authStore')(formHOC(observer(ReferralSignup), metaInfo));
