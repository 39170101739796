import React from 'react';
import { PopUpModal } from '../../theme/shared';
import { FormHelper } from '../../helper';

const isMobile = document.documentElement.clientWidth < 768;

export const SELF_VERIFICATION_INPUT_ENUMS = {
  NET_WORTH: 'NET_WORTH',
  INDIVIDUAL_INCOME: 'INDIVIDUAL_INCOME',
  JOINT_INCOME: 'JOINT_INCOME',
  FINRA_LICENSED: 'FINRA_LICENSED',
  KNOWLEDGEABLE_EMPLOYEE: 'KNOWLEDGEABLE_EMPLOYEE',
  SEC_ADVISER: 'SEC_ADVISER',
  FAMILY_CLIENTS: 'FAMILY_CLIENTS',
};

export const ENTITY_SELF_VERIFICATION_INPUT_ENUMS = {
  ANNUAL_REVENUE: 'ANNUAL_REVENUE',
  ASSETS: 'ASSETS',
  SEC_ADVISER: 'SEC_ADVISER',
  FAMILY_CLIENTS: 'FAMILY_CLIENTS',
};

export const SELF_VERIFICATION_META = {
  limited: {
    header: 'Do any of the following apply to you?',
    subHeader: 'If so, you may qualify as an accredited investor.',
    disclaimer: <>By clicking continue you represent that you qualify as an &quot;accredited investor&quot; under{' '}
      <a href="https://www.law.cornell.edu/cfr/text/17/230.501" target="_blank" rel="noopener noreferrer">SEC Rule 501</a>.</>,
  },
  unlimited: {
    header: <>
      Based on the info that you have provided you may be an {' '}
      <PopUpModal
        customTrigger={<span className="popup-label">accredited investor</span>}
        content={(
            <>You may qualify as an accredited investor if your annual income is $200k+ (or $300k+ together with your spouse)
            or if your net worth exceeds $1 million (excluding your primary residence). See our
            <a href="https://www.nextseed.com/education-center/investor/what-is-an-accredited-investor" target="_blank" rel="noopener noreferrer"> Education Center </a>
            for additional details.</>
          )}
        position="top center"
        showOnlyPopup={!isMobile}
        hoverable
      />
    </>,
    subHeader: 'To proceed as an accredited investor, please select the basis for your qualification',
    disclaimer: <>By clicking continue you represent that you qualify as an &quot;accredited investor&quot; under{' '}
      <a href="https://www.law.cornell.edu/cfr/text/17/230.501" target="_blank" rel="noopener noreferrer"> SEC Rule 501</a>.</>,
  },
  CRDInfoLink: <><a href="https://brokercheck.finra.org/" target="_blank" rel="noopener noreferrer">Click here to look up your CRD number</a></>,
};

export const SELF_VERIFICATION_INPUTS = FormHelper.generateMeta([
  ['finraMethod', '', [], 'optional', '', {
    props: {
      values: [
        {
          label: 'FINRA-licensed individual in good standing with Series 7, 82 or 65',
          key: 'finraLicensed',
          value: SELF_VERIFICATION_INPUT_ENUMS.FINRA_LICENSED,
          limitExceeded: false,
          context: '',
          order: 1,
          page: 1,
        },
      ],
      defaultValue: [],
    },
    asIn: true,
  }],
  ['selfVerificationMethods', '', [], 'optional', '', {
    props: {
      values: [
        {
          label: 'Have a net-worth in excess of $1,000,000, not including your primary residence',
          key: 'netWorth',
          value: SELF_VERIFICATION_INPUT_ENUMS.NET_WORTH,
          limitExceeded: true,
          context: '',
          order: 2,
          page: 1,
        },
        {
          label: 'You (or you and your spouse) have previously made and expect to make in excess of $200,000 ($300,000 if with the spouse) per calendar year',
          key: 'individualIncome',
          value: SELF_VERIFICATION_INPUT_ENUMS.INDIVIDUAL_INCOME,
          limitExceeded: true,
          context: '',
          order: 3,
          page: 1,
        },
        {
          label: 'SEC-or state-registered investment adviser or exempt reporting advisers',
          key: 'secAdviser',
          value: SELF_VERIFICATION_INPUT_ENUMS.SEC_ADVISER,
          limitExceeded: false,
          context: '',
          order: 5,
          page: 1,
        },
        {
          label: 'Family office with at least $5 million in assets under management or their “family clients"',
          key: 'familyClients',
          value: SELF_VERIFICATION_INPUT_ENUMS.FAMILY_CLIENTS,
          limitExceeded: false,
          context: '',
          order: 6,
          page: 1,
        },
      ],
      defaultValue: [],
    },
    asIn: true,
  }],
  ['finraCRD', SELF_VERIFICATION_META.CRDInfoLink, '', '', 'Please enter your CRD'],
]);

export const ENTITY_SELF_VERIFICATION_INPUTS = FormHelper.generateMeta([
  ['selfVerificationMethods', '', [], 'optional', '', {
    props: {
      values: [
        {
          label: 'LLC with at least $1M in annual revenue',
          key: 'annualRevenue',
          value: ENTITY_SELF_VERIFICATION_INPUT_ENUMS.ANNUAL_REVENUE,
          limitExceeded: true,
          context: '',
          order: 1,
          page: 1,
        },
        {
          label: 'LLC with at least $5M in assets, not formed for the specific purpose of investing',
          key: 'assets',
          value: ENTITY_SELF_VERIFICATION_INPUT_ENUMS.ASSETS,
          limitExceeded: true,
          context: '',
          order: 2,
          page: 1,
        },
        {
          label: 'SEC-or state-registered investment adviser or exempt reporting advisers',
          key: 'secAdviser',
          value: ENTITY_SELF_VERIFICATION_INPUT_ENUMS.SEC_ADVISER,
          limitExceeded: false,
          context: '',
          order: 3,
          page: 1,
        },
        {
          label: 'Family office with at least $5 million in assets under management or their "family clients"',
          key: 'familyClients',
          value: ENTITY_SELF_VERIFICATION_INPUT_ENUMS.FAMILY_CLIENTS,
          limitExceeded: false,
          context: '',
          order: 4,
          page: 1,
        },
      ],
      defaultValue: [],
    },
    asIn: true,
  }],
]);
