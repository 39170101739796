import shortid from 'shortid';

export const FORM_VALUES = [
  { name: 'name_of_business', placeholder: 'Business Name' },
  { name: 'shorthand_name', placeholder: 'Shorthand Name' },
  { name: 'type_of_business', placeholder: 'Business Type (legal types)' },
  { name: 'state_of_formation', placeholder: 'State of Formation' },
  { name: 'investment_multiple', placeholder: 'Investment Multiple (Rev Share Only)' },
  { name: 'revenue_share_percentage', placeholder: 'Revenue Share Percentage (Rev Share Only)' },
  { name: 'minimum_offering_amount', placeholder: 'Minimum offering amount' },
  { name: 'offering_amount', placeholder: 'Offering Amount' },
  { name: 'maturity_date', placeholder: 'Maturity Date' },
  { name: 'termination_date', placeholder: 'Termination Date' },
  { name: 'offer_date', placeholder: 'Offer Date' },
  { name: 'interest_rate', placeholder: 'Interest Rate (Term Only)' },
  { name: 'entity_name', placeholder: 'Entity Name' },
  { name: 'name_of_investor_sig', placeholder: 'Name of investor sig' },
  { name: 'investor_address_1', placeholder: 'Investor address 1' },
  { name: 'investor_address_2', placeholder: 'Investor address 2' },
  { name: 'investor_address_3', placeholder: 'Investor address 3' },
  { name: 'principal', placeholder: 'Principal' },
  { name: 'ivsdate', placeholder: 'Investor date' },
];

export const DOCFILE_TYPES = [];

export const FILES = [
  'Form C Issuer Certificate.docx',
  'Form Security Agreement.docx',
  'Form of Note Purchase Agreement - Revenue Share.docx',
  'Form of Note Purchase Agreement - Term Note.docx',
  'Goldstar Escrow Agreement (Form).docx',
  'National - Template Disclosure Statement (Rev).docx',
  'National - Template Disclosure Statement (Term).docx',
  'National Portal - Form of Crowdfunding Portal Agreement (Rev Share).docx',
  'National Portal - Form of Crowdfunding Portal Agreement (Term).docx',
  'Resolution - Borrowing Authority (Form).docx',
];

export const TEMPLATE_VARIABLES = {
  name_of_business: '',
  shorthand_name: '',
  investment_multiple: '',
  revenue_share_percentage: '',
  minimum_offering_amount: '',
  offering_amount: '',
  maturity_date: '',
  interest_rate: '',
  offer_date: '',
  state_of_formation: '',
  type_of_business: '',
  termination_date: '',
  entity_name: '',
  name_of_investor_sig: '',
  investor_address_1: '',
  investor_address_2: '',
  investor_address_3: '',
  principal: '',
  ivsdate: '',
};

export const PERSONAL_SIGNATURE = {
  id: shortid.generate(),
  personSignature: {
    value: '',
    error: undefined,
    rule: 'required|max:150',
    key: 'personSignature',
    label: 'Signature',
    customErrors: {
      required: 'The Signature field is required.',
    },
  },
  personTitle: {
    value: '',
    error: undefined,
    rule: 'required|max:256',
    key: 'personTitle',
    label: 'Title',
    customErrors: {
      required: 'The Title field is required.',
    },
  },
  signatureDate: {
    value: '',
    error: undefined,
    rule: 'required|date',
    key: 'signatureDate',
    label: 'Signature Date',
    customErrors: {
      required: 'The Date field is required.',
    },
  },
};

export const FILER_INFORMATION = {
  skipScreenshot: {
    value: false,
    key: 'skipScreenshot',
    label: 'Screenshot',
    error: undefined,
    rule: 'required|boolean',
    customErrors: {
      required: 'The Screenshot field is required.',
    },
  },
  offeringUrl: {
    value: '',
    key: 'offeringUrl',
    label: 'Website URL',
    error: undefined,
    rule: 'required|url',
    placeHolder: 'Website URL',
    tooltip: 'Please enter URL of page, for which screenshot will be generated',
    customErrors: {
      required: 'The Website URL field is required.',
      url: 'Provide valid Website URL',
    },
  },
  filerCik: {
    value: '',
    key: 'filerCik',
    placeHolder: 'Filer Cik',
    label: 'Filer Cik',
    error: undefined,
    rule: 'required|max:10',
    content: 'Please enter CIK of filer',
    customErrors: {
      required: 'The Filer Cik field is required.',
      max: 'The Filer Cik is too long. Max length is :max.',
    },
  },
  filerCcc: {
    value: '',
    key: 'filerCcc',
    placeHolder: 'Filer Ccc',
    label: 'Filer Ccc',
    error: undefined,
    rule: 'required|max:8',
    customErrors: {
      required: 'The Filer Ccc field is required.',
      max: 'The Filer Ccc is too long. Max length is :max.',
    },
  },
  fileNumber: {
    value: '',
    key: 'fileNumber',
    placeHolder: 'File number',
    label: 'File number',
    error: undefined,
    rule: 'max:17',
  },
  liveTestFlag: {
    value: 'TEST',
    key: 'liveTestFlag',
    values: [{ label: 'Live', value: 'LIVE' }, { label: 'Test', value: 'TEST' }],
    label: 'Live Test Flag',
    error: undefined,
    rule: 'required|in:LIVE,TEST',
  },
  confirmingCopyFlag: {
    value: false,
    key: 'confirmingCopyFlag',
    label: 'Confirming Copy Flag',
    error: undefined,
    rule: 'required|boolean',
    customErrors: {
      required: 'The Confirming Copy Flag field is required.',
    },
  },
  returnCopyFlag: {
    value: false,
    key: 'returnCopyFlag',
    label: 'Return Copy Flag',
    error: undefined,
    rule: 'required|boolean',
    customErrors: {
      required: 'The Return Copy Flag field is required.',
    },
  },
  contactName: {
    value: '',
    key: 'contactName',
    placeHolder: 'Name',
    label: 'Name',
    error: undefined,
    rule: 'required|max:30',
    customErrors: {
      required: 'The Contact Name field is required.',
    },
  },
  contactPhone: {
    value: '',
    key: 'contactPhone',
    placeHolder: 'Phone Number',
    label: 'Phone Number',
    error: undefined,
    rule: 'required|max:20',
    customErrors: {
      required: 'The Phone Number field is required.',
    },
  },
  contactEmail: {
    value: '',
    key: 'contactEmail',
    placeHolder: 'Email',
    label: 'Email',
    error: undefined,
    rule: 'required|email|max:80',
    customErrors: {
      required: 'The Email field is required.',
      email: 'Email is not valid',
    },
  },
  overrideInternetFlag: {
    value: false,
    key: 'overrideInternetFlag',
    label: 'overrideInternetFlag',
    error: undefined,
    rule: 'required|boolean',
  },
  notificationEmail: {
    value: [],
    key: 'notificationEmail',
    placeHolder: 'Enter notification email',
    label: 'Enter notification email',
    error: undefined,
    rule: 'required_if:overrideInternetFlag,true|array|max:3',
    customErrors: {
      max: 'You can\'t add more than 3 notification emails.',
    },
  },
};

export const ISSUER_INFORMATION = {
  nameOfIssuer: {
    value: '',
    key: 'nameOfIssuer',
    placeHolder: 'Name of Issuer',
    label: 'Name of Issuer',
    error: undefined,
    rule: 'required|max:150',
    customErrors: {
      required: 'The Name of Issuer field is required.',
      max: 'The Name of Issuer is too long. Max length is :max.',
    },
  },
  legalStatusForm: {
    value: '',
    key: 'legalStatusForm',
    placeHolder: 'Please select',
    label: 'Form',
    error: undefined,
    rule: 'required',
    customErrors: {
      required: 'The Legal Status of Issuer field is required.',
    },
  },
  legalStatusOtherDesc: {
    value: '',
    key: 'legalStatusOtherDesc',
    placeHolder: 'Other Description',
    label: 'Other Description',
    error: undefined,
    rule: 'required_if:legalStatusForm,Other|max:256',
    customErrors: {
      required_if: 'The Other Description field is required when Form is Other.',
      max: 'The Other Description is too long. Max length is :max.',
    },
  },
  jurisdictionOrganization: {
    value: '',
    key: 'jurisdictionOrganization',
    placeHolder: 'Please select',
    label: 'Jurisdiction of Incorporation/Organization',
    error: undefined,
    rule: 'required',
    customErrors: {
      required: 'The Jurisdiction of Incorporation/Organization field is required.',
    },
  },
  dateIncorporation: {
    value: '',
    key: 'dateIncorporation',
    placeHolder: 'Date of Incorporation/Organization',
    label: 'Date of Incorporation/Organization',
    error: undefined,
    rule: 'required|date',
    customErrors: {
      required: 'The Date of Incorporation/Organization field is required.',
    },
  },
  street1: {
    value: '',
    key: 'street1',
    placeHolder: 'Address Line 1',
    label: 'Address Line 1',
    error: undefined,
    rule: 'required|max:40',
    customErrors: {
      required: 'The Address Line 1 field is required.',
      max: 'The Address Line 1 is too long. Max length is :max.',
    },
  },
  street2: {
    value: '',
    key: 'street2',
    placeHolder: 'Address Line 2',
    label: 'Address Line 2',
    error: undefined,
    rule: 'max:40',
    customErrors: {
      max: 'The Address Line 2 is too long. Max length is :max.',
    },
  },
  city: {
    value: '',
    key: 'city',
    placeHolder: 'City',
    label: 'City',
    error: undefined,
    rule: 'required|max:30',
    customErrors: {
      required: 'The City field is required.',
      max: 'The City is too long. Max length is :max.',
    },
  },
  stateOrCountry: {
    value: '',
    key: 'stateOrCountry',
    placeHolder: 'State/Country',
    label: 'State/Country',
    error: undefined,
    rule: 'required',
    customErrors: {
      required: 'The State/Country field is required.',
    },
  },
  zipCode: {
    value: '',
    key: 'zipCode',
    placeHolder: 'Zip',
    label: 'Zip',
    error: undefined,
    rule: 'required|maskedField:5',
    customErrors: {
      required: 'The Zip field is required.',
      maskedField: 'The ZIP Code should be at least 5 digits',
    },
  },
  issuerWebsite: {
    value: '',
    key: 'issuerWebsite',
    placeHolder: 'Website of Issuer',
    label: 'Website of Issuer',
    error: undefined,
    rule: 'required|max:256',
    customErrors: {
      required: 'The Website of Issuer field is required.',
      max: 'The Website of Issuer is too long. Max length is :max.',
    },
  },
  companyName: {
    value: 'NextSeed Securities, LLC',
    key: 'companyName',
    placeHolder: 'Company Name',
    label: 'Company Name',
    error: undefined,
    rule: 'required|max:150',
    customErrors: {
      required: 'The Company Name field is required.',
      max: 'The Company Name is too long. Max length is :max.',
    },
  },
  commissionCik: {
    value: '0001723503',
    key: 'commissionCik',
    placeHolder: 'CIK Number of Intermediary',
    label: 'CIK',
    error: undefined,
    rule: 'required|max:10',
    customErrors: {
      required: 'The CIK field is required.',
      max: 'The CIK is too long. Max length is :max.',
    },
  },
  commissionFileNumber: {
    value: '008-70055',
    key: 'commissionFileNumber',
    placeHolder: 'Commission File Number',
    label: 'Commission File Number',
    error: undefined,
    rule: 'required',
    customErrors: {
      required: 'The Commission File Number field is required.',
    },
  },
  crdNumber: {
    value: '291387',
    key: 'crdNumber',
    placeHolder: 'CRD Number',
    label: 'CRD Number',
    error: undefined,
    rule: 'max:9',
    customErrors: {
      max: 'The CRD Number is too long. Max length is :max.',
    },
  },
};

export const OFFERING_INFORMATION = {
  compensationAmount: {
    value: '',
    placeHolder: 'Add text here',
    label: 'Amount of compensation to be paid to the intermediary,'
      + 'whether as a dollar amount or a percentage of the offering amount, '
      + 'or a good faith estimate if the exact amount is not available at the'
      + 'time of the filing, for conducting the offering, including the amount'
      + 'of referral and any other fees associated with the offering',
    key: 'compensationAmount',
    error: undefined,
    rule: 'required|max:256',
    customErrors: {
      required: 'This field is required',
      max: 'This is too long. Max length is :max.',
    },
  },
  financialInterest: {
    value: '',
    placeHolder: 'Add text here',
    label: 'Any other financial interest in the issuer held by the intermediary, '
      + 'or any arrangement for the intermediary to acquire such an interest',
    key: 'financialInterest',
    error: undefined,
    rule: 'required|max:256',
    customErrors: {
      required: 'This field is required',
      max: 'This is too long. Max length is :max.',
    },
  },
  securityOfferedType: {
    value: '',
    placeholder: 'Security Type',
    label: 'Type of Security Offered',
    key: 'securityOfferedType',
    error: undefined,
    rule: 'required',
    customErrors: {
      required: 'The Type of Security Offered field is required',
    },
  },
  securityOfferedOtherDesc: {
    value: '',
    placeHolder: 'Other Description',
    label: 'Other Description',
    key: 'securityOfferedOtherDesc',
    error: undefined,
    rule: 'max:256|required_if:securityOfferedType,Other',
    customErrors: {
      max: 'The Other Description is too long. Max length is :max.',
      required_if: 'The Other Description field is required when Type of Security Offered is Other.',
    },
  },
  noOfSecurityOffered: {
    value: '',
    placeHolder: '0.00',
    label: 'No. of securities offered',
    key: 'noOfSecurityOffered',
    error: undefined,
    rule: 'numeric|required_if:securityOfferedType,Other|required_if:securityOfferedType,Common Stock|'
      + 'required_if:securityOfferedType,Preferred Stock|max:9999999999',
    customErrors: {
      numeric: 'Please provide numeric values.',
      max: 'The No. of securities offered is too long. Max length is :max.',
    },
  },
  price: {
    value: '',
    placeHolder: '0.00000',
    label: 'Price',
    key: 'price',
    error: undefined,
    rule: 'numeric|max:999999|regex:/[\\+\\-]?\\d{0,7}\\.\\d{5}/',
    customErrors: {
      numeric: 'Please provide numeric values.',
    },
  },
  priceDeterminationMethod: {
    value: '',
    placeHolder: '0.00',
    label: 'Price (or Method for Determining Price)',
    key: 'priceDeterminationMethod',
    error: undefined,
    rule: 'required',
    customErrors: {
      required: 'The Price (or Method for Determining Price) field is required',
    },
  },
  offeringAmount: {
    value: '',
    placeHolder: '0.00',
    label: 'Target Offering Amount',
    key: 'offeringAmount',
    error: undefined,
    rule: 'required|numeric|max:9999999|regex:/[\\+\\-]?\\d{0,7}\\.\\d\\d/',
    customErrors: {
      required: 'The Target Offering Amount field is required',
      numeric: 'Please provide numeric values.',
    },
  },
  overSubscriptionAccepted: {
    value: 'Y',
    label: 'Yes',
    values: [{ label: 'Yes', value: 'Y' }, { label: 'No', value: 'N' }],
    key: 'overSubscriptionAccepted',
    error: undefined,
    rule: 'required',
    customErrors: {},
  },
  overSubscriptionAllocationType: {
    value: '',
    placeHolder: 'Select options',
    label: 'If yes, disclose how oversubscriptions will be allocated',
    key: 'overSubscriptionAllocationType',
    error: undefined,
    rule: 'required_if:overSubscriptionAccepted,Y',
    customErrors: {
      required_if: 'If yes, disclose how oversubscriptions will be allocated is required when Over subscriptions Accepted is Yes.',
    },
  },
  descOverSubscription: {
    value: '',
    placeHolder: 'Other Description',
    label: 'Other Description',
    key: 'descOverSubscription',
    error: undefined,
    rule: 'required_if:overSubscriptionAllocationType,Other|max:256',
    customErrors: {
      required_if: 'The Other Description field is required when oversubscriptions will be allocated is Other.',
    },
  },
  maximumOfferingAmount: {
    value: '',
    placeHolder: '0.00',
    label: 'Maximum Offering Amount (if different from Target Offering Amount)',
    key: 'maximumOfferingAmount',
    error: undefined,
    rule: 'required_if:overSubscriptionAccepted,Y|numeric|max:9999999|regex:/[\\+\\-]?\\d{0,7}\\.\\d\\d/',
    customErrors: {
      required_if: 'The Maximum Offering Amount (if different from Target Offering Amount) field is required when Over subscriptions Accepted is Yes.',
      numeric: 'Please provide numeric values.',
    },
  },
  deadlineDate: {
    value: '',
    placeHolder: 'Deadline Date',
    label: 'Deadline Date',
    key: 'deadlineDate',
    error: undefined,
    rule: 'required|date',
    customErrors: {
      required: 'The Deadline Date field is required.',
    },
  },
};

export const ANNUAL_REPORT_REQUIREMENTS = {
  currentEmployees: {
    value: '0',
    error: undefined,
    rule: 'required|numeric|max:9999999|min:0',
    key: 'currentEmployees',
    label: 'Current Number of Employees',
    customErrors: {
      required: 'The Current Number of Employees field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  totalAssetMostRecentFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'totalAssetMostRecentFiscalYear',
    label: 'Most Recent Fiscal Year-end',
    customErrors: {
      required: 'The Most Recent Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  totalAssetPriorFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'totalAssetPriorFiscalYear',
    label: 'Prior Fiscal Year-end',
    customErrors: {
      required: 'The Prior Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  cashEquiMostRecentFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'cashEquiMostRecentFiscalYear',
    label: 'Most Recent Fiscal Year-end',
    customErrors: {
      required: 'The Most Recent Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  cashEquiPriorFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'cashEquiPriorFiscalYear',
    label: 'Prior Fiscal Year-end',
    customErrors: {
      required: 'The Prior Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  actReceivedMostRecentFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'actReceivedMostRecentFiscalYear',
    label: 'Most Recent Fiscal Year-end',
    customErrors: {
      required: 'The Most Recent Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  actReceivedPriorFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'actReceivedPriorFiscalYear',
    label: 'Prior Fiscal Year-end',
    customErrors: {
      required: 'The Prior Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  shortTermDebtMostRecentFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'shortTermDebtMostRecentFiscalYear',
    label: 'Most Recent Fiscal Year-end',
    customErrors: {
      required: 'The Most Recent Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  shortTermDebtPriorFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'shortTermDebtPriorFiscalYear',
    label: 'Prior Fiscal Year-end',
    customErrors: {
      required: 'The Prior Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  longTermDebtMostRecentFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'longTermDebtMostRecentFiscalYear',
    label: 'Most Recent Fiscal Year-end',
    customErrors: {
      required: 'The Most Recent Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  longTermDebtPriorFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'longTermDebtPriorFiscalYear',
    label: 'Prior Fiscal Year-end',
    customErrors: {
      required: 'The Prior Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  revenueMostRecentFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'revenueMostRecentFiscalYear',
    label: 'Most Recent Fiscal Year-end',
    customErrors: {
      required: 'The Most Recent Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  revenuePriorFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'revenuePriorFiscalYear',
    label: 'Prior Fiscal Year-end',
    customErrors: {
      required: 'The Prior Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  costGoodsSoldMostRecentFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'costGoodsSoldMostRecentFiscalYear',
    label: 'Most Recent Fiscal Year-end',
    customErrors: {
      required: 'The Most Recent Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  costGoodsSoldPriorFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'costGoodsSoldPriorFiscalYear',
    label: 'Prior Fiscal Year-end',
    customErrors: {
      required: 'The Prior Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  taxPaidMostRecentFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'taxPaidMostRecentFiscalYear',
    label: 'Most Recent Fiscal Year-end',
    customErrors: {
      required: 'The Most Recent Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  taxPaidPriorFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'taxPaidPriorFiscalYear',
    label: 'Prior Fiscal Year-end',
    customErrors: {
      required: 'The Prior Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  netIncomeMostRecentFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'netIncomeMostRecentFiscalYear',
    label: 'Most Recent Fiscal Year-end',
    customErrors: {
      required: 'The Most Recent Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  netIncomePriorFiscalYear: {
    value: '0.00',
    error: undefined,
    rule: 'required|numeric|max:99999999999999.99|regex:/[\\+\\-]?\\d{0,14}\\.\\d{2}/',
    key: 'netIncomePriorFiscalYear',
    label: 'Prior Fiscal Year-end',
    customErrors: {
      required: 'The Prior Fiscal Year-end field is required.',
      numeric: 'Please provide numeric values.',
    },
  },
  issueJurisdictionSecuritiesOffering: {
    value: '',
    error: undefined,
    rule: 'required|max:53',
    key: 'issueJurisdictionSecuritiesOffering',
    label: 'Using the list below, select the jurisdictions in which the issuer intends to offer the securities',
    customErrors: {
      required: 'The States And Jurisdictions field is required.',
    },
  },
};

export const SIGNATURE = {
  issuer: {
    value: '',
    error: undefined,
    rule: 'required|max:150',
    key: 'issuer',
    label: 'Issuer',
    customErrors: {
      required: 'The Issuer field is required.',
    },
  },
  issuerSignature: {
    value: '',
    error: undefined,
    rule: 'required|max:150',
    key: 'issuerSignature',
    label: 'Signature',
    customErrors: {
      required: 'The Signature field is required.',
    },
  },
  issuerTitle: {
    value: '',
    error: undefined,
    rule: 'required|max:256',
    key: 'issuerTitle',
    label: 'Title',
    customErrors: {
      required: 'The Title field is required.',
    },
  },
  signaturePersons: [PERSONAL_SIGNATURE],
};

export const OFFERED_SECURITIES = [
  { key: 'Common Stock', value: 'Common Stock', text: 'Common Stock' },
  { key: 'Preferred Stock', value: 'Preferred Stock', text: 'Preferred Stock' },
  { key: 'Debt', value: 'Debt', text: 'Debt' },
  { key: 'Other', value: 'Other', text: 'Other' },
];

export const OVER_SUBSCRIPTION_ALLOCATION_TYPES = [
  { key: 'Pro-rata basis', value: 'Pro-rata basis', text: 'Pro-rata basis' },
  {
    key: 'First-come, first-served basis',
    value: 'First-come, first-served basis',
    text: 'First-come, first-served basis',
  },
  { key: 'Other', value: 'Other', text: 'Other' },
];

export const LEGAL_FORM_TYPES = [
  { key: 'Corporation', value: 'Corporation', text: 'Corporation' },
  { key: 'Limited Partnership', value: 'Limited Partnership', text: 'Limited Partnership' },
  {
    key: 'Limited Liability Company',
    value: 'Limited Liability Company',
    text: 'Limited Liability Company',
  },
  { key: 'General Partnership', value: 'General Partnership', text: 'General Partnership' },
  { key: 'Business Trust', value: 'Business Trust', text: 'Business Trust' },
  { key: 'Other', value: 'Other', text: 'Other (Specify)' },
];

export const COUNTRIES = [
  { key: 'AL', value: 'AL', text: 'ALABAMA' },
  { key: 'AK', value: 'AK', text: 'ALASKA' },
  { key: 'AZ', value: 'AZ', text: 'ARIZONA' },
  { key: 'AR', value: 'AR', text: 'ARKANSAS' },
  { key: 'CA', value: 'CA', text: 'CALIFORNIA' },
  { key: 'CO', value: 'CO', text: 'COLORADO' },
  { key: 'CT', value: 'CT', text: 'CONNECTICUT' },
  { key: 'DE', value: 'DE', text: 'DELAWARE' },
  { key: 'DC', value: 'DC', text: 'DISTRICT OF COLUMBIA' },
  { key: 'FL', value: 'FL', text: 'FLORIDA' },
  { key: 'GA', value: 'GA', text: 'GEORGIA' },
  { key: 'HI', value: 'HI', text: 'HAWAII' },
  { key: 'ID', value: 'ID', text: 'IDAHO' },
  { key: 'IL', value: 'IL', text: 'ILLINOIS' },
  { key: 'IN', value: 'IN', text: 'INDIANA' },
  { key: 'IA', value: 'IA', text: 'IOWA' },
  { key: 'KS', value: 'KS', text: 'KANSAS' },
  { key: 'KY', value: 'KY', text: 'KENTUCKY' },
  { key: 'LA', value: 'LA', text: 'LOUISIANA' },
  { key: 'ME', value: 'ME', text: 'MAINE' },
  { key: 'MD', value: 'MD', text: 'MARYLAND' },
  { key: 'MA', value: 'MA', text: 'MASSACHUSETTS' },
  { key: 'MI', value: 'MI', text: 'MICHIGAN' },
  { key: 'MN', value: 'MN', text: 'MINNESOTA' },
  { key: 'MS', value: 'MS', text: 'MISSISSIPPI' },
  { key: 'MO', value: 'MO', text: 'MISSOURI' },
  { key: 'MT', value: 'MT', text: 'MONTANA' },
  { key: 'NE', value: 'NE', text: 'NEBRASKA' },
  { key: 'NV', value: 'NV', text: 'NEVADA' },
  { key: 'NH', value: 'NH', text: 'NEW HAMPSHIRE' },
  { key: 'NJ', value: 'NJ', text: 'NEW JERSEY' },
  { key: 'NM', value: 'NM', text: 'NEW MEXICO' },
  { key: 'NY', value: 'NY', text: 'NEW YORK' },
  { key: 'NC', value: 'NC', text: 'NORTH CAROLINA' },
  { key: 'ND', value: 'ND', text: 'NORTH DAKOTA' },
  { key: 'OH', value: 'OH', text: 'OHIO' },
  { key: 'OK', value: 'OK', text: 'OKLAHOMA' },
  { key: 'OR', value: 'OR', text: 'OREGON' },
  { key: 'PA', value: 'PA', text: 'PENNSYLVANIA' },
  { key: 'RI', value: 'RI', text: 'RHODE ISLAND' },
  { key: 'SC', value: 'SC', text: 'SOUTH CAROLINA' },
  { key: 'SD', value: 'SD', text: 'SOUTH DAKOTA' },
  { key: 'TN', value: 'TN', text: 'TENNESSEE' },
  { key: 'TX', value: 'TX', text: 'TEXAS' },
  { key: 'X1', value: 'X1', text: 'UNITED STATES' },
  { key: 'UT', value: 'UT', text: 'UTAH' },
  { key: 'VT', value: 'VT', text: 'VERMONT' },
  { key: 'VA', value: 'VA', text: 'VIRGINIA' },
  { key: 'WA', value: 'WA', text: 'WASHINGTON' },
  { key: 'WV', value: 'WV', text: 'WEST VIRGINIA' },
  { key: 'WI', value: 'WI', text: 'WISCONSIN' },
  { key: 'WY', value: 'WY', text: 'WYOMING' },
  { key: 'A0', value: 'A0', text: 'ALBERTA, CANADA' },
  { key: 'A1', value: 'A1', text: 'BRITISH COLUMBIA, CANADA' },
  { key: 'A2', value: 'A2', text: 'MANITOBA, CANADA' },
  { key: 'A3', value: 'A3', text: 'NEW BRUNSWICK, CANADA' },
  { key: 'A4', value: 'A4', text: 'NEWFOUNDLAND, CANADA' },
  { key: 'A5', value: 'A5', text: 'NOVA SCOTIA, CANADA' },
  { key: 'A6', value: 'A6', text: 'ONTARIO, CANADA' },
  { key: 'A7', value: 'A7', text: 'PRINCE EDWARD ISLAND, CANADA' },
  { key: 'A8', value: 'A8', text: 'QUEBEC, CANADA' },
  { key: 'A9', value: 'A9', text: 'SASKATCHEWAN, CANADA' },
  { key: 'B0', value: 'B0', text: 'YUKON, CANADA' },
  { key: 'Z4', value: 'Z4', text: 'CANADA (FEDERAL LEVEL)' },
  { key: 'B2', value: 'B2', text: 'AFGHANISTAN' },
  { key: 'Y6', value: 'Y6', text: 'ALAND ISLANDS' },
  { key: 'B3', value: 'B3', text: 'ALBANIA' },
  { key: 'B4', value: 'B4', text: 'ALGERIA' },
  { key: 'B5', value: 'B5', text: 'AMERICAN SAMOA' },
  { key: 'B6', value: 'B6', text: 'ANDORRA' },
  { key: 'B7', value: 'B7', text: 'ANGOLA' },
  { key: '1A', value: '1A', text: 'ANGUILLA' },
  { key: 'B8', value: 'B8', text: 'ANTARCTICA' },
  { key: 'B9', value: 'B9', text: 'ANTIGUA AND BARBUDA' },
  { key: 'C1', value: 'C1', text: 'ARGENTINA' },
  { key: '1B', value: '1B', text: 'ARMENIA' },
  { key: '1C', value: '1C', text: 'ARUBA' },
  { key: 'C3', value: 'C3', text: 'AUSTRALIA' },
  { key: 'C4', value: 'C4', text: 'AUSTRIA' },
  { key: '1D', value: '1D', text: 'AZERBAIJAN' },
  { key: 'C5', value: 'C5', text: 'BAHAMAS' },
  { key: 'C6', value: 'C6', text: 'BAHRAIN' },
  { key: 'C7', value: 'C7', text: 'BANGLADESH' },
  { key: 'C8', value: 'C8', text: 'BARBADOS' },
  { key: '1F', value: '1F', text: 'BELARUS' },
  { key: 'C9', value: 'C9', text: 'BELGIUM' },
  { key: 'D1', value: 'D1', text: 'BELIZE' },
  { key: 'G6', value: 'G6', text: 'BENIN' },
  { key: 'D0', value: 'D0', text: 'BERMUDA' },
  { key: 'D2', value: 'D2', text: 'BHUTAN' },
  { key: 'D3', value: 'D3', text: 'BOLIVIA' },
  { key: '1E', value: '1E', text: 'BOSNIA AND HERZEGOVINA' },
  { key: 'B1', value: 'B1', text: 'BOTSWANA' },
  { key: 'D4', value: 'D4', text: 'BOUVET ISLAND' },
  { key: 'D5', value: 'D5', text: 'BRAZIL' },
  { key: 'D6', value: 'D6', text: 'BRITISH INDIAN OCEAN TERRITORY' },
  { key: 'D9', value: 'D9', text: 'BRUNEI DARUSSALAM' },
  { key: 'E0', value: 'E0', text: 'BULGARIA' },
  { key: 'X2', value: 'X2', text: 'BURKINA FASO' },
  { key: 'E2', value: 'E2', text: 'BURUNDI' },
  { key: 'E3', value: 'E3', text: 'CAMBODIA' },
  { key: 'E4', value: 'E4', text: 'CAMEROON' },
  { key: 'E8', value: 'E8', text: 'CAPE VERDE' },
  { key: 'E9', value: 'E9', text: 'CAYMAN ISLANDS' },
  { key: 'F0', value: 'F0', text: 'CENTRAL AFRICAN REPUBLIC' },
  { key: 'F2', value: 'F2', text: 'CHAD' },
  { key: 'F3', value: 'F3', text: 'CHILE' },
  { key: 'F4', value: 'F4', text: 'CHINA' },
  { key: 'F6', value: 'F6', text: 'CHRISTMAS ISLAND' },
  { key: 'F7', value: 'F7', text: 'COCOS (KEELING) ISLANDS' },
  { key: 'F8', value: 'F8', text: 'COLOMBIA' },
  { key: 'F9', value: 'F9', text: 'COMOROS' },
  { key: 'G0', value: 'G0', text: 'CONGO' },
  { key: 'Y3', value: 'Y3', text: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE' },
  { key: 'G1', value: 'G1', text: 'COOK ISLANDS' },
  { key: 'G2', value: 'G2', text: 'COSTA RICA' },
  { key: 'L7', value: 'L7', text: 'COTE D\'IVOIRE' },
  { key: '1M', value: '1M', text: 'CROATIA' },
  { key: 'G3', value: 'G3', text: 'CUBA' },
  { key: 'G4', value: 'G4', text: 'CYPRUS' },
  { key: '2N', value: '2N', text: 'CZECH REPUBLIC' },
  { key: 'G7', value: 'G7', text: 'DENMARK' },
  { key: '1G', value: '1G', text: 'DJIBOUTI' },
  { key: 'G9', value: 'G9', text: 'DOMINICA' },
  { key: 'G8', value: 'G8', text: 'DOMINICAN REPUBLIC' },
  { key: 'H1', value: 'H1', text: 'ECUADOR' },
  { key: 'H2', value: 'H2', text: 'EGYPT' },
  { key: 'H3', value: 'H3', text: 'EL SALVADOR' },
  { key: 'H4', value: 'H4', text: 'EQUATORIAL GUINEA' },
  { key: '1J', value: '1J', text: 'ERITREA' },
  { key: '1H', value: '1H', text: 'ESTONIA' },
  { key: 'H5', value: 'H5', text: 'ETHIOPIA' },
  { key: 'H7', value: 'H7', text: 'FALKLAND ISLANDS (MALVINAS)' },
  { key: 'H6', value: 'H6', text: 'FAROE ISLANDS' },
  { key: 'H8', value: 'H8', text: 'FIJI' },
  { key: 'H9', value: 'H9', text: 'FINLAND' },
  { key: 'I0', value: 'I0', text: 'FRANCE' },
  { key: 'I3', value: 'I3', text: 'FRENCH GUIANA' },
  { key: 'I4', value: 'I4', text: 'FRENCH POLYNESIA' },
  { key: '2C', value: '2C', text: 'FRENCH SOUTHERN TERRITORIES' },
  { key: 'I5', value: 'I5', text: 'GABON' },
  { key: 'I6', value: 'I6', text: 'GAMBIA' },
  { key: '2Q', value: '2Q', text: 'GEORGIA' },
  { key: '2M', value: '2M', text: 'GERMANY' },
  { key: 'J0', value: 'J0', text: 'GHANA' },
  { key: 'J1', value: 'J1', text: 'GIBRALTAR' },
  { key: 'J3', value: 'J3', text: 'GREECE' },
  { key: 'J4', value: 'J4', text: 'GREENLAND' },
  { key: 'J5', value: 'J5', text: 'GRENADA' },
  { key: 'J6', value: 'J6', text: 'GUADELOUPE' },
  { key: 'GU', value: 'GU', text: 'GUAM' },
  { key: 'J8', value: 'J8', text: 'GUATEMALA' },
  { key: 'Y7', value: 'Y7', text: 'GUERNSEY' },
  { key: 'J9', value: 'J9', text: 'GUINEA' },
  { key: 'S0', value: 'S0', text: 'GUINEA-BISSAU' },
  { key: 'K0', value: 'K0', text: 'GUYANA' },
  { key: 'K1', value: 'K1', text: 'HAITI' },
  { key: 'K4', value: 'K4', text: 'HEARD ISLAND AND MCDONALD ISLANDS' },
  { key: 'X4', value: 'X4', text: 'HOLY SEE (VATICAN CITY STATE)' },
  { key: 'K2', value: 'K2', text: 'HONDURAS' },
  { key: 'K3', value: 'K3', text: 'HONG KONG' },
  { key: 'K5', value: 'K5', text: 'HUNGARY' },
  { key: 'K6', value: 'K6', text: 'ICELAND' },
  { key: 'K7', value: 'K7', text: 'INDIA' },
  { key: 'K8', value: 'K8', text: 'INDONESIA' },
  { key: 'K9', value: 'K9', text: 'IRAN, ISLAMIC REPUBLIC OF' },
  { key: 'L0', value: 'L0', text: 'IRAQ' },
  { key: 'L2', value: 'L2', text: 'IRELAND' },
  { key: 'Y8', value: 'Y8', text: 'ISLE OF MAN' },
  { key: 'L3', value: 'L3', text: 'ISRAEL' },
  { key: 'L6', value: 'L6', text: 'ITALY' },
  { key: 'L8', value: 'L8', text: 'JAMAICA' },
  { key: 'M0', value: 'M0', text: 'JAPAN' },
  { key: 'Y9', value: 'Y9', text: 'JERSEY' },
  { key: 'M2', value: 'M2', text: 'JORDAN' },
  { key: '1P', value: '1P', text: 'KAZAKSTAN' },
  { key: 'M3', value: 'M3', text: 'KENYA' },
  { key: 'J2', value: 'J2', text: 'KIRIBATI' },
  { key: 'M4', value: 'M4', text: 'KOREA, DEMOCRATIC PEOPLE\'S REPUBLIC OF' },
  { key: 'M5', value: 'M5', text: 'KOREA, REPUBLIC OF' },
  { key: 'M6', value: 'M6', text: 'KUWAIT' },
  { key: '1N', value: '1N', text: 'KYRGYZSTAN' },
  { key: 'M7', value: 'M7', text: 'LAO PEOPLE\'S DEMOCRATIC REPUBLIC' },
  { key: '1R', value: '1R', text: 'LATVIA' },
  { key: 'M8', value: 'M8', text: 'LEBANON' },
  { key: 'M9', value: 'M9', text: 'LESOTHO' },
  { key: 'N0', value: 'N0', text: 'LIBERIA' },
  { key: 'N1', value: 'N1', text: 'LIBYAN ARAB JAMAHIRIYA' },
  { key: 'N2', value: 'N2', text: 'LIECHTENSTEIN' },
  { key: '1Q', value: '1Q', text: 'LITHUANIA' },
  { key: 'N4', value: 'N4', text: 'LUXEMBOURG' },
  { key: 'N5', value: 'N5', text: 'MACAU' },
  { key: '1U', value: '1U', text: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF' },
  { key: 'N6', value: 'N6', text: 'MADAGASCAR' },
  { key: 'N7', value: 'N7', text: 'MALAWI' },
  { key: 'N8', value: 'N8', text: 'MALAYSIA' },
  { key: 'N9', value: 'N9', text: 'MALDIVES' },
  { key: 'O0', value: 'O0', text: 'MALI' },
  { key: 'O1', value: 'O1', text: 'MALTA' },
  { key: '1T', value: '1T', text: 'MARSHALL ISLANDS' },
  { key: 'O2', value: 'O2', text: 'MARTINIQUE' },
  { key: 'O3', value: 'O3', text: 'MAURITANIA' },
  { key: 'O4', value: 'O4', text: 'MAURITIUS' },
  { key: '2P', value: '2P', text: 'MAYOTTE' },
  { key: 'O5', value: 'O5', text: 'MEXICO' },
  { key: '1K', value: '1K', text: 'MICRONESIA, FEDERATED STATES OF' },
  { key: '1S', value: '1S', text: 'MOLDOVA, REPUBLIC OF' },
  { key: 'O9', value: 'O9', text: 'MONACO' },
  { key: 'P0', value: 'P0', text: 'MONGOLIA' },
  { key: 'Z5', value: 'Z5', text: 'MONTENEGRO' },
  { key: 'P1', value: 'P1', text: 'MONTSERRAT' },
  { key: 'P2', value: 'P2', text: 'MOROCCO' },
  { key: 'P3', value: 'P3', text: 'MOZAMBIQUE' },
  { key: 'E1', value: 'E1', text: 'MYANMAR' },
  { key: 'T6', value: 'T6', text: 'NAMIBIA' },
  { key: 'P5', value: 'P5', text: 'NAURU' },
  { key: 'P6', value: 'P6', text: 'NEPAL' },
  { key: 'P7', value: 'P7', text: 'NETHERLANDS' },
  { key: 'P8', value: 'P8', text: 'NETHERLANDS ANTILLES' },
  { key: '1W', value: '1W', text: 'NEW CALEDONIA' },
  { key: 'Q2', value: 'Q2', text: 'NEW ZEALAND' },
  { key: 'Q3', value: 'Q3', text: 'NICARAGUA' },
  { key: 'Q4', value: 'Q4', text: 'NIGER' },
  { key: 'Q5', value: 'Q5', text: 'NIGERIA' },
  { key: 'Q6', value: 'Q6', text: 'NIUE' },
  { key: 'Q7', value: 'Q7', text: 'NORFOLK ISLAND' },
  { key: '1V', value: '1V', text: 'NORTHERN MARIANA ISLANDS' },
  { key: 'Q8', value: 'Q8', text: 'NORWAY' },
  { key: 'P4', value: 'P4', text: 'OMAN' },
  { key: 'R0', value: 'R0', text: 'PAKISTAN' },
  { key: '1Y', value: '1Y', text: 'PALAU' },
  { key: '1X', value: '1X', text: 'PALESTINIAN TERRITORY, OCCUPIED' },
  { key: 'R1', value: 'R1', text: 'PANAMA' },
  { key: 'R2', value: 'R2', text: 'PAPUA NEW GUINEA' },
  { key: 'R4', value: 'R4', text: 'PARAGUAY' },
  { key: 'R5', value: 'R5', text: 'PERU' },
  { key: 'R6', value: 'R6', text: 'PHILIPPINES' },
  { key: 'R8', value: 'R8', text: 'PITCAIRN' },
  { key: 'R9', value: 'R9', text: 'POLAND' },
  { key: 'S1', value: 'S1', text: 'PORTUGAL' },
  { key: 'PR', value: 'PR', text: 'PUERTO RICO' },
  { key: 'S3', value: 'S3', text: 'QATAR' },
  { key: 'S4', value: 'S4', text: 'REUNION' },
  { key: 'S5', value: 'S5', text: 'ROMANIA' },
  { key: '1Z', value: '1Z', text: 'RUSSIAN FEDERATION' },
  { key: 'S6', value: 'S6', text: 'RWANDA' },
  { key: 'Z0', value: 'Z0', text: 'SAINT BARTHELEMY' },
  { key: 'U8', value: 'U8', text: 'SAINT HELENA' },
  { key: 'U7', value: 'U7', text: 'SAINT KITTS AND NEVIS' },
  { key: 'U9', value: 'U9', text: 'SAINT LUCIA' },
  { key: 'Z1', value: 'Z1', text: 'SAINT MARTIN' },
  { key: 'V0', value: 'V0', text: 'SAINT PIERRE AND MIQUELON' },
  { key: 'V1', value: 'V1', text: 'SAINT VINCENT AND THE GRENADINES' },
  { key: 'Y0', value: 'Y0', text: 'SAMOA' },
  { key: 'S8', value: 'S8', text: 'SAN MARINO' },
  { key: 'S9', value: 'S9', text: 'SAO TOME AND PRINCIPE' },
  { key: 'T0', value: 'T0', text: 'SAUDI ARABIA' },
  { key: 'T1', value: 'T1', text: 'SENEGAL' },
  { key: 'Z2', value: 'Z2', text: 'SERBIA' },
  { key: 'T2', value: 'T2', text: 'SEYCHELLES' },
  { key: 'T8', value: 'T8', text: 'SIERRA LEONE' },
  { key: 'U0', value: 'U0', text: 'SINGAPORE' },
  { key: '2B', value: '2B', text: 'SLOVAKIA' },
  { key: '2A', value: '2A', text: 'SLOVENIA' },
  { key: 'D7', value: 'D7', text: 'SOLOMON ISLANDS' },
  { key: 'U1', value: 'U1', text: 'SOMALIA' },
  { key: 'T3', value: 'T3', text: 'SOUTH AFRICA' },
  { key: '1L', value: '1L', text: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS' },
  { key: 'U3', value: 'U3', text: 'SPAIN' },
  { key: 'F1', value: 'F1', text: 'SRI LANKA' },
  { key: 'V2', value: 'V2', text: 'SUDAN' },
  { key: 'V3', value: 'V3', text: 'SURINAME' },
  { key: 'L9', value: 'L9', text: 'SVALBARD AND JAN MAYEN' },
  { key: 'V6', value: 'V6', text: 'SWAZILAND' },
  { key: 'V7', value: 'V7', text: 'SWEDEN' },
  { key: 'V8', value: 'V8', text: 'SWITZERLAND' },
  { key: 'V9', value: 'V9', text: 'SYRIAN ARAB REPUBLIC' },
  { key: 'F5', value: 'F5', text: 'TAIWAN, PROVINCE OF CHINA' },
  { key: '2D', value: '2D', text: 'TAJIKISTAN' },
  { key: 'W0', value: 'W0', text: 'TANZANIA, UNITED REPUBLIC OF' },
  { key: 'W1', value: 'W1', text: 'THAILAND' },
  { key: 'Z3', value: 'Z3', text: 'TIMOR-LESTE' },
  { key: 'W2', value: 'W2', text: 'TOGO' },
  { key: 'W3', value: 'W3', text: 'TOKELAU' },
  { key: 'W4', value: 'W4', text: 'TONGA' },
  { key: 'W5', value: 'W5', text: 'TRINIDAD AND TOBAGO' },
  { key: 'W6', value: 'W6', text: 'TUNISIA' },
  { key: 'W8', value: 'W8', text: 'TURKEY' },
  { key: '2E', value: '2E', text: 'TURKMENISTAN' },
  { key: 'W7', value: 'W7', text: 'TURKS AND CAICOS ISLANDS' },
  { key: '2G', value: '2G', text: 'TUVALU' },
  { key: 'W9', value: 'W9', text: 'UGANDA' },
  { key: '2H', value: '2H', text: 'UKRAINE' },
  { key: 'C0', value: 'C0', text: 'UNITED ARAB EMIRATES' },
  { key: 'X0', value: 'X0', text: 'UNITED KINGDOM' },
  { key: '2J', value: '2J', text: 'UNITED STATES MINOR OUTLYING ISLANDS' },
  { key: 'X3', value: 'X3', text: 'URUGUAY' },
  { key: '2K', value: '2K', text: 'UZBEKISTAN' },
  { key: '2L', value: '2L', text: 'VANUATU' },
  { key: 'X5', value: 'X5', text: 'VENEZUELA' },
  { key: 'Q1', value: 'Q1', text: 'VIET NAM' },
  { key: 'D8', value: 'D8', text: 'VIRGIN ISLANDS, BRITISH' },
  { key: 'VI', value: 'VI', text: 'VIRGIN ISLANDS, U.S.' },
  { key: 'X8', value: 'X8', text: 'WALLIS AND FUTUNA' },
  { key: 'U5', value: 'U5', text: 'WESTERN SAHARA' },
  { key: 'T7', value: 'T7', text: 'YEMEN' },
  { key: 'Y4', value: 'Y4', text: 'ZAMBIA' },
  { key: 'Y5', value: 'Y5', text: 'ZIMBABWE' },
  { key: 'XX', value: 'XX', text: 'UNKNOWN' },
];

export const NEW_OFFERING_INFORMATION = {
  businessName: {
    value: '',
    error: undefined,
    rule: 'required',
    key: 'businessName',
    label: 'Business Name',
  },
  businessDescription: {
    value: '',
    error: undefined,
    rule: 'required',
    key: 'businessDescription',
    label: 'Description',
  },
};

export const XML_STATUSES = {
  draft: 'DRAFT',
  created: 'CREATED',
  completed: 'COMPLETED',
  errored: null,
};

export const EDGAR_URL = '/edgar/publish';

export const XML_URL = '/edgar/primary-data';

export const GRAPHQL = '/graphql';

export const XML_SUBMISSION_TABS = [{
  name: 'filer',
  label: 'Filer Information',
  errorClass: '',
}, {
  name: 'issuer',
  label: 'Issuer Information',
  errorClass: '',
}, {
  name: 'offering',
  label: 'Offering Information',
  errorClass: '',
}, {
  name: 'annual',
  label: 'Annual Report Disclosure Requirements',
  errorClass: '',
}, {
  name: 'signature',
  label: 'Signature',
  errorClass: '',
}, {
  name: 'doc',
  label: 'Documents',
  errorClass: '',
}];

export const LENDING_PARTNER_LENDIO = {
  yrsInBusiness: [
    {
      key: 'NOT_IN_BUSINESS',
      value: 'NOT_IN_BUSINESS',
      text: 'Not yet in business, still in planning stages',
    },
    {
      key: 'LOOKING_BUY_BUSINESS',
      value: 'LOOKING_BUY_BUSINESS',
      text: 'Looking to buy an existing business',
    },
    {
      key: 'DURATION_0_2_MONTHS',
      value: 'DURATION_0_2_MONTHS',
      text: '0-2 months',
    },
    {
      key: 'DURATION_3_5_MONTHS',
      value: 'DURATION_3_5_MONTHS',
      text: '3-5 months',
    },
    {
      key: 'DURATION_6_11_MONTHS',
      value: 'DURATION_6_11_MONTHS',
      text: '6-11 months',
    },
    {
      key: 'DURATION_1_2_YEARS',
      value: 'DURATION_1_2_YEARS',
      text: '1-2 years',
    },
    {
      key: 'DURATION_2_3_YEARS',
      value: 'DURATION_2_3_YEARS',
      text: '2-3 years',
    },
    {
      key: 'DURATION_3_5_YEARS',
      value: 'DURATION_3_5_YEARS',
      text: '3-5 years',
    },
    {
      key: 'DURATION_5_OR_MORE_YEARS',
      value: 'DURATION_5_OR_MORE_YEARS',
      text: '5 or more years',
    },
  ],
  avgSales: [
    {
      key: 'NO_REVENUES',
      value: 'NO_REVENUES',
      text: '$0, No revenues',
    },
    {
      key: 'AVERAGE_MONTHLY_SALES_1_4000',
      value: 'AVERAGE_MONTHLY_SALES_1_4000',
      text: '$1 - $4,000',
    },
    {
      key: 'AVERAGE_MONTHLY_SALES_4001_8000',
      value: 'AVERAGE_MONTHLY_SALES_4001_8000',
      text: '$4,001 - $8,000',
    },
    {
      key: 'AVERAGE_MONTHLY_SALES_8001_15000',
      value: 'AVERAGE_MONTHLY_SALES_8001_15000',
      text: '$8,001 - $15,000',
    },
    {
      key: 'AVERAGE_MONTHLY_SALES_15001_20000',
      value: 'AVERAGE_MONTHLY_SALES_15001_20000',
      text: '$15,001 - $20,000',
    },
    {
      key: 'AVERAGE_MONTHLY_SALES_20001_40000',
      value: 'AVERAGE_MONTHLY_SALES_20001_40000',
      text: '$20,001 - $40,000',
    },
    {
      key: 'AVERAGE_MONTHLY_SALES_40001_80000',
      value: 'AVERAGE_MONTHLY_SALES_40001_80000',
      text: '$40,001 - $80,000',
    },
    {
      key: 'AVERAGE_MONTHLY_SALES_80001_200000',
      value: 'AVERAGE_MONTHLY_SALES_80001_200000',
      text: '$80,001 - $200,000',
    },
    {
      key: 'AVERAGE_MONTHLY_SALES_200001_400000',
      value: 'AVERAGE_MONTHLY_SALES_200001_400000',
      text: '$200,001 - $400,000',
    },
    {
      key: 'GREATER_THAN_400000',
      value: 'GREATER_THAN_400000',
      text: 'More than $400,000',
    },
  ],
  personalCreditRating: [
    {
      key: 'POOR',
      value: 'POOR',
      text: '(499 or Below) Poor',
    },
    {
      key: 'NOT_SO_GOOD',
      value: 'NOT_SO_GOOD',
      text: '(500 - 599) Not so good',
    },
    {
      key: 'OKAY',
      value: 'OKAY',
      text: '(600 - 649) Okay',
    },
    {
      key: 'GOOD',
      value: 'GOOD',
      text: '(650 - 679) Good',
    },
    {
      key: 'GREAT',
      value: 'GREAT',
      text: '(680 - 719) Great',
    },
    {
      key: 'EXCELLENT',
      value: 'EXCELLENT',
      text: '(720 or Higher) Excellent',
    },
  ],
  industry: [
    {
      key: 'ISTRATIVE_SUPPORT_SERVICES',
      value: 'ISTRATIVE_SUPPORT_SERVICES',
      text: 'Administrative and support services',
    },
    {
      key: 'AGRICULTURE',
      value: 'AGRICULTURE',
      text: 'Agriculture',
    },
    {
      key: 'ARTS_ENTERTAINMENT_RECREATION',
      value: 'ARTS_ENTERTAINMENT_RECREATION',
      text: 'Arts, entertainment, and recreation',
    },
    {
      key: 'AUTOMOBILE_DEALERS',
      value: 'AUTOMOBILE_DEALERS',
      text: 'Automobile dealers',
    },
    {
      key: 'AUTOMOTIVE_REPAIR_MAINTENANCE',
      value: 'AUTOMOTIVE_REPAIR_MAINTENANCE',
      text: 'Automotive repair and maintenance',
    },
    {
      key: 'BUSINESS_SERVICES',
      value: 'BUSINESS_SERVICES',
      text: 'Business services',
    },
    {
      key: 'CONSTRUCTION',
      value: 'CONSTRUCTION',
      text: 'Construction',
    },
    {
      key: 'EDUCATIONAL_SERVICES',
      value: 'EDUCATIONAL_SERVICES',
      text: 'Educational services',
    },
    {
      key: 'FINANCE_INSURANCE',
      value: 'FINANCE_INSURANCE',
      text: 'Finance and insurance',
    },
    {
      key: 'FORESTRY_FISHING_HUNTING',
      value: 'FORESTRY_FISHING_HUNTING',
      text: 'Forestry, fishing, and hunting',
    },
    {
      key: 'FREIGHT_TRUCKING',
      value: 'FREIGHT_TRUCKING',
      text: 'Freight trucking',
    },
    {
      key: 'GAMBLING_INDUSTRIES',
      value: 'GAMBLING_INDUSTRIES',
      text: 'Gambling industries',
    },
    {
      key: 'GAS_STATIONS',
      value: 'GAS_STATIONS',
      text: 'Gas stations',
    },
    {
      key: 'GREENHOUSE_NURSERY_FLORICULTURE',
      value: 'GREENHOUSE_NURSERY_FLORICULTURE',
      text: 'Greenhouse, nursery, and floriculture',
    },
    {
      key: 'HEALTHCARE_SOCIAL_ASSISTANCE',
      value: 'HEALTHCARE_SOCIAL_ASSISTANCE',
      text: 'Healthcare and social assistance',
    },
    {
      key: 'HOTELS_TRAVEL_ACCOMODATIONS',
      value: 'HOTELS_TRAVEL_ACCOMODATIONS',
      text: 'Hotels and travel accomodations',
    },
    {
      key: 'INFORMATION_TECHNOLOGY',
      value: 'INFORMATION_TECHNOLOGY',
      text: 'Information and technology',
    },
    {
      key: 'LEGAL_SERVICES',
      value: 'LEGAL_SERVICES',
      text: 'Legal services',
    },
    {
      key: 'MANAGEMENT_COMPANIES',
      value: 'MANAGEMENT_COMPANIES',
      text: 'Management of companies',
    },
    {
      key: 'MANUFACTURING',
      value: 'MANUFACTURING',
      text: 'Manufacturing',
    },
    {
      key: 'MINING1068',
      value: 'MINING1068',
      text: 'Mining',
    },
    {
      key: 'NON_PROFIT_ORGANIZATIONS',
      value: 'NON_PROFIT_ORGANIZATIONS',
      text: 'Non-Profit organizations',
    },
    {
      key: 'OTHER_SERVICES',
      value: 'OTHER_SERVICES',
      text: 'Other services',
    },
    {
      key: 'PERSONAL_CARE_SERVICES',
      value: 'PERSONAL_CARE_SERVICES',
      text: 'Personal care services',
    },
    {
      key: 'PHYSICIAN_DENTIST_HEALTH_PRACTITIONER',
      value: 'PHYSICIAN_DENTIST_HEALTH_PRACTITIONER',
      text: 'Physician, dentist, or health practitioner',
    },
    {
      key: 'PUBLIC_ADMINISTRATION',
      value: 'PUBLIC_ADMINISTRATION',
      text: 'Public administration',
    },
    {
      key: 'REAL_ESTATE',
      value: 'REAL_ESTATE',
      text: 'Real estate',
    },
    {
      key: 'RELIGIOUS_INSTITUTIONS',
      value: 'RELIGIOUS_INSTITUTIONS',
      text: 'Religious institutions',
    },
    {
      key: 'RENTAL_LEASING',
      value: 'RENTAL_LEASING',
      text: 'Rental and leasing',
    },
    {
      key: 'RESTAURANTS_FOOD_SERVICES',
      value: 'RESTAURANTS_FOOD_SERVICES',
      text: 'Restaurants and food services',
    },
    {
      key: 'RETAIL_STORES',
      value: 'RETAIL_STORES',
      text: 'Retail stores',
    },
    {
      key: 'TRANSPORTATION',
      value: 'TRANSPORTATION',
      text: 'Transportation',
    },
    {
      key: 'UTILITIES2',
      value: 'UTILITIES2',
      text: 'Utilities',
    },
    {
      key: 'VETERINARIANS',
      value: 'VETERINARIANS',
      text: 'Veterinarians',
    },
    {
      key: 'WAREHOUSING_STORAGE',
      value: 'WAREHOUSING_STORAGE',
      text: 'Warehousing and storage',
    },
    {
      key: 'WASTE_MANAGEMENT_REMEDIATION_SERVICES',
      value: 'WASTE_MANAGEMENT_REMEDIATION_SERVICES',
      text: 'Waste management & remediation services',
    },
    {
      key: 'WHOLESALE_TRADE',
      value: 'WHOLESALE_TRADE',
      text: 'Wholesale trade',
    },
    {
      key: 'PROFESSIONAL_SCIENTIFIC_TECHNICAL_SERVICES',
      value: 'PROFESSIONAL_SCIENTIFIC_TECHNICAL_SERVICES',
      text: 'Professional, scientific, and technical services',
    },
  ],
  raiseAmount: [
    {
      key: 'AMT_1_5000',
      value: 'AMT_1_5000',
      text: '$1 - $5,000',
    },
    {
      key: 'AMT_5001_25000',
      value: 'AMT_5001_25000',
      text: '$5,001 - $25,000',
    },
    {
      key: 'AMT_25001_50000',
      value: 'AMT_25001_50000',
      text: '$25,001 - $50,000',
    },
    {
      key: 'AMT_50001_100000',
      value: 'AMT_50001_100000',
      text: '$50,001 - $100,000',
    },
    {
      key: 'AMT_100001_250000',
      value: 'AMT_100001_250000',
      text: '$100,001 - $250,000',
    },
    {
      key: 'AMT_250001_500000',
      value: 'AMT_250001_500000',
      text: '$250,001 - $500,000',
    },
    {
      key: 'AMT_500001_1M',
      value: 'AMT_500001_1M',
      text: '$500,001 - $1M',
    },
    {
      key: 'GREATER_THAN_1M',
      value: 'GREATER_THAN_1M',
      text: 'Over $1M',
    },
  ],
};

export const TARGETED_INVESTOR_IRR = {
  OPPORTUNISTIC: 15, VALUE_ADD: 11, CORE_PLUS: 7, CORE: 5,
};
