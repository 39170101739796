import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Container, Grid, Header, Button, Divider } from 'semantic-ui-react';
import NSImage from '../../../shared/NSImage';

@inject('publicStore')
@withRouter
@observer
class RepublicModule extends Component {
  componentDidMount() {
    const { publicStore, staticMeta, isMobile, location } = this.props;
    const { desktopImages, mobileImages } = staticMeta;
    const { getRepublicImagesArr } = publicStore;
    const isAboutPage = location.pathname.includes('/about');
    getRepublicImagesArr(desktopImages, mobileImages, isAboutPage, isMobile);
  }

  handleOfferingsOnClick = (toLink) => {
    this.props.history.push(toLink);
    window.scrollTo(0, 0);
  }

  render() {
    const { staticMeta, location, publicStore, isMobile } = this.props;
    const { key, title, description, buttonText, toLink, externalButton } = staticMeta;
    const isAboutPage = location.pathname.includes('/about');
    const { republicImagesArr, republicImagesArr1, republicImagesArr2 } = publicStore;
    return (
      <section key={key} className={`${isMobile ? 'pt-50 pb-50 pl-15 pr-15' : 'pt-70 pb-70'} ${!isAboutPage ? 'bg-offwhite' : ''}`}>
        <Container>
          <Grid textAlign={!isMobile ? 'center' : ''}>
            <Grid.Row className="pt-0">
              <Header as="h2" className="pt-10 pr-15">{title}</Header>
              {!isAboutPage && <NSImage className={!isMobile ? 'pb-10' : 'pt-10 pb-15'} path={`republic/${!isMobile ? 'republic.png' : 'republicMobileLong.png'}`} />}
            </Grid.Row>
            <Grid.Row>
              <p className="mb-10">{description}</p>
            </Grid.Row>
            {isAboutPage && !isMobile
            ? (
              <>
                <Grid.Row columns={2}>
                  {republicImagesArr1.map(imagePath => (
                    <Grid.Column width={6} className="pb-10 pt-10 pr-30 pl-30">
                      <NSImage className="pr-90 pl-40 republic-list" path={imagePath} />
                    </Grid.Column>
                  ))}
                </Grid.Row>
                <Divider hidden />
                <Grid.Row columns={3}>
                  {republicImagesArr2.map(imagePath => (
                    <Grid.Column width={5} className="pb-10 pt-10 plr-0">
                      <NSImage className="pr-40 pl-40 republic-list" path={imagePath} />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </>
            ) : (
              <Grid.Row columns={isMobile ? 1 : 4}>
                {republicImagesArr.map(imagePath => (
                  <Grid.Column className={!isMobile ? 'pb-10 pt-10 plr-0' : 'center-align'}>
                    <NSImage className={`${!isMobile ? 'pr-25 pl-25' : 'pb-20 pt-20'} republic-list`} path={imagePath} />
                  </Grid.Column>
                ))}
              </Grid.Row>
            )}
            <Grid.Row>
              {!externalButton
              ? <Button onClick={() => this.handleOfferingsOnClick(toLink)} fluid={isMobile} inverted color="green" className={`${!isMobile ? 'mt-40' : ''}`} primary>{buttonText}</Button>
              : <a style={isMobile ? { width: '100%' } : {}} href={toLink} rel="noopener noreferrer" target="_blank"><Button fluid={isMobile} className={`${!isMobile ? 'mt-40 bg-republic' : 'bg-republic'}`} primary>{buttonText}</Button></a>
              }
            </Grid.Row>
          </Grid>
        </Container>
      </section>
    );
  }
}

export default RepublicModule;
