import React from 'react';
import Helmet from 'react-helmet';
import { Banner, ReferralSignup, BusinessTypes, WhyNextseed, TrackRecord, FAQ } from './index';
import { BUSINESS_REFERRAL_HELMET_META } from '../../../../services/constants/businessReferral';

const referralSections = [
  {
    key: 'referralBanner',
    component: <Banner />,
  },
  {
    key: 'referralSignup',
    component: <ReferralSignup />,
  },
  {
    key: 'businessTypes',
    component: <BusinessTypes />,
  },
  {
    key: 'whyNextseed',
    component: <WhyNextseed />,
  },
  {
    key: 'trackRecord',
    component: <TrackRecord />,
  },
  {
    key: 'faq',
    component: <FAQ />,
  },
];

const BusinessReferral = () => (
  <>
    <Helmet>
      {BUSINESS_REFERRAL_HELMET_META.map(field => (
        <meta
          name={field.name}
          content={field.content}
        />
      ))}
    </Helmet>
    {referralSections.map(section => (
      <React.Fragment key={section.key}>
        {section.component}
      </React.Fragment>
    ))}
  </>
);

export default BusinessReferral;
