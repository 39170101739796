import React, { Component } from 'react';
import { Header, Container, Grid, Responsive } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { InlineLoader } from '../../../../theme/shared';
import TeamList from './TeamList';

@inject('teamStore', 'uiStore', 'publicStore', 'campaignStore')
@observer
class Team extends Component {
  constructor(props) {
    super(props);
    props.teamStore.initRequest();
  }

  render() {
    const { teamMembers, loading } = this.props.teamStore;
    const { responsiveVars } = this.props.uiStore;
    const teamInfo = (
      <Grid centered className="team-list">
        <TeamList
          columns={4}
          className="team-gallery"
          match={this.props.match}
          joinColumn={responsiveVars.isMobile}
        />
      </Grid>
    );
    return (
      <>
        <section className={responsiveVars.uptoTablet ? 'pt-50 pb-50' : 'pt-100 pb-100'}>
          <Container>
            <Grid centered>
              <Grid.Column textAlign={responsiveVars.isMobile ? 'left' : 'center'} computer={15} tablet={14} mobile={16}>
                <Header as="h2" className={responsiveVars.isMobile ? 'mb-10' : 'mb-30'}>Meet our team</Header>
                <p className={`${responsiveVars.isMobile ? 'mb-40' : 'mb-60'} neutral-text`}>
                  We{'\''}re a team of entrepreneurs with backgrounds in business, finance, law, marketing and technology.<Responsive minWidth={992} as="br" /> We{'\''}re here to empower business owners and everyday people to invest in one another.
                </p>
                {loading ? (<InlineLoader />)
                  : teamMembers.length === 0 ? <section className="center-align"><h3 style={{ color: '#31333d' }}>No Records to Display</h3></section>
                    : teamInfo}
              </Grid.Column>
            </Grid>
          </Container>
        </section>
        {/* <section className={responsiveVars.uptoTablet ? 'pt-50 pb-50' : 'pt-100 pb-100'}>
          <Container>
            <Grid centered reversed="mobile">
              <Grid.Column width={responsiveVars.uptoTablet ? 16 : 7} floated="left">
                {loading ? (<InlineLoader />)
                  : jobsList.lenght > 0
                    ? (
                      <div id="job-position">
                        <Header as="h3" className={responsiveVars.isMobile ? 'mt-40' : 'mt-50'}>Current Positions</Header>
                        <List divided relaxed="very" className="job-list">
                          {jobsList.map(i => (
                            <List.Item>
                              <List.Content>
                                <List.Header style={{ cursor: 'pointer' }} onClick={() => this.openDoc(i.BOX_FILE_ID)} className="highlight-text">{i.POSITION}</List.Header>
                                <List.Description className="neutral-text">{`${i.CITY} ${i.STATE}`}</List.Description>
                              </List.Content>
                            </List.Item>
                          ))
                          }
                        </List>
                      </div>
                    )
                    : <></>
                  }
              </Grid.Column>
            </Grid>
          </Container>
        </section>
        <IframeModal
          open={this.state.open}
          close={this.closeModal}
          srcUrl={this.state.embedUrl}
          loading={this.state.loading}
        /> */}
      </>
    );
  }
}
export default Team;
