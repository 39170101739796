import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Header, Container, Button, Dimmer, Loader, Grid } from 'semantic-ui-react';
// import NSImage from '../../../shared/NSImage';

@inject('uiStore', 'publicStore')
@withRouter
@observer
class Banner extends Component {
  render() {
    const { showButton, redirectUrl } = this.props.publicStore;
    const { responsiveVars } = this.props.uiStore;
    // const { uptoTablet } = responsiveVars;
    return (
      <section className="banner business-banner">
        <Container className={responsiveVars.isMobile ? 'mobile-banner' : ''}>
          <Grid>
            <Grid.Column widescreen={8} computer={8} tablet={16} mobile={16} stackable>
              <div className="banner-caption">
                <Header className="home-header custom-h1-header as-h2" as="h1">
                  Meaningful investments in<br />Main Street businesses
                </Header>
                <p>Browse vetted investment offerings <br />in communities all over the US.</p>
                {showButton
                  ? (
                    <Button
                      className={`${responsiveVars.isMobile && 'mt-10'} mt-20 white-button relaxed`}
                      content="Get Started"
                      as={Link}
                      to={redirectUrl}
                      fluid={responsiveVars.isMobile}
                    />
                  ) : ''
                }
              </div>
            </Grid.Column>
          </Grid>
        </Container>
        {this.props.withDimmer && (
          <Dimmer active className="fullscreen">
            <Loader active>Loading..</Loader>
          </Dimmer>
        )}
      </section>
    );
  }
}

export default Banner;
