import React from 'react';
import { Modal } from 'semantic-ui-react';
import { InlineLoader } from '../index';

class IframeModal extends React.Component {
  render() {
    const {
      srcUrl, open, close, loading, trigger, isPdf, text, closeOnDimmerClick,
    } = this.props;
    const closeDimmerClickAction = closeOnDimmerClick || false;
    return (
      <Modal open={open} size="large" closeIcon trigger={trigger} onClose={close} closeOnDimmerClick={closeDimmerClickAction}>
        <Modal.Content>
          <div className="pdf-viewer">
            {(loading || !srcUrl) ? <InlineLoader text={text} />
              : isPdf ? <embed width="100%" height="100%" title="agreement" src={srcUrl} />
                : <iframe width="100%" height="100%" title="agreement" src={srcUrl} />
            }
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default IframeModal;
