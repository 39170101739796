/**
 * @desc Pass fields in values if fields in keys needs validation
 */
export const CONDITIONAL_REQUIRE = {
  verify: 'password',
  legalStatusOtherDesc: 'legalStatusForm',
  securityOfferedOtherDesc: 'securityOfferedType',
  noOfSecurityOffered: 'securityOfferedType',
  overSubscriptionAllocationType: 'overSubscriptionAccepted',
  descOverSubscription: 'overSubscriptionAllocationType',
  maximumOfferingAmount: 'overSubscriptionAccepted',
};
