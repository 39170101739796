import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Header, Container, Grid } from 'semantic-ui-react';
import { FAQ_CONST } from '../../../../services/constants/businessReferral';

@inject('uiStore')
@withRouter
@observer
class FAQ extends Component {
  render() {
    const { responsiveVars } = this.props.uiStore;
    const { isMobile } = responsiveVars;
    return (
      <section className="bg-offwhite referral-section pb-10">
        <Container className={isMobile ? 'mb-20' : ''}>
          <Grid container relaxed="very" className="mb-30">
            <Grid.Column>
            <Header as="h2" textAlign={isMobile ? 'left' : 'center'}>{FAQ_CONST.header}</Header>
            {FAQ_CONST.list.map(list => (
              <div className="mb-50">
                <Header as="h3">{list.subHeader}</Header>
                <p>{list.body}</p>
              </div>
            ))}
            <p className="note">{FAQ_CONST.note}</p>
            </Grid.Column>
          </Grid>
        </Container>
      </section>
    );
  }
}

export default FAQ;
