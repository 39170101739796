import React from 'react';
import { inject, observer } from 'mobx-react';
import NSImage from '../../../modules/shared/NSImage';

const imgMap = {
  LogoColor: 'logo-color.svg',
  LogoWhite: 'logo-white.svg',
  LogoWhiteGreen: 'logo-white-green.svg',
  LogoSmall: 'logo-icon.svg',
  LogoSmallWhite: 'logo-icon-white.svg',
  LogoLendio: 'lendio_logo.svg',
  LogoNsAndLendio: 'nextseed_and_lendio.svg',
  LogoNsAndLendioWhite: 'nextseed_and_lendio_white.svg',
  LogoBlack: 'logo_black.svg',
  LogoGreenGrey: 'logo.svg',
  LogoNss: 'nss-logo.svg',
  RepublicBlue: 'republic-logo-blue.svg',
  RepublicIconWhite: 'republic-Icon-white.svg',
  RepublicHand: 'republic-hand.svg',
  RepublicFists: 'republic-fists.svg',
  RepublicFistsOnly: 'republic-fists-only.svg',
};

@inject('uiStore')
@observer
export default class Logo extends React.Component {
  showLoader = (refImg) => {
    if (!['RepublicBlue', 'RepublicIconWhite', 'RepublicHand', 'RepublicFists', 'LogoLendio', 'LogoNsAndLendio', 'LogoNsAndLendioWhite'].includes(refImg)) {
      this.props.uiStore.setAppLoader(true);
      setTimeout(() => { this.props.uiStore.setAppLoader(false); }, 80);
    }
  };

  render() {
    return (
      <NSImage
        onClick={() => this.showLoader(this.props.dataSrc)}
        {...this.props}
        path={imgMap[this.props.dataSrc] || 'logo-color.svg'}
        className={`${this.props.restOfClasses ? this.props.restOfClasses : ''} logo`}
      />
    );
  }
}
