import {
    ACCREDITED_INVESTOR_STATUS_REQUESTED_EVENT_NAME,
    BASIC_ACCOUNT_CREATED_EVENT_NAME,
    BASIC_ACCOUNT_VALUE,
    BROKERAGE_ACCOUNT_CREATED_EVENT_NAME,
    BROKERAGE_ACCOUNT_VALUE,
    INVESTMENT_ACCOUNT_CREATED_EVENT_NAME,
    INVESTMENT_ACCOUNT_VALUE,
    LOGGED_IN_EVENT_NAME,
    LOGGED_OUT_EVENT_NAME,
    ACCREDITED_INVESTOR_STATUS_APPROVED_EVENT_NAME,
    FOLLOW_BUTTON_CLICKED_EVENT_NAME,
    INVEST_BUTTON_CLICKED_EVENT_NAME, SUBSCRIBED_TO_NEWSLETTER_BUTTON_CLICKED_EVENT_NAME, AGREEMENT_SIGNED_EVENT_NAME, GA_ECOM_ORDER_COMPLETED, GA_CURRENCY_CODE_USD, OFFERING_TILE_CLICK_EVENT_NAME,
} from '../../constants/analytics';

class Analytics {
    // Most function are wrapped with empty error catches to ensure no interruption to UI if there is some weird error

    // Check function to see if needed properties should be computed.  Can be expanded later for per user control, etc
    trackingEnabled = () => !!window.analytics

    // Identity a user
    identify = (userId, traits, userHash) => {
        try {
            if (window.analytics) {
                window.analytics.identify(userId, traits, {
                    integrations: {
                        Intercom: {
                            user_hash: userHash,
                        },
                    },
                });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Alias a user
    alias = (userId) => {
        try {
            if (window.analytics) {
                window.analytics.alias(userId);
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Resets Segment tracking
    reset = () => {
        try {
            if (window.analytics) {
                window.analytics.reset();
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Record page view
    // TODO: add in `Product Viewed` event based on slug - https://segment.com/docs/connections/spec/ecommerce/v2/#product-viewed
    pageView = (oldLocation, newLocation) => {
        try {
            if (window.analytics) {
                window.analytics.page(document.title, {
                    path: newLocation.pathname,
                    referrer: `https://${window.location.hostname}${oldLocation.pathname}`,
                });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track signup for newsletter
    trackSubscribedToNewsletter = (email) => {
        try {
            if (window.analytics) {
                window.analytics.track(SUBSCRIBED_TO_NEWSLETTER_BUTTON_CLICKED_EVENT_NAME, { Email: email });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track Offering Tile Clicked
    // TODO: add in `Product Clicked` event for ecom for offering tiles - https://segment.com/docs/connections/spec/ecommerce/v2/#product-clicked
    trackOfferingTileClick = (offeringGuid, offeringName) => {
        try {
            if (window.analytics) {
                window.analytics.track(OFFERING_TILE_CLICK_EVENT_NAME, { OfferingName: offeringName, OfferingGUID: offeringGuid });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track login event
    trackLogin = () => {
        try {
            if (window.analytics) {
                window.analytics.track(LOGGED_IN_EVENT_NAME);
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track logout event
    trackLogout = (logoutType) => {
        try {
            if (window.analytics) {
                window.analytics.track(LOGGED_OUT_EVENT_NAME, { Type: logoutType });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track a basic signup
    trackBasicSignup = (accountType) => {
        try {
            if (window.analytics) {
                window.analytics.track(BASIC_ACCOUNT_CREATED_EVENT_NAME, {
                    AccountType: accountType,
                    Value: BASIC_ACCOUNT_VALUE,
                });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track a brokerage account conversion
    trackBrokerageAccountConversion = (birthday, age, city, state, brokerageFirm) => {
        try {
            if (window.analytics) {
                window.analytics.track(BROKERAGE_ACCOUNT_CREATED_EVENT_NAME, { Age: age, City: city, State: state, EmployedByAnotherMemberFirm: brokerageFirm, Value: BROKERAGE_ACCOUNT_VALUE });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track an investment account conversion
    trackInvestmentAccountConversion = (accountType) => {
        try {
            if (window.analytics) {
                window.analytics.track(INVESTMENT_ACCOUNT_CREATED_EVENT_NAME, {
                    Type: String(accountType).toUpperCase(),
                    Value: INVESTMENT_ACCOUNT_VALUE,
                });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track Accreditation Request
    trackAccreditedInvestorRequest = () => {
        try {
            if (window.analytics) {
                window.analytics.track(ACCREDITED_INVESTOR_STATUS_REQUESTED_EVENT_NAME);
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track Accreditation Approval
    trackAccreditedInvestorApproval = (expiration) => {
        try {
            if (window.analytics) {
                window.analytics.track(ACCREDITED_INVESTOR_STATUS_APPROVED_EVENT_NAME, { ExpirationDate: expiration });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track follow button clicked
    trackFollowButtonClick = (followAction, offeringGuid, offeringName) => {
        try {
            if (window.analytics) {
                window.analytics.track(FOLLOW_BUTTON_CLICKED_EVENT_NAME, { Action: followAction, OfferingGUID: offeringGuid, OfferingName: offeringName, content_ids: [offeringGuid], content_name: offeringName });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track Invest Now button click
    // TODO: add in checkout started https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-started
    trackInvestButtonClick = (investAction, offeringGuid, offeringName) => {
        try {
            if (window.analytics) {
                window.analytics.track(INVEST_BUTTON_CLICKED_EVENT_NAME, { OfferingName: offeringName, OfferingGUID: offeringGuid, Action: investAction, content_ids: [offeringGuid] });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }

    // Track Signed Agreement
    trackAgreementSigned = (amount, offeringGuid, offeringName, agreementId) => {
        try {
            if (window.analytics) {
                window.analytics.track(AGREEMENT_SIGNED_EVENT_NAME, { Amount: amount, OfferingGUID: offeringGuid, OfferingName: offeringName, currency: GA_CURRENCY_CODE_USD, value: amount, content_ids: [offeringGuid], content_name: offeringName, num_items: 1 });

                // Also fire GA ecom Order Complete event - https://segment.com/docs/connections/spec/ecommerce/v2/#order-completed
                window.analytics.track(GA_ECOM_ORDER_COMPLETED, {
                    total: amount,
                    currency: GA_CURRENCY_CODE_USD,
                    order_id: String(agreementId),
                    products: [
                        {
                            product_id: offeringGuid,
                            name: offeringName,
                            quantity: 1,
                            price: amount,
                        },
                    ],
                });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
    }
}

export default new Analytics();
