import gql from 'graphql-tag';

export const common = {
  offeringBasics: `
  isAvailablePublicly
  offeringSlug
  template
  keyTerms {
    legalBusinessName
    shorthandBusinessName
    securities
    regulation
    offeringSize
    preferredReturn
    targetInvestmentPeriod
    minOfferingAmountCF
    maxOfferingAmountCF
    minOfferingAmount506
    maxOfferingAmount506
    minOfferingAmount506C
    maxOfferingAmount506C
  }
  leadDetails {
    email {
      address
    }
    info {
      firstName
      lastName
    }
    phone {
      number
    }
  }
  issuerDetails {
    email {
      address
    }
    info {
      firstName
      lastName
    }
    phone {
      number
    }
  }
  offering {
    launch {
      targetDate
    }
  }
  issuerId
  stage
  created {
    date
  }
  updated {
    by
    date
  }
  closureSummary {
    processingDate
    hardCloseDate
    operationsDate
    launchDate
    totalInvestmentAmount
    totalInvestorCount
    repayment {
      currentRepaidAmount
      count
    }
  }
  order`,
  offeringTemplate2: `
  content {
    title
    order
    contentType
    scope
    customValue
  }
  gallery {
    caption
    isVisible
    order
    image {
      id
      url
      isPublic
      fileName
    }
  }
  misc {
    issuerStatement
    logo {
      url
      fileName
    }
    avatar {
      url
      fileName
    }
    social {
      type
      url
      shareLink
      blurb
      featuredImageUpload {
        url
        fileName
      }
    }
  }
  tombstone {
    offeredBy
    showOfferedBy
    image {
      url
      fileName
    }
    description
    meta {
      keyLabel
      order
      keyType
      keyValue
      keyFormat
      isHighlight
    }
    customTag
    toggleMeta
  }
  subHeader {
    meta {
      keyLabel
      order
      keyType
      keyValue
      keyFormat
      isHighlight
    }
    toggleMeta
  }
  header {
    tracker {
      fb
      google
      script
    }
    heroImage {
      url
      fileName
    }
    heroBackgroundImage {
      url
      fileName
    }
    heroVideoURL
    heroVideoIcon
    heroDescription
    meta {
      keyLabel
      order
      keyType
      keyValue
      keyFormat
      isHighlight
    }
    toggleMeta
  }
  `,
};
export const allOfferingsCompact = gql`
  query getOfferings($stage: [OfferingStageEnumType], $issuerId: String){
    getOfferings(filters: { stage: $stage, issuerId: $issuerId }){
      id
      offeringSlug
      closureSummary {
        hardCloseDate
        launchDate
        processingDate
      }
      keyTerms {
        legalBusinessName
        shorthandBusinessName
      }
      offering {
        launch {
          targetDate
          terminationDate
        }
      }
      stage
      created {
        id
        date
      }
      order
      template
    }
  }
`;

export const allOfferings = gql`
  query getOfferings($stage: [OfferingStageEnumType]){
    getOfferings(filters: { stage: $stage }){
      id
      ${common.offeringBasics}
    }
  }
`;

export const adminDeleteOffering = gql`
  mutation adminDeleteOffering($id: String!) {
    adminDeleteOffering(id: $id) {
      id
    }
  }
`;

export const getofferingById = gql`
  query getOfferingById($id: String!) {
    getOfferingById(id: $id) {
      offeringSlug
    }
  }
`;

export const getOfferingClosureProcess = gql`
  query getOfferingDetailsBySlug($id: String!) {
    getOfferingDetailsBySlug(offeringSlug: $id) {
      id
      offeringSlug
      referralCode
      previewPassword
      regulation
      rootFolderId
      closureProcess {
        softCloseNotification {
          finished
          remainingCount
          started
          startedCount
          status
        }
        checkBalance {
          finished
          remainingCount
          started
          startedCount
          status
        }
        issueCredits {
          finished
          remainingCount
          started
          startedCount
          status
        }
        fundEscrow {
          finished
          remainingCount
          started
          startedCount
          status
        }
        verifySecurityTransaction {
          finished
          remainingCount
          started
          startedCount
          status
        }
        processNotes {
          finished
          remainingCount
          started
          startedCount
          status
        }
        validateNotes {
          finished
          remainingCount
          started
          startedCount
          status
        }
        finalizeNotes {
          finished
          remainingCount
          started
          startedCount
          status
        }
        hardCloseNotification {
          finished
          remainingCount
          started
          startedCount
          status
        }
        exportEnvelopes {
          finished
          remainingCount
          started
          startedCount
          status
        }
      }
    }
  }
`;

export const getOfferingStoreDetails = gql`
  query getOfferingDetailsBySlug($id: String!) {
    getOfferingDetailsBySlug(offeringSlug: $id) {
      storageDetails
    }
  }
`;

export const getOfferingDetails = gql`
  query getOfferingDetailsBySlug($id: String!) {
    getOfferingDetailsBySlug(offeringSlug: $id) {
      id
      investmentSummary {
        isInvestedInOffering
        tranche
      }
      lock {
        userId
        user
        date
      }
      offeringSlug
      referralCode
      previewPassword
      regulation
      template
      rootFolderId
      ${common.offeringTemplate2}
      goldstar {
        isin
        contactId
        esAccountNumber
        sfAccountNumber
        esAccountNumberRegD
        isinRegD
        sfAccountNumberRegD
      }
      investNow {
        template
        page {
          note
          title
          page
          regulation
          hideHeader
          toc {
            label
            order
            account
            accreditation
            required
          }
        }
        config {
          investmentType
          showBonusRewards
          showExpectedReturn
          expectedReturnCalc
          confirmationMessage
          hideConfirmationReferral
          hideConfirmationHeader
        }
        docuSign {
          template
          doc {
            name
            mappingRequired
            mapping {
              key
              value
              customValue
            }
            upload {
              fileId
              fileName
            }
          }
        }
      }
      closureProcess {
        checkBalance {
          finished
          remainingCount
          started
          startedCount
          status
        }
        exportEnvelopes {
          finished
          remainingCount
          started
          startedCount
          status
        }
        finalizeNotes {
          finished
          remainingCount
          started
          startedCount
          status
        }
        fundEscrow {
          finished
          remainingCount
          started
          startedCount
          status
        }
        hardCloseNotification {
          finished
          remainingCount
          started
          startedCount
          status
        }
        issueCredits {
          finished
          remainingCount
          started
          startedCount
          status
        }
        processNotes {
          finished
          remainingCount
          started
          startedCount
          status
        }
        softCloseNotification {
          finished
          remainingCount
          started
          startedCount
          status
        }
        validateNotes {
          finished
          remainingCount
          started
          startedCount
          status
        }
        verifySecurityTransaction {
          finished
          remainingCount
          started
          startedCount
          status
        }
      }
      linkedBank {
        accountNumber
        routingNumber
        bankName
        accountHolderName
      }
      leadDetails {
        id
        email {
          address
        }
        info {
          firstName
          lastName
        }
        phone {
          number
        }
      }
      issuerDetails {
        id
        email {
          address
        }
        info {
          firstName
          lastName
        }
       phone {
        number
      }
      }
      keyTerms {
        maxInvestmentAmountCF
        maxInvestmentAmountD
        revShareSummaryUpload {
          id
          url
          fileName
          isPublic
        }
        discount
        valuationCap
        priceCopy
        offeringSize
        preferredReturn
        targetInvestmentPeriod
        totalRoundSize
        equityClass
        classThreshold
        equityUnitType
        roundType
        premoneyValuation
        additionalKeyterms {
          label
          description
        }
        startupPeriod
        revSharePercentageDescription
        useOfProceedFootnote
        currentFinancialStatements
        submitted {
          aliasId: id
          by
          date
        }
        approved {
          aliasId: id
          by
          date
          status
        }
        legalBusinessName
        shorthandBusinessName
        industry
        maturity
        regulation
        frequencyOfPayments
        securities
        securityInterest
        securitiesOwnershipPercentage
        locationRiskFactors
        investmentMultiple
        revSharePercentage
        interestRate
        minOfferingAmountCF
        minOfferingAmount506
        maxOfferingAmountCF
        maxOfferingAmount506
        minOfferingAmount506C
        maxOfferingAmount506C
        legalBusinessType
        nsMinFees
        nsMaxFees
        stateOfFormation
        city
        state
        minInvestAmt
        appendixATitle
        investmentMultipleSummary
        stockType
        offeringDisclaimer
        revShareSummary
        nsFeeCalcDescription
        isNewBusiness
        isHealthcare
        isFood
        isAlcohol
        totalProjectCost
        raisedThroughSaleOfEquity
        nsFeePercentage
        uploadProformas {
          fileId
          fileName
          fileHandle {
            id
            boxFileId
            fileExtension
            fileStatus
            fileType
            stepName
            userId
            created {
              aliasId: id
              by
              date
            }
            updated {
              aliasId: id
              by
              date
            }
            deleted {
              aliasId: id
              by
              date
            }
          }
        }
      }
      media {
        heroImage {
          id
          url
          isPublic
          fileName
        }
        tombstoneImage {
          id
          url
          isPublic
          fileName
        }
        locationHeroImage {
          id
          url
          isPublic
          fileName
        }
        location {
          id
          url
          isPublic
          fileName
        }
        gallery {
          id
          url
          isPublic
          fileName
        }
        logo {
          id
          url
          isPublic
          fileName
        }
        avatar {
          id
          url
          isPublic
          fileName
        }
        heroVideo {
          id
          url
          isPublic
          fileName
        }
        heroBackground {
          id
          url
          isPublic
          fileName
        }
        useOfProceeds {
          id
          url
          isPublic
          fileName
        }
        businessModelImage {
          id
          url
          isPublic
          fileName
        }
        issuerSubmitted
        submitted {
          aliasId: id
          by
          date
        }
        approved {
          aliasId: id
          by
          date
          status
        }
      }
      contingencies {
        launch {
          contingency
          acceptance
          accepted {
            status
            aliasId: id
            by
            date
            comment
          }
        }
        close {
          contingency
          acceptance
          accepted {
            aliasId: id
            status
            date
            by
            comment
          }
        }
      }
      offering {
        overview {
          elevatorPitch
          tombstoneDescription
          highlight
          googleMeta
          social {
            type
            url
            shareLink
            blurb
            featuredImageUpload {
              id
              url
              fileName
              isPublic
            }
          }
          issuerWebsite
          issuerSubmitted
          submitted {
            aliasId: id
            by
            date
          }
          approved {
            aliasId: id
            by
            date
            status
          }
        }
        about {
          theCompany
          history {
            date
            description
          }
          businessModel
          locationAnalysis
          issuerSubmitted
          submitted {
            aliasId: id
            by
            date
          }
          approved {
            aliasId: id
            by
            date
            status
          }
        }
        misc {
          additionalBonusRewardsContent
          submitted {
            aliasId: id
            by
            date
          }
          approved {
            aliasId: id
            by
            date
            status
          }
        }
        misc {
          additionalBonusRewardsContent
          submitted {
            aliasId: id
            by
            date
          }
          approved {
            aliasId: id
            by
            date
            status
          }
        }
        launch {
          targetDate
          terminationDate
          expectedOpsDate
          issuerApprovedDate
          edgarLink
          investmentConfirmationTemplateName
          submitted {
            aliasId: id
            by
            date
          }
          approved {
            aliasId: id
            by
            date
            status
          }
        }
      }
      legal {
        issuerBacId
        affiliatedIssuerBacId
        general {
          websiteUrl
          monthLaunch
          offeringDeadline
          employmentIdNumber
          numOfEmployees
          taxedAs
          businessStreet
          businessCity
          businessState
          businessZip
          businessPhone {
            countryCode
            number
          }
          bankName
          bankRoutingNumber
          accountNumber
          businessCapitalization
          useOfProceeds {
            offeringExpenseAmount
            offeringExpenseAmountDescription
          }
          equityShareholderRights
          security {
            class
            votingRights
            securitiesAuthorized
            securitiesOutstanding
            limitDiluteQualify
          }
          exemptOfferings {
            dateOfOffering
            securitiesExemption
            securitiesOffered
            amountSold
            useOfProceeds
          }
          materialIndebtedness {
            creditorName
            amountOutstanding
            interestRate
            maturityDate
            paymentSchedule
            otherTerms
          }
          affiliatedTransactions {
            name
            relationship
            amountTransaction
            description
          }
          issuerSubmitted
          submitted {
            aliasId: id
            by
            date
          }
          approved {
            aliasId: id
            by
            date
            status
          }
        }
        riskFactors {
          businessRisks
          financingRisks
          developmentRisks
          reputationalRisks
          competitionRisks
          marketRisks
          workStoppagesRisks
          managementRisks
          personnelRisks
          laborSupplyRisks
          privacyRisks
          realEstateRisks
          supplyRisks
          foodSafetyRisks
          nutritionalDisclosureRisks
          foodRisks
          alcoholSalesRisks
          alcoholInvestmentRisks
          industryRisks
          healthcareRisks
          legalRisks
          environmentalRisks
          itRisks
          accountingRisks
          ipRisks
          regulatoryRisks
          regulatoryFoodRisks
          taxRisks
          limitedRepaymentRisks
          relatingForecastsRisks
          debtFinancingRisks
          conflictOfInterestRisks
          issuerSubmitted
          submitted {
            aliasId: id
            by
            date
          }
          approved {
            aliasId: id
            by
            date
            status
          }
        }
        dataroom {
          documents {
            name
            upload {
              fileId
              fileName
            }
            accreditedOnly
          }
          submitted {
            aliasId: id
            by
            date
          }
          approved {
            aliasId: id
            by
            date
            status
          }
        }
        documentation {
          issuer {
            formID {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            corpFormation {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            issuerFinancials {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            leaseAgreement {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            issuerSubmitted
            submitted {
              aliasId: id
              by
              date
            }
            approved {
              aliasId: id
              by
              date
              status
            }
          }
          admin {
            escrow {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            resolutionOfBorrowing {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            formC {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            npa {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            purchaseAgreement {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            specialPurposeEntityAgreement {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            llcAgreement {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            subscriptionAgreement {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            proxyAgreement {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            safeNote {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            disclosure {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            securityAgreement {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            personalGuarantee {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            promissoryNote {
              fileId
              fileName
              fileHandle {
                id
                created {
                  date
                  by
                }
                updated {
                  date
                  by
                }
              }
            }
            edgar
            submitted {
              aliasId: id
              by
              date
            }
            approved {
              aliasId: id
              by
              date
              status
            }
          }
          admin {
            escrow {
              fileId
              fileName
            }
            resolutionOfBorrowing {
              fileId
              fileName
            }
            formC {
              fileId
              fileName
            }
            npa {
              fileId
              fileName
            }
            purchaseAgreement {
              fileId
              fileName
            }
            proxyAgreement {
              fileId
              fileName
            }
            safeNote {
              fileId
              fileName
            }
            specialPurposeEntityAgreement {
              fileId
              fileName
            }
            llcAgreement {
              fileId
              fileName
            }
            subscriptionAgreement {
              fileId
              fileName
            }
            disclosure {
              fileId
              fileName
            }
            securityAgreement {
              fileId
              fileName
            }
            personalGuarantee {
              fileId
              fileName
            }
          }
        }
      }
      leadership {
        employer {
          name
          type
          description
          title
          dateOfService
        }
        isPublic
        isBeneficialOwnerDocGeneration
        firstName
        lastName
        email
        leaderBacId
        phone {
          number
          countryCode
        }
        dob
        ssn
        citizenship
        percentOwned
        companyPosition
        dateOfService
        address {
          street
          city
          state
          zip
        }
        dlLicenseNumber
        dlState
        dlIssuedDate
        dlExpirationDate
        bio
        uploads {
          headshot {
            id
            url
            fileName
            isPublic
          }
          heroImage {
            id
            url
            fileName
            isPublic
          }
          license {
            id
            url
            fileName
            isPublic
          }
        }
        social {
          website
          facebook
          linkedin
          twitter
        }
        otherEntities
        promoters
        issuerSubmitted
        submitted {
          aliasId: id
          by
          date
        }
        approved {
          aliasId: id
          by
          date
          status
        }
      }
      closingBinder {
        name
        aliasAccreditedOnly: isVisible
        status
        upload {
          fileId
          fileName
          fileHandle {
            boxFileId
          }
        }
      }
      closureSummary {
        defaultedDate
        exportEnvelopes {
          fileSubstitution {
            upload {
              fileId
              fileName
            }
            replacePage
          }
        }
        processingDate
        hardCloseDate
        launchDate
        disbursement {
          date
          amount
        }
        operationsDate
        keyTerms {
          monthlyPayment
          supplementalAgreements {
            documents {
              name
              aliasAccreditedOnly: isVisible
              upload {
                fileId
                fileName
              }
            }
          }
          priceCalculation
          multiple
          revSharePercentage
          interestRate
          businessOpenDate
          nsPayment
          nsPaymentSecurities
          investorFee
          maturityDate
          anticipatedPaymentStartDate
          gsFees
        }
        repayment {
          startDate
          completeDate
          currentRepaidAmount
          count
        }
        totalCommittedAmount
        totalInvestorCount
        totalInvestmentAmount
        totalInvestmentAmountCf
        totalInvestmentAmount506C
        totalInvestmentAmount506B
        failedDate
      }
      bonusRewards{
        id
        offeringId
        title
        description
        rewardStatus
        expirationDate
        earlyBirdQuantity
        tiers
        created {
          aliasId: id
          by
          date
        }
        updated {
          aliasId: id
          by
          date
        }
        deleted {
          aliasId: id
          by
          date
        }
      }
      applicationId
      issuerId
      lead {
        id
      }
      stage
      rewardsTiers
      earlyBird {
        quantity
        amount
      }
      created{
        aliasId: id
        by
        date
      }
      updated{
        aliasId: id
        by
        date
      }
      deleted{
        aliasId: id
        by
        date
      }
      order
      meta
    }
  }
`;

export const updateOffering = gql`
mutation updateOffering($id: String!, $issuerId: String, $adminId: String, $offeringDetails: OfferingInputType!) {
  updateOffering(id: $id, issuerId: $issuerId, adminId: $adminId, offeringDetails: $offeringDetails) {
    aliasId: id
    ${common.offeringBasics}
  }
}
`;

export const adminUpsertOffering = gql`
mutation adminUpsertOffering($id: String, $offeringDetails: OfferingInputType!) {
  adminUpsertOffering(id: $id, offeringDetails: $offeringDetails) {
    id
    ${common.offeringBasics}
  }
}
`;

export const getOfferingBac = gql`
query getOfferingBac($offeringId: String! $bacType: OfferingBacTypeEnumType){
  getOfferingBac(
    offeringId: $offeringId
    filters: {
      bacType: $bacType
    }
  ) {
    id
    offeringId
    controlPersonQuestionnaire
    otherEntities
    residenceTenYears
    legalName
    email
    bac1
    bac2
    bac3
    bac4
    bac5
    bac6
    bac7
    bac8
    civilLawsuit
    ofac
    onlineReputation
    judgements
    issuerDiligence
    certificateFormation
    operatingAgreement
    evidenceGoodStanding
    executiveTeam
    isControlDiligence
    isAffiliatedDiligence
    submitted{
      id
      by
      date
    }
    approved{
      id
      by
      date
      status
      reportGeneratedDate
    }
    created{
      id
      by
      date
    }
    updated{
      id
      by
      date
    }
    deleted{
      id
      by
      date
    }
  }
}
`;

export const createBac = gql`
mutation createBAC($offeringBacDetails: OfferingBacInputType!) {
  createOfferingBac(offeringBacDetails: $offeringBacDetails) {
    id
  }
}
`;

export const updateBac = gql`
mutation updateBac($id: String! $offeringBacDetails: OfferingBacInputType!) {
  updateOfferingBac(id: $id offeringBacDetails: $offeringBacDetails) {
    id
  }
}
`;

export const deleteBac = gql`
mutation deleteBac($id: String! $offeringId: String!){
  deleteOfferingBac(id: $id  offeringId: $offeringId) {
    id
  }
}`;

export const adminBusinessFilings = gql`
  query adminBusinessFilings($offeringId: ID! $orderByBusinessFilingSubmission: businessfilingsubmissionOrderBy) {
    adminBusinessFilings(offeringId: $offeringId ) {
      offeringId
      filingId
      filingFolderName
      created
      folderId
      lockedStatus
      submissions (orderBy: $orderByBusinessFilingSubmission) {
        xmlSubmissionId
        created
        xmlSubmissionDownloadUrl
        folderName
        xmlSubmissionStatus
        lockedStatus
      }
    }
  }
`;

export const adminBusinessFiling = gql`
  query adminBusinessFiling($offeringId: ID!, $filingId: ID!) {
    adminBusinessFiling(offeringId: $offeringId, filingId: $filingId) {
      folderId
      submissions{
        payload
      }
    }
  }
`;

export const getXMLFiles = gql`
  query getFiles($folderId: ID!, $accountType: BoxAccountTypeEnum) {
    files(folderId: $folderId, accountType: $accountType) {
      id
      name
    }
  }
`;


export const adminCreateBusinessFiling = gql`
  mutation adminCreateBusinessFiling ($offeringId: String!) {
    adminCreateBusinessFiling(offeringId: $offeringId) {
      filingId
      offeringId
    }
  }
`;

export const upsertBonusReward = gql`
mutation upsertBonusReward($id: String, $bonusRewardDetails: BonusRewardInputType!){
  upsertBonusReward(id: $id, bonusRewardDetails: $bonusRewardDetails){
    id
  }
}
`;

export const getBonusRewards = gql`
query getBonusRewards($offeringId: String!){
  getBonusRewards(offeringId: $offeringId){
    id
    offeringId
    title
    description
    rewardStatus
    expirationDate
    earlyBirdQuantity
    tiers
    created{
      by
      date
    }
    updated{
      by
      date
    }
  }
}
`;

export const deleteBonusReward = gql`
mutation deleteBonusReward($id: String! $offeringId: String!){
  deleteBonusReward(id: $id offeringId: $offeringId
  ){
    id
  }
}
`;

export const getTotalAmount = gql`
query getTotalAmount{
  getNSOfferingAmountRaised
    {
    amountRaisedUS
    amountRaisedTX
    totalInvestorsUS
  }
  }
  `;

export const setOrderForOfferings = gql`
  mutation setOrderForOfferings($offeringOrderDetails:[OfferingOrderInput]){
    setOrderForOfferings(offeringOrderDetails: $offeringOrderDetails)
  }
`;

export const initializeClosingBinder = gql`
  mutation initializeClosingBinder($offeringId: String!){
    initializeClosingBinder(offeringId: $offeringId) {
      name
      status
    }
  }
`;

export const adminSetOfferingAsDefaulted = gql`
  mutation adminSetOfferingAsDefaulted($offeringId: String!, $reason: String!){
    adminSetOfferingAsDefaulted(offeringId: $offeringId, reason: $reason) {
      stage
    }
  }
`;
