import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Header, Container, Dimmer, Loader, Grid } from 'semantic-ui-react';
import NSImage from '../../../shared/NSImage';

@inject('uiStore')
@withRouter
@observer
class Banner extends Component {
  render() {
    const { responsiveVars } = this.props.uiStore;
    const { uptoTablet } = responsiveVars;
    return (
      <section className={`${uptoTablet ? 'pb-30' : ''} banner business-banner bg-white`}>
        <Container>
          <Grid>
            <Grid.Column widescreen={7} computer={7} tablet={16} mobile={16} stackable>
                {uptoTablet && (
                  <NSImage className="mt-40" NSImage path="banners/home-banner.png" />
                )}
                <Header className="home-header" as="h2">
                  NextSeed Business<br />Referral Program
                </Header>
            </Grid.Column>
            <Grid.Column widescreen={9} computer={9} tablet={16} mobile={16}>
            {!uptoTablet
            && (
              <NSImage path="banners/home-banner.png" />
            )}
            </Grid.Column>
          </Grid>
        </Container>
        {this.props.withDimmer && (
          <Dimmer active className="fullscreen">
            <Loader active>Loading..</Loader>
          </Dimmer>
        )}
      </section>
    );
  }
}

export default Banner;
