import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import CollectionsHeader from '../components/CollectionsHeader';
import CollectionsList from '../components/CollectionsList';
import CollectionsFooter from '../components/CollectionsFooter';


@inject('collectionStore')
@observer
@withRouter
class Collections extends Component {
  constructor(props) {
    super(props);
    props.collectionStore.getCollections();
  }

  handleExploreBtn = () => {
    this.props.history.push('/offerings');
    window.scrollTo(0, 0);
  }

  render() {
    const metaDescription = 'Browse curated combinations of investment opportunities and businesses, categorized by the Communities that matter to you most.';
    return (
      <>
        <Helmet>
          <meta name="description" content={metaDescription} />
          <meta property="og:description" content={metaDescription} />
          <meta name="twitter:description" content={metaDescription} />
        </Helmet>
        <CollectionsHeader />
        <CollectionsList />
        <CollectionsFooter />
      </>
    );
  }
}

export default Collections;
