/* eslint-disable no-param-reassign */
import { decorate, action, observable, runInAction, computed } from 'mobx';
import { isEmpty, isUndefined, intersection, pick } from 'lodash';
import cookie from 'react-cookies';
import moment from 'moment';
import { updateInvestorProfileData } from '../../queries/account';
import {
  EMPLOYMENT_STATUS,
  BROKERAGE_EMPLOYMENT,
  PUBLIC_COMPANY_REL,
  FINANCES,
  INVESTOR_PROFILE_FULL_META,
  INVESTMENT_EXPERIENCE,
  } from '../../../../constants/account';
import { SELF_VERIFICATION_META, SELF_VERIFICATION_INPUTS } from '../../../constants/selfVerification';
import { FormValidator as Validator } from '../../../../helper';
import { userDetailsStore, investmentLimitStore, uiStore } from '../../index';
import AccCreationHelper from '../../../../modules/private/investor/accountSetup/containers/accountCreation/helper';
import DataModelStore, { decorateDefault } from '../shared/dataModelStore';

export class InvestorProfileStore extends DataModelStore {
  constructor() {
    super({
      updateInvestorProfileData,
    });
  }

  threshold = {
    netWorthThreshold: 1000000,
    jointThreshold: 300000,
    individualThreshold: 200000,
  }

  selfAccreditationStepData = {};

  invProfileForms = ['EMPLOYMENT_STATUS_FRM', 'BROKERAGE_EMPLOYMENT_FRM', 'PUBLIC_COMPANY_REL_FRM', 'FINANCIAL_INFO_FRM', 'SELF_ACCREDITATION_FRM']

  EMPLOYMENT_STATUS_FRM = Validator.prepareFormObject(EMPLOYMENT_STATUS, true);

  BROKERAGE_EMPLOYMENT_FRM = Validator.prepareFormObject(BROKERAGE_EMPLOYMENT, true);

  PUBLIC_COMPANY_REL_FRM = Validator.prepareFormObject(PUBLIC_COMPANY_REL, true);

  INVESTOR_PROFILE_FULL = Validator.prepareFormObject(INVESTOR_PROFILE_FULL_META, true);

  FINANCIAL_INFO_FRM = Validator.prepareFormObject(FINANCES, true);

  INVESTMENT_EXP_FRM = Validator.prepareFormObject(INVESTMENT_EXPERIENCE, true);

  SELF_ACCREDITATION_FRM = Validator.prepareFormObject(SELF_VERIFICATION_INPUTS, true);

  formArr = [...this.invProfileForms, ...['INVESTMENT_EXP_FRM']];

  isFormSubmitted = false;

  profileOnboardingFlag = cookie.load('PROFILE_ONBOARDED') || false;

  setProfileOnboardingCookie = () => {
    this.profileOnboardingFlag = cookie.load('PROFILE_ONBOARDED');
  }

  setProfileOnboarding = (status) => {
    this.profileOnboardingFlag = status;
    if (status) {
      cookie.save('PROFILE_ONBOARDED', status, { maxAge: 604800 });
    }
    this.setProfileOnboardingCookie();
  }

  @action
  upsertInvestorProfile = async (currentStep) => {
    const { fields } = this[currentStep.form];
    this.validateForm(currentStep.form, false, true);
    if (this[currentStep.form].meta.isValid) {
      const { payLoad } = this.formPayLoad(fields);

      const { submitEmptyValues } = uiStore;
      if (submitEmptyValues) {
        payLoad.accreditationSelfVerification = { questionnaire: null, verifiedAt: null };
      }

      const res = await this.upsertInvestorProfileMutation(payLoad);

      if (res.data.createInvestorProfile.id) {
        runInAction(() => {
          Validator.setIsDirty(this[currentStep.form], false);
          userDetailsStore.setUserStatus(res.data.createInvestorProfile.status);
          this.isFormSubmitted = true;
          userDetailsStore.mergeUserData('investorProfileData', payLoad);
          if (currentStep.form !== 'INVESTMENT_EXP_FRM') {
            this.setStepToBeRendered(currentStep.stepToBeRendered);
          }
        });
      }
      return true;
    }
    return false;
  }

  formPayLoad = (fields) => {
    let payLoad = Validator.evaluateFormData(fields);
    payLoad.isPartialProfile = !!isUndefined(payLoad.isPartialProfile);
    const fieldArray = Object.keys(fields);

    if (intersection(['isComfortable', 'isRiskTaker'], fieldArray).length > 0) {
      Object.keys(pick(fields, ['isComfortable', 'isRiskTaker'])).forEach((field) => {
        payLoad[field] = fields[field].value.length > 0;
      });
    }

    if (fields.selfVerificationMethods) {
      const accreditationSelfVerification = {};
      const questionnaire = [];
      const { finraMethod, finraCRD, selfVerificationMethods } = fields;
      if (finraMethod && finraMethod.value.length) {
        finraMethod.values.map((obj) => {
          obj.context = finraCRD.value;
          obj.key = obj.value;
          delete obj.value;
          delete obj.limitExceeded;
          questionnaire.push(obj);
          return questionnaire;
        });
      }

      selfVerificationMethods.value.map((val) => {
        if (val) {
          selfVerificationMethods.values.map((obj) => {
            if (obj.value === val) {
              obj.key = obj.value;
              delete obj.value;
              delete obj.limitExceeded;
              questionnaire.push(obj);
            }
            return questionnaire;
          });
        }
        return questionnaire;
      });
      accreditationSelfVerification.questionnaire = questionnaire;
      accreditationSelfVerification.verifiedAt = moment().toISOString();

      payLoad = {
        isPartialProfile: payLoad.isPartialProfile,
        accreditationSelfVerification,
      };
    }

    if (fields.annualIncomeCurrentYear) {
      payLoad = {
        ...payLoad,
        annualIncome: [
          {
            year: fields.annualIncomeCurrentYear.year,
            income: fields.annualIncomeCurrentYear.value,
          }],
      };
    }

    return { payLoad };
  }

  @action
  resetForm = (form) => {
    Validator.resetFormData(this[form]);
  }

  textRadioFieldData = (fields) => {
    const textField = Object.keys(fields).find(field => !fields[field].values);
    const radioField = Object.keys(fields).find(field => fields[field].values);
    return { textField, radioField };
  }

  get isInvestmentExperienceValid() {
    const { isComfortable, isRiskTaker, experienceLevel } = Validator.ExtractValues(this.INVESTMENT_EXP_FRM.fields);
    return isComfortable.length !== 0 && isRiskTaker.length !== 0 && experienceLevel !== 'NONE';
  }

  upsertInvestorProfileMutation = async (payLoad) => {
    const res = await this.executeMutation({
      mutation: 'updateInvestorProfileData', variables: payLoad,
    });
    return res;
  }

  setFormDataWithRadio = (data, form, textField, radioField) => {
    const { fields } = this[form];
    fields[textField].value = data[textField] !== 'false' ? data[textField] : '';
    fields[radioField].value = data[textField] && data[textField] !== ''
      && data[textField] !== 'false' ? 'yes' : 'no';
  }

  setInvestorDetailInfo = (data) => {
    if (data) {
      this.INVESTOR_PROFILE_FULL = Validator.prepareFormObject(INVESTOR_PROFILE_FULL_META, true);
      this.formArr.forEach((form) => {
        this.setFormData(form, data);
        this.INVESTOR_PROFILE_FULL.fields = { ...this.INVESTOR_PROFILE_FULL.fields, ...this[form].fields };
      });
      ['isRiskTaker', 'isComfortable'].map((field) => {
        if (data && data[field]) {
          this.INVESTOR_PROFILE_FULL.fields[field].value = data[field] ? ['checked'] : [];
        }
        return false;
      });
      Validator.onChange(this.INVESTOR_PROFILE_FULL, '', '', false);
    }
  }

  updateInvestorEditProfileData = async () => {
    const { fields } = this.INVESTOR_PROFILE_FULL;
    const { payLoad } = this.formPayLoad(fields);
    const res = await this.upsertInvestorProfileMutation(payLoad);
    if (res.data.createInvestorProfile.id) {
      userDetailsStore.mergeUserData('investorProfileData', payLoad, 'currentUser');
    }
  }

  setFormData = (form, data) => {
    if (['BROKERAGE_EMPLOYMENT_FRM', 'PUBLIC_COMPANY_REL_FRM'].includes(form)) {
      const { textField, radioField } = this.textRadioFieldData(this[form].fields);
      this.setFormDataWithRadio(data, form, textField, radioField);
    } else if (form === 'FINANCIAL_INFO_FRM') {
      this[form] = Validator.setFormData(this[form], data);
      ['annualIncomeCurrentYear'].map((item, index) => {
        this[form].fields[item].value = data.annualIncome && data.annualIncome[index].income;
        return true;
      });
    } else {
      this[form] = Validator.setFormData(this[form], data);
    }
  }

  populateData = (userData) => {
    if (!isEmpty(userData)) {
      const { investorProfileData, accreditationSelfVerification } = userData;
      const userProfileData = { ...investorProfileData, accreditationSelfVerification };
      if (userProfileData) {
        // eslint-disable-next-line array-callback-return
        this.invProfileForms.forEach((form) => {
          this.setFormData(form, userProfileData);
          Validator.onChange(this[form], '', '', false);
        });
        this.invProfileForms.some((form) => {
          const getProfileStep = AccCreationHelper.establishProfileSteps();
          if (!this[form].meta.isValid) {
            this.setStepToBeRendered(getProfileStep[form]);
            return true;
          }
          this.setStepToBeRendered(getProfileStep.INVESTMENT_EXP_FRM);
          return false;
        });
      }
    }
  }

  get isInvExperienceValid() {
    const { isComfortable, isRiskTaker, experienceLevel } = Validator.ExtractValues(this.INVESTMENT_EXP_FRM.fields);
    return isComfortable.length !== 0 && isRiskTaker.length !== 0 && experienceLevel !== 'NONE';
  }

  get isValidInvestorProfileForm() {
    return ![...this.invProfileForms, ...['INVESTMENT_EXP_FRM']].find(form => !this[form].meta.isValid);
  }

  get householdFilingStatus() {
    if (this.FINANCIAL_INFO_FRM.fields.taxFilingAs.value === 'JOINT') {
      return this.threshold.jointThreshold;
    }
    return this.threshold.individualThreshold;
  }

  get monetaryThresholdReached() {
    const financialDetails = userDetailsStore.userAccountFinancialDetails.individualAccountFinancialDetails;
    const { isUpdateInvestmentLimitRoute } = uiStore;
    const { income, netWorth } = financialDetails;
    let currentNetIncome;
    let currentNetWorth;
    if (isUpdateInvestmentLimitRoute) {
      currentNetIncome = investmentLimitStore.INVESTMENT_LIMIT_META.fields.annualIncome.value || income;
      currentNetWorth = investmentLimitStore.INVESTMENT_LIMIT_META.fields.netWorth.value || netWorth;
    } else {
      currentNetIncome = this.FINANCIAL_INFO_FRM.fields.annualIncomeCurrentYear.value || income;
      currentNetWorth = this.FINANCIAL_INFO_FRM.fields.netWorth.value || netWorth;
    }
    if ((currentNetWorth >= this.threshold.netWorthThreshold) || (currentNetIncome >= this.householdFilingStatus)) {
      return true;
    }
    return false;
  }

  get isFinraSelected() {
    return this.SELF_ACCREDITATION_FRM.fields.finraMethod.value.includes('FINRA_LICENSED');
  }

  get isCheckboxValid() {
    return !isEmpty(this.SELF_ACCREDITATION_FRM.fields.selfVerificationMethods.value) || !isEmpty(this.SELF_ACCREDITATION_FRM.fields.finraMethod.value);
  }

  get isFormValid() {
    let isCRDValid = false;
    if (this.isFinraSelected) {
      isCRDValid = !isEmpty(this.SELF_ACCREDITATION_FRM.fields.finraCRD.value);
    } else isCRDValid = true;

    return this.isCheckboxValid && isCRDValid;
  }

  getSelfAccreditationStepData = () => {
    if (this.monetaryThresholdReached) {
      this.selfAccreditationStepData = SELF_VERIFICATION_META.unlimited;
    } else {
      this.selfAccreditationStepData = SELF_VERIFICATION_META.limited;
    }
    return this.selfAccreditationStepData;
  }

  selectVerificationInputs = () => {
    if (!this.monetaryThresholdReached) {
      this.SELF_ACCREDITATION_FRM.fields.selfVerificationMethods.values = this.SELF_ACCREDITATION_FRM.fields.selfVerificationMethods.values.filter(i => !i.limitExceeded);
    }
  }
}


decorate(InvestorProfileStore, {
  EMPLOYMENT_STATUS_FRM: observable,
  BROKERAGE_EMPLOYMENT_FRM: observable,
  PUBLIC_COMPANY_REL_FRM: observable,
  FINANCIAL_INFO_FRM: observable,
  INVESTMENT_EXP_FRM: observable,
  INVESTOR_PROFILE_FULL: observable,
  SELF_ACCREDITATION_FRM: observable,
  isFormSubmitted: observable,
  profileOnboardingFlag: observable,
  setProfileOnboardingCookie: action,
  setProfileOnboarding: action,
  setInvestorDetailInfo: action,
  populateData: action,
  setFormDataWithRadio: action,
  updateInvestorEditProfileData: action,
  setFormData: action,
  isInvestmentExperienceValid: computed,
  isValidInvestorProfileForm: computed,
  householdFilingStatus: computed,
  monetaryThresholdReached: computed,
  getSelfAccreditationStepData: action,
  selectVerificationInputs: action,
  ...decorateDefault,
});

export default new InvestorProfileStore();
