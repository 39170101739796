import { observable, action } from 'mobx';
import graphql from 'mobx-apollo';
import { get } from 'lodash';
import { GqlClient as client } from '../../../../api/gqlApi';
import { getUserReferralDetails } from '../../queries/referrals';
import Helper from '../../../../helper/utility';

export class ReferralStore {
  @observable referralCode = null;

  @observable referralData = {
    availableCredit: 0,
    spentCredit: 0,
    totalEarnedCredit: 0,
    totalReferredUsers: 0,
    loading: false,
  }

  getUserReferralDetails = (userId = false, showToast = true) => new Promise((resolve, reject) => {
    graphql({
      client,
      query: getUserReferralDetails,
      variables: userId ? { userId } : { },
      fetchPolicy: 'network-only',
      onFetch: (data) => {
        if (data) {
          this.setReferralData(data);
          resolve(data);
        }
      },
      onError: () => {
        if (showToast) {
          Helper.toast('Something went wrong, please try again later.', 'error');
        }
        reject();
      },
    });
  });

  @action
  setReferralData = (data) => {
    this.referralData = { ...get(data, 'getUserReferralDetails') };
  }
}

export default new ReferralStore();
