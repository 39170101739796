import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Logo } from '../index';

const TopBanner = withRouter(props => (
  <div className="clickable">
    <Link to="/about?utm_source=nextseed&amp;utm_medium=website_republic_banner&amp;utm_campaign=ns_acq" rel="noopener noreferrer">
      <span className={`${(props.leftMenu) ? 'hide' : ''} sticky-message`}>
        <div className="white-highlight">Big news! NextSeed joins{' '}
          <a href="http://republic.co" rel="noopener noreferrer" target="_blank" className="republic-logo">
            <Logo dataSrc="RepublicIconWhite" size="mini" />
          </a>{' '}Republic
        </div>
        <div className="republic-hand">
          <Logo dataSrc="RepublicHand" size="tiny" />
        </div>
        <div className={props.isMobile && 'center-align'}>
          <Link rel="noopener noreferrer" to="/about?utm_source=nextseed&amp;utm_medium=website_republic_banner&amp;utm_campaign=ns_acq">
            <span role="banner" className="white-highlight underline-text">{' '}{props.isMobile ? 'Learn More' : <b>Learn More</b>}</span>
          </Link>
        </div>
      </span>
    </Link>
    <Button
      onClick={props.toggle}
      circular
      size="large"
      className="close-button"
      icon={{ className: 'ns-close-light' }}
    />
  </div>
));

export default TopBanner;
