import React from 'react';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import Helper from '../../../../helper/utility';

const EdCenterMetaTags = ({ article }) => (
  <Helmet>
    <meta name="description" content={Helper.descriptionMetaTag(article, 'content')} />
    <link rel="canonical" href={window.location.href} />
    <title>{Helper.pageTitle(`${get(article, 'title')}`)} | NextSeed</title>
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content={`${get(article, 'title')} | NextSeed`} />
    <meta property="og:description" content={Helper.descriptionMetaTag(article, 'content')} />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:site_name" content="NextSeed" />
    <meta property="article:publisher" content="https://www.facebook.com/thenextseed" />
    <meta property="article:tag" content={get(article, 'tags') ? get(article, 'tags').join(', ') : ''} />
    <meta property="article:section" content={get(article, 'category')} />
    <meta property="fb:app_id" content="1806635959569619" />
    <meta property="og:image" content={`https://${window.location.hostname}/og-image_A.jpg`} />
    <meta property="og:image:secure_url" content={`https://${window.location.hostname}/og-image_A.jpg`} />
    <meta property="og:image:width" content="1218" />
    <meta property="og:image:height" content="542" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:description" content={Helper.descriptionMetaTag(article, 'content')} />
    <meta name="twitter:title" content={`${get(article, 'title')} | NextSeed`} />
    <meta name="twitter:site" content="@thenextseed" />
    <meta name="twitter:image" content={`https://${window.location.hostname}/og-image_A.jpg`} />
    <meta name="twitter:creator" content="@thenextseed" />
    <meta prefix="og: http://ogp.me/ns#" property="og:title" content={`${get(article, 'title')} | NextSeed`} />
    <meta prefix="og: http://ogp.me/ns#" property="og:type" content="article" />
    <meta prefix="og: http://ogp.me/ns#" property="og:image" content={`https://${window.location.hostname}/og-image_A.jpg`} />
    <meta prefix="og: http://ogp.me/ns#" property="og:url" content={window.location.href} />
  </Helmet>
);

export default EdCenterMetaTags;
