import React from 'react';
import { Link } from 'react-router-dom';

export const REPUBLIC_INVESTMENT_OPPORTUNITIES = {
  key: 'republicModule',
  title: <><b className="republic-blue">NEW!</b> Invest on</>,
  aboutTitle: 'Now even more investment opportunities',
  description: <>NextSeed is now a part of Republic. Explore private investing opportunities with<br />
  high-growth potential across startups, gaming, real estate and crypto.<br /><br />
  <Link className="republic-blue" to="/about?utm_source=nextseed&amp;utm_medium=website_republic_banner&amp;utm_campaign=ns_acq">Learn more</Link> about NextSeed and Republic.</>,
  aboutDescription: <>NextSeed investors can now expand their portfolios with private investing opportunities<br />with high-growth potential across startups, gaming, real estate, and crypto.</>,
  buttonText: 'EXPLORE REPUBLIC OFFERINGS',
  externalButton: true,
  desktopImages: ['republic/startups.png', 'republic/realEstate.png', 'republic/gaming.png', 'republic/crypto.png'],
  mobileImages: ['republic/startupsMobile.png', 'republic/realEstateMobile.png', 'republic/gamingMobile.png', 'republic/cryptoMobile.png'],
  // eslint-disable-next-line no-useless-escape
  toLink: 'https://republic.co/companies?utm_source=nextseed\&utm_medium=website_offerings_page_button\&utm_campaign=ns_acq',
};
