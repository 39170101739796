import { COMMON } from './auth';

export const TRANSFER_FUND = {
  amount: {
    value: '',
    label: 'Amount',
    error: undefined,
    placeHolder: 'Enter amount',
    rule: 'required|numeric',
  },
};

export const ADD_WITHDRAW_FUND = {
  amount: TRANSFER_FUND.amount,
  description: {
    value: '',
    label: 'Justify',
    error: undefined,
    rule: 'optional',
    placeHolder: 'Type your comment here...',
  },
  showAgreementId: {
    value: false,
    rule: 'optional',
    skipField: true,
  },
  agreementId: {
    value: '',
    label: 'Agreement ID',
    error: undefined,
    placeHolder: 'Enter Agreement ID',
    rule: 'optional',
  },
  sendInvestorNotification: {
    value: false,
    label: 'Send Notification to Investor',
    error: undefined,
    rule: 'optional',
  },
};

export const ADD_CREDIT_FUND = {
  amount: TRANSFER_FUND.amount,
  description: {
    value: '',
    label: 'Description',
    error: undefined,
    rule: 'required',
    placeHolder: 'Type your comment here...',
  },
  type: {
    key: 'type',
    value: '',
    label: 'Type of Credit',
    error: undefined,
    placeHolder: 'Choose one',
    rule: 'optional',
  },
};

export const TYPE_OF_CREDIT = [
  { key: 'credit', value: 'ACCREDITATION_PROMOTION', text: 'Accredited Status' },
  { key: 'credit', value: 'CREDIT', text: 'Credit' },
];

export const VERIFY_OTP = {
  code: { ...COMMON.code },
};
