import React, { Component } from 'react';
import { Container, Header } from 'semantic-ui-react';

class Community extends Component {
  render() {
    return (
      <section>
        <Container>
          <Header as="h2" className="mb-80" textAlign="center">
            Community !
          </Header>
        </Container>
      </section>
    );
  }
}

export default Community;
