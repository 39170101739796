class Helper {
  getFundingTypeIndex = (value) => {
    let index = '';
    if (value === 'check') {
      index = 0;
    } else if (value === 'iraTransfer') {
      index = 1;
    } else if (value === 'directRollOver') {
      index = 2;
    }
    return index;
  }

  getAccountTypeIndex = (value) => {
    let index = '';
    if (value === 'traditional') {
      index = 0;
    } else if (value === 'roth') {
      index = 1;
    }
    return index;
  }

  entitySteps = () => ({
    FIN_INFO_FRM: 0,
    GEN_INFO_FRM: 1,
    TRUST_INFO_FRM: 2,
    PERSONAL_INFO_FRM: 3,
    FORM_DOCS_FRM: 4,
    formLinkBankManually: 5,
    addFunds: 6,
    summary: 7,
    THANK_YOU: 8,
  });

  iraSteps = fundingType => (fundingType !== 0 ? {
    FIN_INFO_FRM: 1,
    ACC_TYPES_FRM: 2,
    IDENTITY_FRM: 3,
    BENEFICIARY_FRM: 4,
    SPOUSAL_CONSENT_FRM: 5,
    FUNDING_FRM: 6,
    CUSTODIAN_FRM: 7,
    RMD_FRM: 8,
    ASSETS_LIQUIDATION_FRM: 9,
    IRA_STATEMENT_UPLOAD_FRM: 10,
    SUMMARY: 11,
    SIGNATURES: 12,
    THANK_YOU: 13,
  } : {
    FIN_INFO_FRM: 1,
    ACC_TYPES_FRM: 2,
    IDENTITY_FRM: 3,
    BENEFICIARY_FRM: 4,
    SPOUSAL_CONSENT_FRM: 5,
    FUNDING_FRM: 6,
    LINK_BANK: 7,
    ADD_FUNDS: 8,
    SUMMARY: 9,
    SIGNATURES: 10,
    THANK_YOU: 11,
  });

  individualSteps = () => ({
    formLinkBankManually: 1,
    summary: 2,
    THANK_YOU: 3,
  });

  establishProfileSteps = () => ({
    EMPLOYMENT_STATUS_FRM: 1,
    BROKERAGE_EMPLOYMENT_FRM: 2,
    PUBLIC_COMPANY_REL_FRM: 3,
    INVESTOR_PROFILE_FULL: 4,
    FINANCIAL_INFO_FRM: 4,
    INVESTMENT_EXP_FRM: 5,
  });
}

export default new Helper();
