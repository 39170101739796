import React from 'react';
import { Mission, Team } from './index';
import { RepublicHomeBanner, RepublicAboutBanner, RepublicModule } from '../../republic/index';
import { REPUBLIC_INVESTMENT_OPPORTUNITIES } from '../../../../constants/about';

const isMobile = document.documentElement.clientWidth < 768;

const aboutSections = [
  {
    key: 'republicBanner',
    component: <RepublicHomeBanner isMobile={isMobile} />,
  },
  {
    key: 'republicSecondBanner',
    component: <RepublicAboutBanner isMobile={isMobile} />,
  },
  {
    key: 'republicCards',
    component: <RepublicModule staticMeta={REPUBLIC_INVESTMENT_OPPORTUNITIES} isMobile={isMobile} />,
  },
  {
    key: 'mission',
    component: <Mission />,
  },
  {
    key: 'team',
    component: <Team />,
  },
];

const About = () => (
  <>
    {aboutSections.map(section => (
      <React.Fragment key={section.key}>
        {section.component}
      </React.Fragment>
    ))}
  </>
);

export default About;
