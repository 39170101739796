import { observable, action, computed, toJS, decorate } from 'mobx';
import { startCase, isEmpty, keyBy } from 'lodash';
import DataModelStore, { decorateDefault } from '../shared/dataModelStore';
import { adminFetchEmails, fetchAdminListEmailTypesAndIdentifier } from '../../queries/users';
import Helper from '../../../../helper/utility';
import { FormValidator as Validator } from '../../../../helper';
import { EMAILLIST_META } from '../../../constants/admin/data';
import { factoryStore } from '../../index';


export class EmailStore extends DataModelStore {
  constructor() {
    super({ adminFetchEmails, fetchAdminListEmailTypesAndIdentifier });
  }

  EMAIL_LIST_FRM = Validator.prepareFormObject(EMAILLIST_META);

  filters = true;

  emailLogList = [];

  emailPlugin = {};

  listEmailTypes = [];

  listEmailIdentifiers = [];

  initRequest = async (reqParams) => {
    try {
      const { emailType, emailIdentifier, keyword, startDate, endDate } = this.requestState.search;
      const filters = toJS({ ...this.requestState.search });
      delete filters.keyword;
      this.requestState.page = (reqParams && reqParams.page) || this.requestState.page;
      this.requestState.perPage = (reqParams && reqParams.first) || this.requestState.perPage;
      let params = {
        search: keyword,
        // recipientId: emailType || 'DEV',
        recipientId: emailType,
        limit: this.requestState.perPage,
      };
      params = emailIdentifier ? { ...params, emailIdentifier } : { ...params };
      params = this.requestState.lek[`page-${this.requestState.page}`]
        ? { ...params, lek: this.requestState.lek[`page-${this.requestState.page}`] } : { ...params };

      if (startDate || endDate) {
        params = {
          ...params,
          ...{ fromDate: startDate, toDate: endDate },
        };
      }

      const data = await this.executeQuery({
        client: 'PRIVATE',
        query: 'adminFetchEmails',
        variables: params,
        setLoader: 'adminFetchEmails',
        fetchPolicy: 'network-only',
      });
      this.setFieldValue('emailLogList', data);
      const { lek } = data.adminFetchEmails;
      const requestStateObj = {
        ...this.requestState,
        lek: {
          ...this.requestState.lek,
          [`page-${this.requestState.page + 1}`]: lek,
        },
      };
      this.setFieldValue('requestState', requestStateObj);
    } catch (error) {
      Helper.toast('Something went wrong, please try again later.', 'error');
    }
  }

  fetchAdminListEmailTypesAndIdentifier = async () => {
    try {
      const data = await this.executeQuery({
        client: 'PRIVATE',
        query: 'fetchAdminListEmailTypesAndIdentifier',
        variables: {},
        setLoader: 'adminListEmailType',
      });
      const listEmailType = [];
      if (data.adminListEmailType && data.adminListEmailType.length) {
        listEmailType.push({ key: 'SELECT', value: null, text: 'SELECT' });
        data.adminListEmailType.forEach((e) => {
          listEmailType.push({ key: e, value: e, text: startCase(e) });
        });
      }
      const listEmailIdentifiers = [];
      if (data.adminListEmailPluginsByIndex && data.adminListEmailPluginsByIndex.length) {
        listEmailIdentifiers.push({ key: 'SELECT', value: null, text: 'SELECT' });
        data.adminListEmailPluginsByIndex.forEach((e) => {
          listEmailIdentifiers.push({ key: e.emailIdentifier, value: e.emailIdentifier, text: e.emailIdentifier, note: e.config.note, description: e.config.description, pluginInput: e.config.pluginInputs });
        });
      }
      this.setFieldValue('listEmailTypes', listEmailType);
      this.setFieldValue('listEmailIdentifiers', listEmailIdentifiers);
      factoryStore.setEmailIdentifier(listEmailIdentifiers);
    } catch (error) {
      window.logger(error);
      Helper.toast('Something went wrong, please try againsetPluginInput later.', 'error');
    }
  }

  get emailList() {
    return (this.emailLogList && this.emailLogList.adminFetchEmails
      && toJS(this.emailLogList.adminFetchEmails.emails)
    ) || [];
  }

  get count() {
    return (this.emailLogList && this.emailLogList.adminFetchEmails
      && toJS(this.emailLogList.adminFetchEmails.resultCount)
    ) || 0;
  }

  setPluginInput = (emailIdentifier) => {
    const pluginObj = this.listEmailIdentifiers.find(
      e => e.key === emailIdentifier,
      );
    this.emailPlugin = {
      ...pluginObj,
      pluginInput: keyBy(pluginObj.pluginInput, 'key'),
    };
  }

  reseFilterManually = (currentFilter, currentFilterValue) => {
    const resetFilter = currentFilter === 'emailType' ? 'emailIdentifier' : 'emailType';
    const filters = { ...this.requestState.search };
    if (!isEmpty(filters)) {
      if (resetFilter in filters === true) {
        delete filters[resetFilter];
      }
    }
    this.setFieldValue('requestState', filters, 'search');
    this.setInitiateSrch(currentFilter, currentFilterValue, { defaultFilterType: 'emailType', defaultValue: 'DEV' });
  }
}

decorate(EmailStore, {
  ...decorateDefault,
  EMAIL_LIST_FRM: observable,
  listEmailTypes: observable,
  listEmailIdentifiers: observable,
  initRequest: action,
  fetchAdminListEmailTypesAndIdentifier: action,
  emailList: computed,
  count: computed,
  emailLogList: observable,
  filters: observable,
  emailPlugin: observable,
  setPluginInput: action,
  reseFilterManually: action,
});

export default new EmailStore();
