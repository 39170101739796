import React from 'react';
import Parser from 'html-react-parser';

const pillersMeta = [
  { x: 0, rotate: 3, begin: 0 },
  { x: 17, rotate: 20, begin: 0.1 },
  { x: 40, rotate: 40, begin: 0.3 },
  { x: 60, rotate: 58, begin: 0.5 },
  { x: 80, rotate: 76, begin: 0.1 },
];

const InlineLoader = props => (
  <section className={`center-align ${props.className}`}>
    {
      props.text ? (
        <h3 className="grey-header">{Parser(props.text) || ''}</h3>
      ) : props.nsLogo ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="280" height="280" viewBox="0 0 280 280">
          <g id="logo-animated" transform="translate(-4.516 -12.515)">
            <rect id="Rectangle_1" data-name="Rectangle 1" width="280" height="280" transform="translate(4.516 12.515)" fill="none" />
            <path className="path" stroke="#75c696" strokeWidth="8" id="Path_1" data-name="Path 1" d="M73.323,84.491c18.644-9.461,30.419-25.772,35.325-48.282l.981-4.241-4.252.326a79.773,79.773,0,0,0-31.727,7.83C55.006,49.258,43.232,65.9,38.325,89.385l-.981,4.241L41.6,93.3C53.044,91.994,64.165,89.058,73.323,84.491Z" transform="translate(112.075 107.182)" fill="none" />
            <path className="path" stroke="#75c696" strokeWidth="15" id="Path_2" data-name="Path 2" d="M122.657,0C54.951,0,0,54.807,0,122.337c0,66.225,52.988,120.379,118.732,122.337V178.122l-2.944-.326a104.42,104.42,0,0,1-31.727-7.177c-26.167-11.092-42.194-33.928-46.773-65.572l-.654-4.241,4.252-.326a93.268,93.268,0,0,1,39.578,6.2c17.99,7.177,31.073,20.226,38.923,38.5l2.944,6.851,2.944-6.851c7.85-18.269,20.933-30.992,38.923-38.5a93.267,93.267,0,0,1,39.577-6.2l4.252.326-.654,4.241c-4.252,31.971-20.606,54.481-46.773,65.572a102.264,102.264,0,0,1-31.727,7.177l-2.944.326v66.551A122.388,122.388,0,0,0,244.66,122.337C245.641,54.807,190.691,0,122.657,0Z" transform="translate(22.516 30.515)" fill="none" />
            <path className="path" stroke="#75c696" strokeWidth="8" id="Path_3" data-name="Path 3" d="M49.55,40.028A78.424,78.424,0,0,0,17.823,32.2l-4.252-.326.981,4.241C19.458,58.623,31.56,74.935,49.877,84.4a89.668,89.668,0,0,0,31.4,8.808l4.252.326-.981-4.241C79.969,65.8,68.194,49.489,49.55,40.028Z" transform="translate(55.062 106.952)" fill="none" />
          </g>
        </svg>
      ) : (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enableBackground="new 0 0 100 100"
          xmlSpace="preserve"
          style={{ height: '60px', width: '100px', display: 'inline-block' }}
        >
          {pillersMeta.map((p, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <rect fill="#75C696" x={p.x} key={index} width="3" height="50" transform={`translate(0) rotate(180 ${p.rotate} 50)`}>
              <animate
                attributeName="height"
                attributeType="XML"
                dur="1s"
                values="10; 50; 10"
                repeatCount="indefinite"
                begin={`${p.begin}s`}
              />
            </rect>
          ))}
        </svg>
      )
    }
  </section>
);

export default InlineLoader;
