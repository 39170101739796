import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Header, Grid } from 'semantic-ui-react';
import NSImage from '../../../shared/NSImage';
import { PRIVATE_INVESTING_COMPONENT } from '../../../../constants/about';


const RepublicAboutBanner = ({ isMobile }) => (
  <section className="bg-offwhite">
      <Container>
        <Grid>
          <Grid.Row className="container-padding">
            <NSImage path={`republic/${isMobile ? 'republicHandMobile.png' : 'republicHand.png'}`} />
            <Header className={`${!isMobile && 'ml-30'}`}>{PRIVATE_INVESTING_COMPONENT.header}</Header>
          </Grid.Row>
        </Grid>
        <p>{PRIVATE_INVESTING_COMPONENT.body}</p>
        <Link to={PRIVATE_INVESTING_COMPONENT.linkURL}>{PRIVATE_INVESTING_COMPONENT.linkText}</Link>
      </Container>
  </section>
);

export default RepublicAboutBanner;
