import React from 'react';
import ReactGA from 'react-ga';
import { get } from 'lodash';
import ReactPixel from 'react-facebook-pixel';
import { withRouter } from 'react-router-dom';

@withRouter
export default class SocialScripts extends React.Component {
  componentDidMount() {
    const { location, campaign } = this.props;
    const googleCode = get(campaign, 'header.tracker.google');
    const fbCode = get(campaign, 'header.tracker.fb');
    if (googleCode) {
      ReactGA.initialize(googleCode);
      ReactGA.pageview(`${location.pathname}${(location.search || location.hash)}`);
    }
    if (fbCode) {
      ReactPixel.grantConsent();
      ReactPixel.init(fbCode);
      ReactPixel.pageView();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, campaign } = this.props;
    const googleCode = get(campaign, 'header.tracker.google');
    // const fbCode = get(campaign, 'header.tracker.fb');
    if (googleCode && location !== prevProps.location) {
      ReactGA.pageview(`${location.pathname}${(location.search || location.hash)}`);
      // ReactPixel.trackCustom('PageView1', { referrer: `${location.pathname}${(location.search || location.hash)}` });
    }
  }

  componentWillUnmount() {
    const fbCode = get(this.props.campaign, 'header.tracker.fb');
    if (fbCode) {
      ReactPixel.revokeConsent();
    }
  }

  render() {
    return '';
  }
}
