/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Modal, Header, Divider, Button, Message, Form, Statistic, Grid, Checkbox } from 'semantic-ui-react';
import { MaskedInput } from '../../../../../../theme/form';
import { ListErrors, NsModal } from '../../../../../../theme/shared';
import Helper from '../../../../../../helper/utility';

@inject('uiStore', 'investmentLimitStore', 'accreditationStore', 'userDetailsStore')
@withRouter
@observer
export default class UpdateInvestmentLimits extends Component {
  constructor(props) {
    super(props);
    const { accountTypeToBeAccredited, investmentCalculate } = this.props.investmentLimitStore;
    const { isUpdateInvestmentLimitRoute } = this.props.uiStore;
    this.props.accreditationStore.getSelfAccreditationStatus(accountTypeToBeAccredited);
    if (isUpdateInvestmentLimitRoute) {
      this.props.investmentLimitStore.setInvestmentLimitInfo(accountTypeToBeAccredited.name, accountTypeToBeAccredited.details.accountId);
    }
    investmentCalculate();
  }

  componentWillUnmount() {
    const { setFieldValue } = this.props.investmentLimitStore;
    setFieldValue('isUpdateLimitActionActive', false);
  }

  submit = (e) => {
    e.stopPropagation();
  }

  handleCloseModal = (e) => {
    e.stopPropagation();
    if (this.props.userDetailsStore.signupStatus.activeAndInProgressAccounts.length >= 2 && this.props.uiStore.refLink.includes('invest-now')) {
      this.props.accreditationStore.changeShowAccountListFlag(true);
    }
    this.props.history.push(this.props.uiStore.refLink);
  }

  updateInvestmentLimit = () => {
    const { INVESTMENT_LIMIT_META, accountTypeToBeAccredited } = this.props.investmentLimitStore;
    const { isEntitySelfAccredited, isSelfAccredited, changeShowAccountListFlag } = this.props.accreditationStore;
    const { setFieldvalue, refLink } = this.props.uiStore;
    const { signupStatus } = this.props.userDetailsStore;

    // this.props.investmentLimitStore.updateInvestmentLimit().then(() => {
      if (accountTypeToBeAccredited.name === 'entity') {
        if (INVESTMENT_LIMIT_META.fields.isSelfAccredited.value && !isEntitySelfAccredited) {
          setFieldvalue('isUpdateInvestmentLimitRoute', true);
          this.props.history.push(`${this.props.match.url}/self-verification/${accountTypeToBeAccredited.name}`);
        } else {
          this.props.investmentLimitStore.updateInvestmentLimit().then(() => {
            if (signupStatus.activeAndInProgressAccounts.length >= 2 && refLink.includes('invest-now')) {
              changeShowAccountListFlag(true);
            }
            this.props.history.push(refLink || this.props.refLink);
        });
        }
      } else if (accountTypeToBeAccredited.name !== 'entity') {
        if (INVESTMENT_LIMIT_META.fields.isSelfAccredited.value && !isSelfAccredited) {
          setFieldvalue('isUpdateInvestmentLimitRoute', true);
          this.props.history.push(`${this.props.match.url}/self-verification/${accountTypeToBeAccredited.name}`);
        } else {
          this.props.investmentLimitStore.updateInvestmentLimit().then(() => {
            if (signupStatus.activeAndInProgressAccounts.length >= 2 && refLink.includes('invest-now')) {
              changeShowAccountListFlag(true);
            }
            this.props.history.push(refLink || refLink);
          });
        }
      }
  }

  formChange = (e, result, form) => {
    const { formChange } = this.props.investmentLimitStore;
    const { setFieldvalue } = this.props.uiStore;
    if (result.checked === false) {
      setFieldvalue('submitEmptyValues', true);
      formChange(e, result, form);
    } else {
      formChange(e, result, form);
    }
  }

  investmentCalculate = () => {
    const { calculateCfLimit } = this.props.investmentLimitStore;
    calculateCfLimit('INVESTMENT_LIMIT_META');
  }

  render() {
    const errors = false;
    const { inProgress, responsiveVars } = this.props.uiStore;
    const { isMobile } = responsiveVars;

    const {
      maskingFieldChange,
      INVESTMENT_LIMIT_META,
      currentInvestmentLimit,
      isAccountSelfAccredited,
    } = this.props.investmentLimitStore;

    const { fields } = INVESTMENT_LIMIT_META;
    const currentLimit = fields.isSelfAccredited.value
      || isAccountSelfAccredited
        ? currentInvestmentLimit
        : typeof currentInvestmentLimit === 'string'
        ? isNaN(parseInt(currentInvestmentLimit, 10))
          ? currentInvestmentLimit
          : `$${currentInvestmentLimit}`
        : Helper.MoneyMathDisplayCurrency(currentInvestmentLimit);
    return (
      <div>
        <NsModal onClose={this.handleCloseModal} isProgressHeaderDisable closeOnDimmerClick={false} modalClassName="multistep-modal">
          <Grid centered stackable className={isMobile ? 'full-width mt-0' : 'mt-0'}>
            <Grid.Column mobile={16} tablet={12} computer={8} className="pt-0">
              <Modal.Header className="signup-header">
                <Header as="h3">Update Investment limits</Header>
                <Divider />
                <p>
                  Ensure that your 12-month Investment Limit for Regulation Crowdfunding is up to date
                  by providing your most recent Annual Income and Net Worth.&nbsp;
                  <Link target="_blank" to="/resources/education-center/investor/faq">See FAQ on how your investment limit is calculated</Link>
                </p>
              </Modal.Header>
              <Modal.Content>
                <Divider clearing hidden />
                <Form onSubmit={this.submit}>
                  {fields
                    && ['annualIncome', 'netWorth', 'cfInvestments'].map(field => (
                      <MaskedInput
                        key={field}
                        name={field}
                        currency
                        prefix="$ "
                        value={fields[field].value}
                        fielddata={fields[field]}
                        changed={maskingFieldChange}
                        onblur={!(fields.isSelfAccredited.value) && this.investmentCalculate}
                        toolTipOnLabel
                      />
                    ))
                  }
                  <Checkbox
                    className="ui very relaxed list mt-30 field dblock"
                    label={fields.isSelfAccredited.label}
                    name="isSelfAccredited"
                    checked={fields.isSelfAccredited.value}
                    onChange={(e, result) => this.formChange(e, result, 'INVESTMENT_LIMIT_META')}
                  />
                  <Statistic className="mt-30" size="tiny">
                    <Statistic.Label>Estimated investment limit</Statistic.Label>
                    <Statistic.Value>{currentLimit}</Statistic.Value>
                  </Statistic>
                  {errors
                    && (
                    <Message error className="mt-30">
                      <ListErrors errors={[errors]} />
                    </Message>
                    )
                  }
                  <div className="mt-30">
                    <Button
                      primary
                      content="Update investment limits"
                      loading={inProgress}
                      disabled={!INVESTMENT_LIMIT_META.meta.isValid || inProgress}
                      onClick={() => this.updateInvestmentLimit()}
                    />
                  </div>
                </Form>
              </Modal.Content>
            </Grid.Column>
          </Grid>
        </NsModal>
      </div>
    );
  }
}
