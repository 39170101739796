import React from 'react';

export const BUSINESS_REFERRAL = {
  name: {
    value: '',
    error: undefined,
    placeHolder: 'Name',
    label: 'Your Name*',
    rule: 'required',
    customErrors: {
      required: '* required field',
    },
  },
  email: {
    value: '',
    error: undefined,
    placeHolder: 'Email Address',
    label: 'Your Email*',
    rule: 'required|email',
    customErrors: {
      required: '* required field',
      email: 'Your Email format is invalid.',
    },
  },
  phone: {
    value: '',
    error: undefined,
    placeHolder: 'Phone number',
    label: 'Your Phone Number',
    rule: 'optional|maskedPhoneNumber',
    customErrors: {
      required: '* must be a valid phone number',
    },
  },
  businessName: {
    value: '',
    error: undefined,
    label: 'Name of Business You\'re Referring*',
    placeHolder: 'Business name',
    rule: 'required',
    customErrors: {
      required: '* required field',
    },
  },
  businessContactName: {
    value: '',
    error: undefined,
    label: 'Business Contact Name*',
    placeHolder: 'Business contact name',
    rule: 'required',
    customErrors: {
      required: '* required field',
    },
  },
  businessContactEmail: {
    value: '',
    error: undefined,
    label: 'Business Contact Email*',
    placeHolder: 'Business contact email',
    rule: 'required|email',
    customErrors: {
      required: '* required field',
      email: 'Your Email format is invalid.',
    },
  },
  businessContactPhone: {
    value: '',
    error: undefined,
    label: 'Business Contact Phone Number',
    placeHolder: 'Business contact number',
    rule: 'optional|maskedPhoneNumber',
    customErrors: {
      required: '* required field',
    },
  },
  businessIndustry: {
    value: '',
    options: [
      { text: 'Consumer Product', key: 'CONSUMER_PRODUCT', value: 'Consumer Product' },
      { text: 'Consumer Service', key: 'CONSUMER_SERVICE', value: 'Consumer Service' },
      { text: 'Retail', key: 'RETAIL', value: 'Retail' },
      { text: 'Food & Beverage', key: 'FOOD_AND_BEVERAGE', value: 'Food & Beverage' },
      { text: 'Hospitality & Entertainment', key: 'HOSPITALITY_AND_ENTERTAINMENT', value: 'Hospitality & Entertainment' },
      { text: 'Health & Wellness', key: 'HEALTH_AND_WELLNESS', value: 'Health & Wellness' },
      { text: 'Commercial Real Estate', key: 'COMMERCIAL_REAL_ESTATE', value: 'Commercial Real Estate' },
      { text: 'Healthcare Service & Tech', key: 'HEALTHCARE_SERVICE_AND_TECH', value: 'Healthcare Service & Tech' },
      { text: 'Other', key: 'OTHER', value: 'Other' },
    ],
    placeHolder: 'Please select an option',
    label: 'Industry of Business You\'re Referring *',
    rule: 'required',
    customErrors: {
      required: '* required field',
    },
  },
  businessAbout: {
    value: '',
    error: undefined,
    label: 'Tell us a bit about the business you are referring',
    placeHolder: 'Enter text here...',
    rule: 'optional',
    customErrors: {
      required: '* required field',
    },
  },
};

export const REFERRAL_SIGNUP = {
  header: 'Do you know an entrepreneur or owner with a growing business?',
  body: <>
    <br />
    We might be able to help them achieve their business goals and,
    in return for your introduction, provide you with a $500 referral bonus.
    <br /><br />
    Simply provide a brief introduction and a way to reach the business.
    We’ll take care of the rest!
    <br /> <br />
    For each business you invite that launches a campaign on NextSeed,
    you will receive <b>$500</b> and the business will receive a
    <b> $500</b> credit towards their campaign.
    <br /> <br />
    Learn more about how it works by reading the FAQ below.
  </>,
};

export const BUSINESS_TYPES = {
  header: 'What types of businesses are a good fit for NextSeed?',
  body: <>
  <br />
  We make it easy for entrepreneurs at various stages of growth to raise capital for their next round.
  A variety of startups, small businesses, and commercial developers leverage the NextSeed platform to
  share their story,  get in front of tens of thousands of investors, and fund their growth – all
  through an integrated digital platform.
  </>,
  list: {
    industries: [
      'Consumer Products', 'Consumer Services', 'Retail', 'Food & Beverage', 'Hospitality & Entertainment',
      'Health & Wellness', 'Commercial Real Estate', 'Healthcare Services & Tech',
    ],
    characteristics: [
      'Experienced management team', 'Growth-stage or established brand', 'Unique product/concept',
      'Community-oriented',
    ],
  },
  note: <>
    All businesses must pass NextSeed’s approval and due diligence process before launching an offering.<br />
    See FAQ below for more information about our application and offering process.
  </>,
};

export const WHY_NEXTSEED = {
  header: 'Why do businesses use NextSeed to raise capital?',
  list: [
    {
      subHeader: 'RAISE FUNDS UNDER FLEXIBLE TERMS',
      body: <>We offer several different types of funding options, from debt to equity to convertibles, each with
      custom terms to fit your strategic growth plans.</>,
    },
    {
      subHeader: 'ONE-STOP PLATFORM',
      body: <>In addition to fundraising and marketing services, we coordinate legal support as well, providing a
      one-stop platform for you and your investors.</>,
    },
    {
      subHeader: 'FULL-SERVICE SUPPORT',
      body: <>Our one-time fee is only taken if you’re successful. It comes with everything you need to launch,
      promote and service your campaign.</>,
    },
    {
      subHeader: 'WE MARKET YOUR BUSINESS',
      body: <>NextSeed helps you build a tailored marketing plan to maximize your reach and attract an investor
      community invested in your long-term success.</>,
    },
  ],
};

export const TRACK_RECORD = {
  header: 'Our Track Record',
  list: [
    {
      subHeader: 'RAISED OVER $20M+',
      body: 'For small businesses and startups* (Dec 2020)',
    },
    {
      subHeader: '65+ BUSINESSES FUNDED',
      body: 'By 32K+ members on the NextSeed platform',
    },
    {
      subHeader: 'AVERAGING 10-20K',
      body: 'Unique web views per campaign',
    },
    {
      subHeader: 'SEC-REGISTERED BROKER-DEALER',
      body: 'And the 1st SEC-Registered Funding Portal under Regulation Crowdfunding (May 2016)',
    },
  ],
  note: '*Including NextSeed TX, NextSeed US, and NextSeed Securities offerings',
};

export const FAQ_CONST = {
  header: 'FAQ',
  list: [
    {
      subHeader: 'How do I qualify?',
      body: <>Fill out the form above and share a bit about yourself and the business owner we should connect with.
      A NextSeed team member will reach out to you to learn more.<br /><br />
      In order to qualify for the referral bonus, you must be the first person to introduce the business to NextSeed,
      and the business must not be already be in discussions with the NextSeed team about their fundraising needs.</>,
    },
    {
      subHeader: 'When do I receive the $500?',
      body: <>You will earn a one-time $500 cash bonus anytime a business that you refer launches a campaign on
      NextSeed. Note that not every business will pass our application or due diligence process. <br /><br />
      If you are an existing NextSeed member with an investment account, we will directly transfer the funds to
      your NextSeed Account. Your $500 bonus can be used to invest in deals on NextSeed. It can also be withdrawn
      directly. If you are not a member, we will ask you for a mailing address to send you a check.</>,
    },
    {
      subHeader: 'When do businesses I invite receive the $500 discount?',
      body: <>Businesses you refer will have $500 deducted from their NextSeed fees if and when their fundraising
      campaign successfully funds.</>,
    },
  ],
  note: <>Have more questions? Email us at <a href="mailto:info@nextseed.com">info@nextseed.com</a></>,
};

export const BUSINESS_REFERRAL_HELMET_META = [
  { name: 'description', content: 'For each business you invite that launches a campaign on NextSeed, you will receive $500 and the business will receive a $500 credit towards their campaign' },
  { name: 'og:description', content: 'For each business you invite that launches a campaign on NextSeed, you will receive $500 and the business will receive a $500 credit towards their campaign' },
  { name: 'twitter:description', content: 'For each business you invite that launches a campaign on NextSeed, you will receive $500 and the business will receive a $500 credit towards their campaign' },
];
