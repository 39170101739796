/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Header, Container, Statistic, Divider, Card } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { MISSION_META, MISSION_META_SUPS } from '../../../../constants/about';

@inject('teamStore', 'uiStore', 'publicStore', 'campaignStore')
@observer

export default class Mission extends Component {
  render() {
    const { uptoTablet, isMobile } = this.props.uiStore.responsiveVars;
    return (
      <>
        <section className={uptoTablet ? 'pt-50 pb-50 bg-offwhite our-mission' : 'pt-100 pb-100 bg-offwhite'}>
          <Container>
            <Header as="h2" className={isMobile ? 'mb-30' : 'mb-80 center-align'}>{MISSION_META.header}</Header>
            <Card.Group itemsPerRow={isMobile ? 1 : 3} className="statistic-section proven-result-section" doubling>
              {MISSION_META.cards.map(card => (
                <Card key={card.key} className={`${isMobile ? 'mlr-0' : ''} bordered`}>
                <Card.Content extra>
                  <Header as="h3" className="mb-10">{card.header}</Header>
                  <p className="neutral-text">{card.body}</p>
                </Card.Content>
                <Card.Content>
                  <Statistic color="green" size="mini" className="basic">
                    <Statistic.Value>{card.stats.value1}</Statistic.Value>
                    <Statistic.Label className="neutral-text">{card.stats.label1}</Statistic.Label>
                    <Statistic.Value>{card.stats.value2}</Statistic.Value>
                    <Statistic.Label className="neutral-text">{card.stats.label2}</Statistic.Label>
                  </Statistic>
                </Card.Content>
              </Card>
              ))}
            </Card.Group>
            {!isMobile && <Divider hidden section />}
            {MISSION_META_SUPS.map((sup, index) => (
              <p key={`sub-${index}`} className={`sup-text center-align note ${isMobile && 'mt-30'}`}>{sup}</p>
            ))}
          </Container>
        </section>
      </>
    );
  }
}
